package app.wesplit.domain.model.currency

val currencySymbols =
    mapOf(
        "AED" to "AED",
        "AFN" to "AFN",
        "ALL" to "L",
        "AMD" to "AMD",
        "ANG" to "ƒ",
        "AOA" to "Kz",
        "ARS" to "AR$",
        "AUD" to "AU$",
        "AWG" to "ƒ",
        "AZN" to "₼",
        "BAM" to "KM",
        "BBD" to "BB$",
        "BDT" to "BDT",
        "BGN" to "лв",
        "BHD" to "BHD",
        "BIF" to "FBu",
        "BMD" to "BM$",
        "BND" to "BN$",
        "BOB" to "Bs.",
        "BRL" to "R$",
        "BSD" to "BS$",
        "BTN" to "Nu.",
        "BWP" to "P",
        "BYN" to "Br",
        "BZD" to "BZ$",
        "CAD" to "C$",
        "CDF" to "FC",
        "CHF" to "CHF",
        "CLP" to "CL$",
        "CNY" to "¥",
        "COP" to "CO$",
        "CRC" to "CRC",
        "CUP" to "CU$",
        "CVE" to "CV$",
        "CZK" to "Kč",
        "DJF" to "Fdj",
        "DKK" to "kr",
        "DOP" to "DO$",
        "DZD" to "DZD",
        "EGP" to "£",
        "ERN" to "Nfk",
        "ETB" to "Br",
        "EUR" to "€",
        "FJD" to "FJ$",
        "FKP" to "£",
        "FOK" to "kr",
        "GBP" to "£",
        "GEL" to "GEL",
        "GGP" to "£",
        "GHS" to "GHS",
        "GIP" to "£",
        "GMD" to "D",
        "GNF" to "FG",
        "GTQ" to "Q",
        "GYD" to "GY$",
        "HKD" to "HK$",
        "HNL" to "L",
        "HRK" to "kn",
        "HTG" to "G",
        "HUF" to "Ft",
        "IDR" to "Rp",
        "ILS" to "₪",
        "IMP" to "£",
        "INR" to "₹",
        "IQD" to "IQD",
        "IRR" to "IRR",
        "ISK" to "kr",
        "JEP" to "£",
        "JMD" to "J$",
        "JOD" to "JOD",
        "JPY" to "¥",
        "KES" to "KSh",
        "KGS" to "с",
        "KHR" to "KHR",
        "KID" to "KI$",
        "KMF" to "CF",
        "KRW" to "₩",
        "KWD" to "KW$",
        "KYD" to "KY$",
        "KZT" to "KZT",
        "LAK" to "LAK",
        "LBP" to "LBP",
        "LKR" to "Rs",
        "LRD" to "LR$",
        "LSL" to "L",
        "LYD" to "LYD",
        "MAD" to "MAD",
        "MDL" to "L",
        "MGA" to "Ar",
        "MKD" to "ден",
        "MMK" to "K",
        "MNT" to "MNT",
        "MOP" to "P",
        "MRU" to "UM",
        "MUR" to "₨",
        "MVR" to "Rf",
        "MWK" to "MK",
        "MXN" to "MX$",
        "MYR" to "RM",
        "MZN" to "MT",
        "NAD" to "NA$",
        "NGN" to "₦",
        "NIO" to "C$",
        "NOK" to "kr",
        "NPR" to "Rs",
        "NZD" to "NZ$",
        "OMR" to "OMR",
        "PAB" to "B/.",
        "PEN" to "S/",
        "PGK" to "K",
        "PHP" to "₱",
        "PKR" to "₨",
        "PLN" to "zł",
        "PYG" to "PYG",
        "QAR" to "QAR",
        "RON" to "lei",
        "RSD" to "дин",
        "RUB" to "₽",
        "RWF" to "FRw",
        "SAR" to "SAR",
        "SBD" to "SB$",
        "SCR" to "₨",
        "SDG" to "SDG",
        "SEK" to "kr",
        "SGD" to "SG$",
        "SHP" to "£",
        "SLE" to "Le",
        "SLL" to "Le",
        "SOS" to "Sh",
        "SRD" to "SR$",
        "SSP" to "£",
        "STN" to "Db",
        "SYP" to "£S",
        "SZL" to "E",
        "THB" to "THB",
        "TJS" to "ЅМ",
        "TMT" to "m",
        "TND" to "TND",
        "TOP" to "T$",
        "TRY" to "₺",
        "TTD" to "TT$",
        "TVD" to "TV$",
        "TWD" to "NT$",
        "TZS" to "TSh",
        "UAH" to "UAH",
        "UGX" to "USh",
        "USD" to "$",
        "UYU" to "UY$",
        "UZS" to "UZS",
        "VES" to "Bs.S.",
        "VND" to "₫",
        "VUV" to "Vt",
        "WST" to "WS$",
        "XAF" to "FCFA",
        "XCD" to "XV$",
        "XDR" to "SDR",
        "XOF" to "CFA",
        "XPF" to "₣",
        "YER" to "YER",
        "ZAR" to "R",
        "ZMW" to "ZK",
        "ZWL" to "ZW$",
    )
