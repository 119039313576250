package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val app_wesplit_data_firebase_FirebaseDataModule : Module get() = module {
	single() { app.wesplit.data.firebase.AccountFirebaseRepository(analytics=get(),coroutineDispatcher=get(),loginDelegate=get(),userRepository=get()) } bind(app.wesplit.domain.model.account.AccountRepository::class)
	single() { app.wesplit.data.firebase.CurrencyFirebaseRepository(analyticsManager=get(),userRepository=get(),coroutineDispatcher=get()) } bind(app.wesplit.domain.model.currency.CurrencyRepository::class)
	single() { app.wesplit.data.firebase.ExpenseFirebaseRepository(coroutineDispatcher=get(),userRepository=get(),analyticsManager=get(),permissionsDelegate=get()) } bind(app.wesplit.domain.model.expense.ExpenseRepository::class)
	single() { app.wesplit.data.firebase.GroupFirebaseRepository(accountRepository=get(),userRepository=get(),coroutineDispatcher=get(),analyticsManager=get()) } bind(app.wesplit.domain.model.group.GroupRepository::class)
	single() { app.wesplit.data.firebase.UserFirebaseRepository(analytics=get(),coroutineDispatcher=get()) } bind(app.wesplit.domain.model.user.UserRepository::class)
	single() { app.wesplit.data.firebase.paywall.BillingDelegateInMemoryStateRepository(userRepository=get()) } bind(app.wesplit.domain.model.paywall.BillingDelegate.StateRepository::class)
	single() { app.wesplit.data.firebase.paywall.PaywallProxyRepository(billingDelegate=get(),billingStateRepository=get(),analytics=get()) } bind(app.wesplit.domain.model.paywall.PaywallRepository::class)
}
public val app.wesplit.data.firebase.FirebaseDataModule.module : org.koin.core.module.Module get() = app_wesplit_data_firebase_FirebaseDataModule