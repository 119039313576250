package app.wesplit.theme
import androidx.compose.ui.graphics.Color

val warningLight = Color(0xFFF57F17)
val warningDark = Color(0xFFFFEB3B)

val onWarningLight = Color(0xFF0E050E)
val onWarningDark = Color(0xFF0E050E)

val infoContainerLight = Color(0xFF00B0FF)
val infoContainerDark = Color(0xFF00B0FF)

val onInfoContainerLight = Color(0xFFE1F5FE)
val onInfoContainerDark = Color(0xFFE1F5FE)
