package app.wesplit.group

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.unit.dp
import com.seiko.imageloader.model.ImageAction
import com.seiko.imageloader.rememberImageSuccessPainter
import com.seiko.imageloader.ui.AutoSizeBox
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveCircularProgressIndicator
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import split.composeapp.generated.resources.Res
import split.composeapp.generated.resources.ic_group
import split.composeapp.generated.resources.no_image_user

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
fun GroupImage(
    modifier: Modifier = Modifier,
    imageUrl: String?,
    groupTitle: String,
    isLoading: Boolean = false,
    placeholder: @Composable () -> Unit = {
        Box(
            modifier =
                Modifier.size(52.dp).clip(RoundedCornerShape(8.dp))
                    .background(MaterialTheme.colorScheme.surfaceContainerLow),
            contentAlignment = Alignment.Center,
        ) {
            Image(
                modifier = Modifier.size(24.dp),
                painter = painterResource(Res.drawable.ic_group),
                contentDescription = stringResource(Res.string.no_image_user, groupTitle),
                colorFilter = ColorFilter.tint(MaterialTheme.colorScheme.onSurface),
            )
        }
    },
    onClick: (() -> Unit)? = null,
) {
    AutoSizeBox(
        modifier =
            modifier.size(52.dp).then(
                onClick?.let { Modifier.clickable { it() } } ?: Modifier,
            ),
        url = imageUrl ?: "",
    ) { action ->
        if (isLoading) {
            AdaptiveCircularProgressIndicator()
        } else {
            when (action) {
                is ImageAction.Success -> {
                    Image(
                        rememberImageSuccessPainter(action),
                        modifier = Modifier.size(52.dp).clip(RoundedCornerShape(8.dp)),
                        contentDescription = groupTitle,
                    )
                }

                is ImageAction.Loading -> {
                    AdaptiveCircularProgressIndicator()
                }

                is ImageAction.Failure -> {
                    placeholder()
                }
            }
        }
    }
}
