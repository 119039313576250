@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package split.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val add_expense_to_group: StringResource by 
      lazy { init_add_expense_to_group() }

  public val add_group_cd: StringResource by 
      lazy { init_add_group_cd() }

  public val add_group_image: StringResource by 
      lazy { init_add_group_image() }

  public val add_user_to_group: StringResource by 
      lazy { init_add_user_to_group() }

  public val already_settled: StringResource by 
      lazy { init_already_settled() }

  public val amount: StringResource by 
      lazy { init_amount() }

  public val amounts: StringResource by 
      lazy { init_amounts() }

  public val andspaces: StringResource by 
      lazy { init_andspaces() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val app_version: StringResource by 
      lazy { init_app_version() }

  public val at_date: StringResource by 
      lazy { init_at_date() }

  public val available_in_mobile_app: StringResource by 
      lazy { init_available_in_mobile_app() }

  public val back: StringResource by 
      lazy { init_back() }

  public val back_btn_cd: StringResource by 
      lazy { init_back_btn_cd() }

  public val balances: StringResource by 
      lazy { init_balances() }

  public val balances_explanation: StringResource by 
      lazy { init_balances_explanation() }

  public val benefits: StringResource by 
      lazy { init_benefits() }

  public val best_offer_badge: StringResource by 
      lazy { init_best_offer_badge() }

  public val calculate_best_way_settle: StringResource by 
      lazy { init_calculate_best_way_settle() }

  public val cancel: StringResource by 
      lazy { init_cancel() }

  public val category_bank: StringResource by 
      lazy { init_category_bank() }

  public val category_beauty: StringResource by 
      lazy { init_category_beauty() }

  public val category_books: StringResource by 
      lazy { init_category_books() }

  public val category_car: StringResource by 
      lazy { init_category_car() }

  public val category_cash: StringResource by 
      lazy { init_category_cash() }

  public val category_cleaning: StringResource by 
      lazy { init_category_cleaning() }

  public val category_clothes: StringResource by 
      lazy { init_category_clothes() }

  public val category_coffee: StringResource by 
      lazy { init_category_coffee() }

  public val category_concert: StringResource by 
      lazy { init_category_concert() }

  public val category_crypto: StringResource by 
      lazy { init_category_crypto() }

  public val category_electricity: StringResource by 
      lazy { init_category_electricity() }

  public val category_entertainment: StringResource by 
      lazy { init_category_entertainment() }

  public val category_fast_food: StringResource by 
      lazy { init_category_fast_food() }

  public val category_fee: StringResource by 
      lazy { init_category_fee() }

  public val category_flight: StringResource by 
      lazy { init_category_flight() }

  public val category_food_drink: StringResource by 
      lazy { init_category_food_drink() }

  public val category_furnishing: StringResource by 
      lazy { init_category_furnishing() }

  public val category_garbage: StringResource by 
      lazy { init_category_garbage() }

  public val category_gifts: StringResource by 
      lazy { init_category_gifts() }

  public val category_groceries: StringResource by 
      lazy { init_category_groceries() }

  public val category_health: StringResource by 
      lazy { init_category_health() }

  public val category_health_beauty: StringResource by 
      lazy { init_category_health_beauty() }

  public val category_hobby: StringResource by 
      lazy { init_category_hobby() }

  public val category_housing: StringResource by 
      lazy { init_category_housing() }

  public val category_internet: StringResource by 
      lazy { init_category_internet() }

  public val category_magic: StringResource by 
      lazy { init_category_magic() }

  public val category_money: StringResource by 
      lazy { init_category_money() }

  public val category_movie: StringResource by 
      lazy { init_category_movie() }

  public val category_none: StringResource by 
      lazy { init_category_none() }

  public val category_parking: StringResource by 
      lazy { init_category_parking() }

  public val category_public: StringResource by 
      lazy { init_category_public() }

  public val category_recycling: StringResource by 
      lazy { init_category_recycling() }

  public val category_rent: StringResource by 
      lazy { init_category_rent() }

  public val category_repair: StringResource by 
      lazy { init_category_repair() }

  public val category_restaurant: StringResource by 
      lazy { init_category_restaurant() }

  public val category_security: StringResource by 
      lazy { init_category_security() }

  public val category_shoes: StringResource by 
      lazy { init_category_shoes() }

  public val category_shopping: StringResource by 
      lazy { init_category_shopping() }

  public val category_sport: StringResource by 
      lazy { init_category_sport() }

  public val category_sport_event: StringResource by 
      lazy { init_category_sport_event() }

  public val category_tax: StringResource by 
      lazy { init_category_tax() }

  public val category_taxi: StringResource by 
      lazy { init_category_taxi() }

  public val category_technology: StringResource by 
      lazy { init_category_technology() }

  public val category_tolls: StringResource by 
      lazy { init_category_tolls() }

  public val category_transport_travel: StringResource by 
      lazy { init_category_transport_travel() }

  public val category_transportation: StringResource by 
      lazy { init_category_transportation() }

  public val category_utilities: StringResource by 
      lazy { init_category_utilities() }

  public val category_water: StringResource by 
      lazy { init_category_water() }

  public val change_color_mode: StringResource by 
      lazy { init_change_color_mode() }

  public val change_theme: StringResource by 
      lazy { init_change_theme() }

  public val close_picker: StringResource by 
      lazy { init_close_picker() }

  public val collapsed: StringResource by 
      lazy { init_collapsed() }

  public val confirm_delete_account: StringResource by 
      lazy { init_confirm_delete_account() }

  public val confirm_delete_expense_message: StringResource by 
      lazy { init_confirm_delete_expense_message() }

  public val confirm_mark_all_settled: StringResource by 
      lazy { init_confirm_mark_all_settled() }

  public val confirm_no_wait: StringResource by 
      lazy { init_confirm_no_wait() }

  public val confirm_yes_delete: StringResource by 
      lazy { init_confirm_yes_delete() }

  public val confirm_yes_join: StringResource by 
      lazy { init_confirm_yes_join() }

  public val confirm_yes_leave: StringResource by 
      lazy { init_confirm_yes_leave() }

  public val confirm_yes_settle: StringResource by 
      lazy { init_confirm_yes_settle() }

  public val contacts_in_book: StringResource by 
      lazy { init_contacts_in_book() }

  public val contacts_in_wesplit: StringResource by 
      lazy { init_contacts_in_wesplit() }

  public val create: StringResource by 
      lazy { init_create() }

  public val create_new_contact: StringResource by 
      lazy { init_create_new_contact() }

  public val current_group_participants: StringResource by 
      lazy { init_current_group_participants() }

  public val dark_theme: StringResource by 
      lazy { init_dark_theme() }

  public val data_syncing: StringResource by 
      lazy { init_data_syncing() }

  public val delete_account: StringResource by 
      lazy { init_delete_account() }

  public val delete_account_confirmation: StringResource by 
      lazy { init_delete_account_confirmation() }

  public val delete_account_from_wesplit: StringResource by 
      lazy { init_delete_account_from_wesplit() }

  public val delete_expense: StringResource by 
      lazy { init_delete_expense() }

  public val delete_expense_group: StringResource by 
      lazy { init_delete_expense_group() }

  public val delete_expense_title: StringResource by 
      lazy { init_delete_expense_title() }

  public val download_mobile_app: StringResource by 
      lazy { init_download_mobile_app() }

  public val edit_group: StringResource by 
      lazy { init_edit_group() }

  public val empty_group_cd: StringResource by 
      lazy { init_empty_group_cd() }

  public val empty_transaction_description: StringResource by 
      lazy { init_empty_transaction_description() }

  public val empty_transactions_cd: StringResource by 
      lazy { init_empty_transactions_cd() }

  public val enter_promocode: StringResource by 
      lazy { init_enter_promocode() }

  public val enter_valid_promocode: StringResource by 
      lazy { init_enter_valid_promocode() }

  public val equal: StringResource by 
      lazy { init_equal() }

  public val error: StringResource by 
      lazy { init_error() }

  public val error_encountered_problems: StringResource by 
      lazy { init_error_encountered_problems() }

  public val error_fetching_plans: StringResource by 
      lazy { init_error_fetching_plans() }

  public val expanded: StringResource by 
      lazy { init_expanded() }

  public val expense_title: StringResource by 
      lazy { init_expense_title() }

  public val feedback: StringResource by 
      lazy { init_feedback() }

  public val fetch_available_plans: StringResource by 
      lazy { init_fetch_available_plans() }

  public val forget_group: StringResource by 
      lazy { init_forget_group() }

  public val forgive_and_forget: StringResource by 
      lazy { init_forgive_and_forget() }

  public val fx_single_currency: StringResource by 
      lazy { init_fx_single_currency() }

  public val get_app: StringResource by 
      lazy { init_get_app() }

  public val get_mobile_app: StringResource by 
      lazy { init_get_mobile_app() }

  public val get_plus_instant_balances: StringResource by 
      lazy { init_get_plus_instant_balances() }

  public val gets_back: StringResource by 
      lazy { init_gets_back() }

  public val go_to_privacy: StringResource by 
      lazy { init_go_to_privacy() }

  public val go_to_terms_conditions: StringResource by 
      lazy { init_go_to_terms_conditions() }

  public val got_it: StringResource by 
      lazy { init_got_it() }

  public val grant_permission: StringResource by 
      lazy { init_grant_permission() }

  public val group_detailed_empty_description: StringResource by 
      lazy { init_group_detailed_empty_description() }

  public val group_list_empty_description_authorized: StringResource by 
      lazy { init_group_list_empty_description_authorized() }

  public val group_list_empty_description_unregistered: StringResource by 
      lazy { init_group_list_empty_description_unregistered() }

  public val group_list_title: StringResource by 
      lazy { init_group_list_title() }

  public val group_name: StringResource by 
      lazy { init_group_name() }

  public val groups: StringResource by 
      lazy { init_groups() }

  public val image_description_ai: StringResource by 
      lazy { init_image_description_ai() }

  public val info_sign: StringResource by 
      lazy { init_info_sign() }

  public val invite_user: StringResource by 
      lazy { init_invite_user() }

  public val join_agree: StringResource by 
      lazy { init_join_agree() }

  public val join_group: StringResource by 
      lazy { init_join_group() }

  public val join_group_as_new_participant: StringResource by 
      lazy { init_join_group_as_new_participant() }

  public val join_group_as_user: StringResource by 
      lazy { init_join_group_as_user() }

  public val join_new_participant: StringResource by 
      lazy { init_join_new_participant() }

  public val label_apply: StringResource by 
      lazy { init_label_apply() }

  public val label_code: StringResource by 
      lazy { init_label_code() }

  public val leave_group: StringResource by 
      lazy { init_leave_group() }

  public val leave_group_confirmation: StringResource by 
      lazy { init_leave_group_confirmation() }

  public val light_theme: StringResource by 
      lazy { init_light_theme() }

  public val loading: StringResource by 
      lazy { init_loading() }

  public val locked_feature: StringResource by 
      lazy { init_locked_feature() }

  public val login: StringResource by 
      lazy { init_login() }

  public val login_anon: StringResource by 
      lazy { init_login_anon() }

  public val login_button_cd: StringResource by 
      lazy { init_login_button_cd() }

  public val login_to_create_descr: StringResource by 
      lazy { init_login_to_create_descr() }

  public val login_to_protect_expense: StringResource by 
      lazy { init_login_to_protect_expense() }

  public val login_with_apple_cd: StringResource by 
      lazy { init_login_with_apple_cd() }

  public val login_with_google_cd: StringResource by 
      lazy { init_login_with_google_cd() }

  public val logout: StringResource by 
      lazy { init_logout() }

  public val mark_as_non_settled: StringResource by 
      lazy { init_mark_as_non_settled() }

  public val mark_as_settled: StringResource by 
      lazy { init_mark_as_settled() }

  public val month_plus: StringResource by 
      lazy { init_month_plus() }

  public val new: StringResource by 
      lazy { init_new() }

  public val new_expense: StringResource by 
      lazy { init_new_expense() }

  public val new_group: StringResource by 
      lazy { init_new_group() }

  public val no_contact_found: StringResource by 
      lazy { init_no_contact_found() }

  public val no_image_group_cd: StringResource by 
      lazy { init_no_image_group_cd() }

  public val no_image_user: StringResource by 
      lazy { init_no_image_user() }

  public val non_distr_cd: StringResource by 
      lazy { init_non_distr_cd() }

  public val not_allowed_to_update_expense: StringResource by 
      lazy { init_not_allowed_to_update_expense() }

  public val not_found: StringResource by 
      lazy { init_not_found() }

  public val not_participating: StringResource by 
      lazy { init_not_participating() }

  public val not_settled: StringResource by 
      lazy { init_not_settled() }

  public val not_split: StringResource by 
      lazy { init_not_split() }

  public val not_split_amount: StringResource by 
      lazy { init_not_split_amount() }

  public val offer_badge: StringResource by 
      lazy { init_offer_badge() }

  public val offline_balances_shown: StringResource by 
      lazy { init_offline_balances_shown() }

  public val okey: StringResource by 
      lazy { init_okey() }

  public val only_this_transaction: StringResource by 
      lazy { init_only_this_transaction() }

  public val only_you_can_update_expense: StringResource by 
      lazy { init_only_you_can_update_expense() }

  public val or: StringResource by 
      lazy { init_or() }

  public val other: StringResource by 
      lazy { init_other() }

  public val paid_by: StringResource by 
      lazy { init_paid_by() }

  public val paid_by_me: StringResource by 
      lazy { init_paid_by_me() }

  public val paid_by_person: StringResource by 
      lazy { init_paid_by_person() }

  public val paid_by_you: StringResource by 
      lazy { init_paid_by_you() }

  public val per_month: StringResource by 
      lazy { init_per_month() }

  public val personal_expense: StringResource by 
      lazy { init_personal_expense() }

  public val plus_active: StringResource by 
      lazy { init_plus_active() }

  public val plus_badge: StringResource by 
      lazy { init_plus_badge() }

  public val plus_badge_benefits: StringResource by 
      lazy { init_plus_badge_benefits() }

  public val plus_feature_cats_cta: StringResource by 
      lazy { init_plus_feature_cats_cta() }

  public val plus_feature_cats_descr_full: StringResource by 
      lazy { init_plus_feature_cats_descr_full() }

  public val plus_feature_cats_descr_short: StringResource by 
      lazy { init_plus_feature_cats_descr_short() }

  public val plus_feature_cats_title: StringResource by 
      lazy { init_plus_feature_cats_title() }

  public val plus_feature_currencies_descr_full: StringResource by 
      lazy { init_plus_feature_currencies_descr_full() }

  public val plus_feature_currencies_descr_short: StringResource by 
      lazy { init_plus_feature_currencies_descr_short() }

  public val plus_feature_currencies_title: StringResource by 
      lazy { init_plus_feature_currencies_title() }

  public val plus_feature_images_descr_full: StringResource by 
      lazy { init_plus_feature_images_descr_full() }

  public val plus_feature_images_descr_short: StringResource by 
      lazy { init_plus_feature_images_descr_short() }

  public val plus_feature_images_title: StringResource by 
      lazy { init_plus_feature_images_title() }

  public val plus_feature_more_descr: StringResource by 
      lazy { init_plus_feature_more_descr() }

  public val plus_feature_more_title: StringResource by 
      lazy { init_plus_feature_more_title() }

  public val plus_feature_noads_descr_full: StringResource by 
      lazy { init_plus_feature_noads_descr_full() }

  public val plus_feature_noads_descr_short: StringResource by 
      lazy { init_plus_feature_noads_descr_short() }

  public val plus_feature_noads_title: StringResource by 
      lazy { init_plus_feature_noads_title() }

  public val plus_feature_protect_descr_full: StringResource by 
      lazy { init_plus_feature_protect_descr_full() }

  public val plus_feature_protect_descr_short: StringResource by 
      lazy { init_plus_feature_protect_descr_short() }

  public val plus_feature_protect_title: StringResource by 
      lazy { init_plus_feature_protect_title() }

  public val plus_feature_quick_add_descr_full: StringResource by 
      lazy { init_plus_feature_quick_add_descr_full() }

  public val plus_feature_quick_add_descr_short: StringResource by 
      lazy { init_plus_feature_quick_add_descr_short() }

  public val plus_feature_quick_add_title: StringResource by 
      lazy { init_plus_feature_quick_add_title() }

  public val plus_feature_single_settle_descr_full: StringResource by 
      lazy { init_plus_feature_single_settle_descr_full() }

  public val plus_feature_single_settle_descr_short: StringResource by 
      lazy { init_plus_feature_single_settle_descr_short() }

  public val plus_feature_single_settle_title: StringResource by 
      lazy { init_plus_feature_single_settle_title() }

  public val plus_feature_suggest_descr_full: StringResource by 
      lazy { init_plus_feature_suggest_descr_full() }

  public val plus_feature_suggest_descr_short: StringResource by 
      lazy { init_plus_feature_suggest_descr_short() }

  public val plus_feature_suggest_title: StringResource by 
      lazy { init_plus_feature_suggest_title() }

  public val plus_subs_title: StringResource by 
      lazy { init_plus_subs_title() }

  public val privacy_policy: StringResource by 
      lazy { init_privacy_policy() }

  public val problems_encountered: StringResource by 
      lazy { init_problems_encountered() }

  public val profile: StringResource by 
      lazy { init_profile() }

  public val profile_under_construction: StringResource by 
      lazy { init_profile_under_construction() }

  public val protect_expense: StringResource by 
      lazy { init_protect_expense() }

  public val protect_expense_editing: StringResource by 
      lazy { init_protect_expense_editing() }

  public val protected_expense: StringResource by 
      lazy { init_protected_expense() }

  public val protected_feature: StringResource by 
      lazy { init_protected_feature() }

  public val quick_add: StringResource by 
      lazy { init_quick_add() }

  public val recalculate: StringResource by 
      lazy { init_recalculate() }

  public val recalculating_backend: StringResource by 
      lazy { init_recalculating_backend() }

  public val retry: StringResource by 
      lazy { init_retry() }

  public val save: StringResource by 
      lazy { init_save() }

  public val search_contact: StringResource by 
      lazy { init_search_contact() }

  public val search_currency: StringResource by 
      lazy { init_search_currency() }

  public val select_currency: StringResource by 
      lazy { init_select_currency() }

  public val select_payer_cd: StringResource by 
      lazy { init_select_payer_cd() }

  public val settings: StringResource by 
      lazy { init_settings() }

  public val settle_all_balances: StringResource by 
      lazy { init_settle_all_balances() }

  public val settle_balances: StringResource by 
      lazy { init_settle_balances() }

  public val settled: StringResource by 
      lazy { init_settled() }

  public val settled_all_expenses: StringResource by 
      lazy { init_settled_all_expenses() }

  public val settled_message: StringResource by 
      lazy { init_settled_message() }

  public val share_group: StringResource by 
      lazy { init_share_group() }

  public val share_link_copied: StringResource by 
      lazy { init_share_link_copied() }

  public val share_subject: StringResource by 
      lazy { init_share_subject() }

  public val shares: StringResource by 
      lazy { init_shares() }

  public val should_pay_to: StringResource by 
      lazy { init_should_pay_to() }

  public val start_type_creat_contact: StringResource by 
      lazy { init_start_type_creat_contact() }

  public val submit_feedback_cd: StringResource by 
      lazy { init_submit_feedback_cd() }

  public val subscribe_for: StringResource by 
      lazy { init_subscribe_for() }

  public val subscribe_plus: StringResource by 
      lazy { init_subscribe_plus() }

  public val subscribe_via_mobile_app: StringResource by 
      lazy { init_subscribe_via_mobile_app() }

  public val suggest_split: StringResource by 
      lazy { init_suggest_split() }

  public val sync_in_progress: StringResource by 
      lazy { init_sync_in_progress() }

  public val system_theme: StringResource by 
      lazy { init_system_theme() }

  public val terms_conditions: StringResource by 
      lazy { init_terms_conditions() }

  public val text_its_me: StringResource by 
      lazy { init_text_its_me() }

  public val text_loading: StringResource by 
      lazy { init_text_loading() }

  public val title_leave_group: StringResource by 
      lazy { init_title_leave_group() }

  public val title_should_be_filled: StringResource by 
      lazy { init_title_should_be_filled() }

  public val transactions: StringResource by 
      lazy { init_transactions() }

  public val try_days: StringResource by 
      lazy { init_try_days() }

  public val try_month_plus: StringResource by 
      lazy { init_try_month_plus() }

  public val try_plus: StringResource by 
      lazy { init_try_plus() }

  public val try_week_plus: StringResource by 
      lazy { init_try_week_plus() }

  public val try_year_plus: StringResource by 
      lazy { init_try_year_plus() }

  public val tutorial_info: StringResource by 
      lazy { init_tutorial_info() }

  public val tutorial_step_add_expense_description: StringResource by 
      lazy { init_tutorial_step_add_expense_description() }

  public val tutorial_step_add_expense_title: StringResource by 
      lazy { init_tutorial_step_add_expense_title() }

  public val tutorial_step_add_participant_description: StringResource by 
      lazy { init_tutorial_step_add_participant_description() }

  public val tutorial_step_add_participant_title: StringResource by 
      lazy { init_tutorial_step_add_participant_title() }

  public val tutorial_step_balances_tab_description: StringResource by 
      lazy { init_tutorial_step_balances_tab_description() }

  public val tutorial_step_balances_tab_title: StringResource by 
      lazy { init_tutorial_step_balances_tab_title() }

  public val tutorial_step_check_balances_description: StringResource by 
      lazy { init_tutorial_step_check_balances_description() }

  public val tutorial_step_check_balances_title: StringResource by 
      lazy { init_tutorial_step_check_balances_title() }

  public val tutorial_step_choose_payer: StringResource by 
      lazy { init_tutorial_step_choose_payer() }

  public val tutorial_step_choose_payer_description: StringResource by 
      lazy { init_tutorial_step_choose_payer_description() }

  public val tutorial_step_confirm_selection_description: StringResource by 
      lazy { init_tutorial_step_confirm_selection_description() }

  public val tutorial_step_confirm_selection_title: StringResource by 
      lazy { init_tutorial_step_confirm_selection_title() }

  public val tutorial_step_create_group_description: StringResource by 
      lazy { init_tutorial_step_create_group_description() }

  public val tutorial_step_create_group_title: StringResource by 
      lazy { init_tutorial_step_create_group_title() }

  public val tutorial_step_date_currency_description: StringResource by 
      lazy { init_tutorial_step_date_currency_description() }

  public val tutorial_step_date_currency_setup: StringResource by 
      lazy { init_tutorial_step_date_currency_setup() }

  public val tutorial_step_expense_title: StringResource by 
      lazy { init_tutorial_step_expense_title() }

  public val tutorial_step_expense_title_description: StringResource by 
      lazy { init_tutorial_step_expense_title_description() }

  public val tutorial_step_input_name_description: StringResource by 
      lazy { init_tutorial_step_input_name_description() }

  public val tutorial_step_input_name_title: StringResource by 
      lazy { init_tutorial_step_input_name_title() }

  public val tutorial_step_invite_friends_description: StringResource by 
      lazy { init_tutorial_step_invite_friends_description() }

  public val tutorial_step_invite_friends_title: StringResource by 
      lazy { init_tutorial_step_invite_friends_title() }

  public val tutorial_step_put_amount: StringResource by 
      lazy { init_tutorial_step_put_amount() }

  public val tutorial_step_put_amount_description: StringResource by 
      lazy { init_tutorial_step_put_amount_description() }

  public val tutorial_step_save_expense: StringResource by 
      lazy { init_tutorial_step_save_expense() }

  public val tutorial_step_save_expense_description: StringResource by 
      lazy { init_tutorial_step_save_expense_description() }

  public val tutorial_step_select_user_description: StringResource by 
      lazy { init_tutorial_step_select_user_description() }

  public val tutorial_step_select_user_title: StringResource by 
      lazy { init_tutorial_step_select_user_title() }

  public val tutorial_step_settle_up_description: StringResource by 
      lazy { init_tutorial_step_settle_up_description() }

  public val tutorial_step_settle_up_title: StringResource by 
      lazy { init_tutorial_step_settle_up_title() }

  public val undistributed: StringResource by 
      lazy { init_undistributed() }

  public val unlock_all_features: StringResource by 
      lazy { init_unlock_all_features() }

  public val user_already_in_group: StringResource by 
      lazy { init_user_already_in_group() }

  public val users_count: StringResource by 
      lazy { init_users_count() }

  public val valid_subs: StringResource by 
      lazy { init_valid_subs() }

  public val valid_subs_days_more: StringResource by 
      lazy { init_valid_subs_days_more() }

  public val valid_subs_hours_more: StringResource by 
      lazy { init_valid_subs_hours_more() }

  public val we_split_in: StringResource by 
      lazy { init_we_split_in() }

  public val web_billing_in_progress: StringResource by 
      lazy { init_web_billing_in_progress() }

  public val week_plus: StringResource by 
      lazy { init_week_plus() }

  public val wesplit_logo: StringResource by 
      lazy { init_wesplit_logo() }

  public val what_you_get_with: StringResource by 
      lazy { init_what_you_get_with() }

  public val with_me: StringResource by 
      lazy { init_with_me() }

  public val year_plus: StringResource by 
      lazy { init_year_plus() }

  public val you: StringResource by 
      lazy { init_you() }

  public val you_borrowed: StringResource by 
      lazy { init_you_borrowed() }

  public val you_have_plus: StringResource by 
      lazy { init_you_have_plus() }

  public val you_lent: StringResource by 
      lazy { init_you_lent() }

  public val your_share: StringResource by 
      lazy { init_your_share() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("add_expense_to_group", CommonMainString0.add_expense_to_group)
  map.put("add_group_cd", CommonMainString0.add_group_cd)
  map.put("add_group_image", CommonMainString0.add_group_image)
  map.put("add_user_to_group", CommonMainString0.add_user_to_group)
  map.put("already_settled", CommonMainString0.already_settled)
  map.put("amount", CommonMainString0.amount)
  map.put("amounts", CommonMainString0.amounts)
  map.put("andspaces", CommonMainString0.andspaces)
  map.put("app_name", CommonMainString0.app_name)
  map.put("app_version", CommonMainString0.app_version)
  map.put("at_date", CommonMainString0.at_date)
  map.put("available_in_mobile_app", CommonMainString0.available_in_mobile_app)
  map.put("back", CommonMainString0.back)
  map.put("back_btn_cd", CommonMainString0.back_btn_cd)
  map.put("balances", CommonMainString0.balances)
  map.put("balances_explanation", CommonMainString0.balances_explanation)
  map.put("benefits", CommonMainString0.benefits)
  map.put("best_offer_badge", CommonMainString0.best_offer_badge)
  map.put("calculate_best_way_settle", CommonMainString0.calculate_best_way_settle)
  map.put("cancel", CommonMainString0.cancel)
  map.put("category_bank", CommonMainString0.category_bank)
  map.put("category_beauty", CommonMainString0.category_beauty)
  map.put("category_books", CommonMainString0.category_books)
  map.put("category_car", CommonMainString0.category_car)
  map.put("category_cash", CommonMainString0.category_cash)
  map.put("category_cleaning", CommonMainString0.category_cleaning)
  map.put("category_clothes", CommonMainString0.category_clothes)
  map.put("category_coffee", CommonMainString0.category_coffee)
  map.put("category_concert", CommonMainString0.category_concert)
  map.put("category_crypto", CommonMainString0.category_crypto)
  map.put("category_electricity", CommonMainString0.category_electricity)
  map.put("category_entertainment", CommonMainString0.category_entertainment)
  map.put("category_fast_food", CommonMainString0.category_fast_food)
  map.put("category_fee", CommonMainString0.category_fee)
  map.put("category_flight", CommonMainString0.category_flight)
  map.put("category_food_drink", CommonMainString0.category_food_drink)
  map.put("category_furnishing", CommonMainString0.category_furnishing)
  map.put("category_garbage", CommonMainString0.category_garbage)
  map.put("category_gifts", CommonMainString0.category_gifts)
  map.put("category_groceries", CommonMainString0.category_groceries)
  map.put("category_health", CommonMainString0.category_health)
  map.put("category_health_beauty", CommonMainString0.category_health_beauty)
  map.put("category_hobby", CommonMainString0.category_hobby)
  map.put("category_housing", CommonMainString0.category_housing)
  map.put("category_internet", CommonMainString0.category_internet)
  map.put("category_magic", CommonMainString0.category_magic)
  map.put("category_money", CommonMainString0.category_money)
  map.put("category_movie", CommonMainString0.category_movie)
  map.put("category_none", CommonMainString0.category_none)
  map.put("category_parking", CommonMainString0.category_parking)
  map.put("category_public", CommonMainString0.category_public)
  map.put("category_recycling", CommonMainString0.category_recycling)
  map.put("category_rent", CommonMainString0.category_rent)
  map.put("category_repair", CommonMainString0.category_repair)
  map.put("category_restaurant", CommonMainString0.category_restaurant)
  map.put("category_security", CommonMainString0.category_security)
  map.put("category_shoes", CommonMainString0.category_shoes)
  map.put("category_shopping", CommonMainString0.category_shopping)
  map.put("category_sport", CommonMainString0.category_sport)
  map.put("category_sport_event", CommonMainString0.category_sport_event)
  map.put("category_tax", CommonMainString0.category_tax)
  map.put("category_taxi", CommonMainString0.category_taxi)
  map.put("category_technology", CommonMainString0.category_technology)
  map.put("category_tolls", CommonMainString0.category_tolls)
  map.put("category_transport_travel", CommonMainString0.category_transport_travel)
  map.put("category_transportation", CommonMainString0.category_transportation)
  map.put("category_utilities", CommonMainString0.category_utilities)
  map.put("category_water", CommonMainString0.category_water)
  map.put("change_color_mode", CommonMainString0.change_color_mode)
  map.put("change_theme", CommonMainString0.change_theme)
  map.put("close_picker", CommonMainString0.close_picker)
  map.put("collapsed", CommonMainString0.collapsed)
  map.put("confirm_delete_account", CommonMainString0.confirm_delete_account)
  map.put("confirm_delete_expense_message", CommonMainString0.confirm_delete_expense_message)
  map.put("confirm_mark_all_settled", CommonMainString0.confirm_mark_all_settled)
  map.put("confirm_no_wait", CommonMainString0.confirm_no_wait)
  map.put("confirm_yes_delete", CommonMainString0.confirm_yes_delete)
  map.put("confirm_yes_join", CommonMainString0.confirm_yes_join)
  map.put("confirm_yes_leave", CommonMainString0.confirm_yes_leave)
  map.put("confirm_yes_settle", CommonMainString0.confirm_yes_settle)
  map.put("contacts_in_book", CommonMainString0.contacts_in_book)
  map.put("contacts_in_wesplit", CommonMainString0.contacts_in_wesplit)
  map.put("create", CommonMainString0.create)
  map.put("create_new_contact", CommonMainString0.create_new_contact)
  map.put("current_group_participants", CommonMainString0.current_group_participants)
  map.put("dark_theme", CommonMainString0.dark_theme)
  map.put("data_syncing", CommonMainString0.data_syncing)
  map.put("delete_account", CommonMainString0.delete_account)
  map.put("delete_account_confirmation", CommonMainString0.delete_account_confirmation)
  map.put("delete_account_from_wesplit", CommonMainString0.delete_account_from_wesplit)
  map.put("delete_expense", CommonMainString0.delete_expense)
  map.put("delete_expense_group", CommonMainString0.delete_expense_group)
  map.put("delete_expense_title", CommonMainString0.delete_expense_title)
  map.put("download_mobile_app", CommonMainString0.download_mobile_app)
  map.put("edit_group", CommonMainString0.edit_group)
  map.put("empty_group_cd", CommonMainString0.empty_group_cd)
  map.put("empty_transaction_description", CommonMainString0.empty_transaction_description)
  map.put("empty_transactions_cd", CommonMainString0.empty_transactions_cd)
  map.put("enter_promocode", CommonMainString0.enter_promocode)
  map.put("enter_valid_promocode", CommonMainString0.enter_valid_promocode)
  map.put("equal", CommonMainString0.equal)
  map.put("error", CommonMainString0.error)
  map.put("error_encountered_problems", CommonMainString0.error_encountered_problems)
  map.put("error_fetching_plans", CommonMainString0.error_fetching_plans)
  map.put("expanded", CommonMainString0.expanded)
  map.put("expense_title", CommonMainString0.expense_title)
  map.put("feedback", CommonMainString0.feedback)
  map.put("fetch_available_plans", CommonMainString0.fetch_available_plans)
  map.put("forget_group", CommonMainString0.forget_group)
  map.put("forgive_and_forget", CommonMainString0.forgive_and_forget)
  map.put("fx_single_currency", CommonMainString0.fx_single_currency)
  map.put("get_app", CommonMainString0.get_app)
  map.put("get_mobile_app", CommonMainString0.get_mobile_app)
  map.put("get_plus_instant_balances", CommonMainString0.get_plus_instant_balances)
  map.put("gets_back", CommonMainString0.gets_back)
  map.put("go_to_privacy", CommonMainString0.go_to_privacy)
  map.put("go_to_terms_conditions", CommonMainString0.go_to_terms_conditions)
  map.put("got_it", CommonMainString0.got_it)
  map.put("grant_permission", CommonMainString0.grant_permission)
  map.put("group_detailed_empty_description", CommonMainString0.group_detailed_empty_description)
  map.put("group_list_empty_description_authorized",
      CommonMainString0.group_list_empty_description_authorized)
  map.put("group_list_empty_description_unregistered",
      CommonMainString0.group_list_empty_description_unregistered)
  map.put("group_list_title", CommonMainString0.group_list_title)
  map.put("group_name", CommonMainString0.group_name)
  map.put("groups", CommonMainString0.groups)
  map.put("image_description_ai", CommonMainString0.image_description_ai)
  map.put("info_sign", CommonMainString0.info_sign)
  map.put("invite_user", CommonMainString0.invite_user)
  map.put("join_agree", CommonMainString0.join_agree)
  map.put("join_group", CommonMainString0.join_group)
  map.put("join_group_as_new_participant", CommonMainString0.join_group_as_new_participant)
  map.put("join_group_as_user", CommonMainString0.join_group_as_user)
  map.put("join_new_participant", CommonMainString0.join_new_participant)
  map.put("label_apply", CommonMainString0.label_apply)
  map.put("label_code", CommonMainString0.label_code)
  map.put("leave_group", CommonMainString0.leave_group)
  map.put("leave_group_confirmation", CommonMainString0.leave_group_confirmation)
  map.put("light_theme", CommonMainString0.light_theme)
  map.put("loading", CommonMainString0.loading)
  map.put("locked_feature", CommonMainString0.locked_feature)
  map.put("login", CommonMainString0.login)
  map.put("login_anon", CommonMainString0.login_anon)
  map.put("login_button_cd", CommonMainString0.login_button_cd)
  map.put("login_to_create_descr", CommonMainString0.login_to_create_descr)
  map.put("login_to_protect_expense", CommonMainString0.login_to_protect_expense)
  map.put("login_with_apple_cd", CommonMainString0.login_with_apple_cd)
  map.put("login_with_google_cd", CommonMainString0.login_with_google_cd)
  map.put("logout", CommonMainString0.logout)
  map.put("mark_as_non_settled", CommonMainString0.mark_as_non_settled)
  map.put("mark_as_settled", CommonMainString0.mark_as_settled)
  map.put("month_plus", CommonMainString0.month_plus)
  map.put("new", CommonMainString0.new)
  map.put("new_expense", CommonMainString0.new_expense)
  map.put("new_group", CommonMainString0.new_group)
  map.put("no_contact_found", CommonMainString0.no_contact_found)
  map.put("no_image_group_cd", CommonMainString0.no_image_group_cd)
  map.put("no_image_user", CommonMainString0.no_image_user)
  map.put("non_distr_cd", CommonMainString0.non_distr_cd)
  map.put("not_allowed_to_update_expense", CommonMainString0.not_allowed_to_update_expense)
  map.put("not_found", CommonMainString0.not_found)
  map.put("not_participating", CommonMainString0.not_participating)
  map.put("not_settled", CommonMainString0.not_settled)
  map.put("not_split", CommonMainString0.not_split)
  map.put("not_split_amount", CommonMainString0.not_split_amount)
  map.put("offer_badge", CommonMainString0.offer_badge)
  map.put("offline_balances_shown", CommonMainString0.offline_balances_shown)
  map.put("okey", CommonMainString0.okey)
  map.put("only_this_transaction", CommonMainString0.only_this_transaction)
  map.put("only_you_can_update_expense", CommonMainString0.only_you_can_update_expense)
  map.put("or", CommonMainString0.or)
  map.put("other", CommonMainString0.other)
  map.put("paid_by", CommonMainString0.paid_by)
  map.put("paid_by_me", CommonMainString0.paid_by_me)
  map.put("paid_by_person", CommonMainString0.paid_by_person)
  map.put("paid_by_you", CommonMainString0.paid_by_you)
  map.put("per_month", CommonMainString0.per_month)
  map.put("personal_expense", CommonMainString0.personal_expense)
  map.put("plus_active", CommonMainString0.plus_active)
  map.put("plus_badge", CommonMainString0.plus_badge)
  map.put("plus_badge_benefits", CommonMainString0.plus_badge_benefits)
  map.put("plus_feature_cats_cta", CommonMainString0.plus_feature_cats_cta)
  map.put("plus_feature_cats_descr_full", CommonMainString0.plus_feature_cats_descr_full)
  map.put("plus_feature_cats_descr_short", CommonMainString0.plus_feature_cats_descr_short)
  map.put("plus_feature_cats_title", CommonMainString0.plus_feature_cats_title)
  map.put("plus_feature_currencies_descr_full",
      CommonMainString0.plus_feature_currencies_descr_full)
  map.put("plus_feature_currencies_descr_short",
      CommonMainString0.plus_feature_currencies_descr_short)
  map.put("plus_feature_currencies_title", CommonMainString0.plus_feature_currencies_title)
  map.put("plus_feature_images_descr_full", CommonMainString0.plus_feature_images_descr_full)
  map.put("plus_feature_images_descr_short", CommonMainString0.plus_feature_images_descr_short)
  map.put("plus_feature_images_title", CommonMainString0.plus_feature_images_title)
  map.put("plus_feature_more_descr", CommonMainString0.plus_feature_more_descr)
  map.put("plus_feature_more_title", CommonMainString0.plus_feature_more_title)
  map.put("plus_feature_noads_descr_full", CommonMainString0.plus_feature_noads_descr_full)
  map.put("plus_feature_noads_descr_short", CommonMainString0.plus_feature_noads_descr_short)
  map.put("plus_feature_noads_title", CommonMainString0.plus_feature_noads_title)
  map.put("plus_feature_protect_descr_full", CommonMainString0.plus_feature_protect_descr_full)
  map.put("plus_feature_protect_descr_short", CommonMainString0.plus_feature_protect_descr_short)
  map.put("plus_feature_protect_title", CommonMainString0.plus_feature_protect_title)
  map.put("plus_feature_quick_add_descr_full", CommonMainString0.plus_feature_quick_add_descr_full)
  map.put("plus_feature_quick_add_descr_short",
      CommonMainString0.plus_feature_quick_add_descr_short)
  map.put("plus_feature_quick_add_title", CommonMainString0.plus_feature_quick_add_title)
  map.put("plus_feature_single_settle_descr_full",
      CommonMainString0.plus_feature_single_settle_descr_full)
  map.put("plus_feature_single_settle_descr_short",
      CommonMainString0.plus_feature_single_settle_descr_short)
  map.put("plus_feature_single_settle_title", CommonMainString0.plus_feature_single_settle_title)
  map.put("plus_feature_suggest_descr_full", CommonMainString0.plus_feature_suggest_descr_full)
  map.put("plus_feature_suggest_descr_short", CommonMainString0.plus_feature_suggest_descr_short)
  map.put("plus_feature_suggest_title", CommonMainString0.plus_feature_suggest_title)
  map.put("plus_subs_title", CommonMainString0.plus_subs_title)
  map.put("privacy_policy", CommonMainString0.privacy_policy)
  map.put("problems_encountered", CommonMainString0.problems_encountered)
  map.put("profile", CommonMainString0.profile)
  map.put("profile_under_construction", CommonMainString0.profile_under_construction)
  map.put("protect_expense", CommonMainString0.protect_expense)
  map.put("protect_expense_editing", CommonMainString0.protect_expense_editing)
  map.put("protected_expense", CommonMainString0.protected_expense)
  map.put("protected_feature", CommonMainString0.protected_feature)
  map.put("quick_add", CommonMainString0.quick_add)
  map.put("recalculate", CommonMainString0.recalculate)
  map.put("recalculating_backend", CommonMainString0.recalculating_backend)
  map.put("retry", CommonMainString0.retry)
  map.put("save", CommonMainString0.save)
  map.put("search_contact", CommonMainString0.search_contact)
  map.put("search_currency", CommonMainString0.search_currency)
  map.put("select_currency", CommonMainString0.select_currency)
  map.put("select_payer_cd", CommonMainString0.select_payer_cd)
  map.put("settings", CommonMainString0.settings)
  map.put("settle_all_balances", CommonMainString0.settle_all_balances)
  map.put("settle_balances", CommonMainString0.settle_balances)
  map.put("settled", CommonMainString0.settled)
  map.put("settled_all_expenses", CommonMainString0.settled_all_expenses)
  map.put("settled_message", CommonMainString0.settled_message)
  map.put("share_group", CommonMainString0.share_group)
  map.put("share_link_copied", CommonMainString0.share_link_copied)
  map.put("share_subject", CommonMainString0.share_subject)
  map.put("shares", CommonMainString0.shares)
  map.put("should_pay_to", CommonMainString0.should_pay_to)
  map.put("start_type_creat_contact", CommonMainString0.start_type_creat_contact)
  map.put("submit_feedback_cd", CommonMainString0.submit_feedback_cd)
  map.put("subscribe_for", CommonMainString0.subscribe_for)
  map.put("subscribe_plus", CommonMainString0.subscribe_plus)
  map.put("subscribe_via_mobile_app", CommonMainString0.subscribe_via_mobile_app)
  map.put("suggest_split", CommonMainString0.suggest_split)
  map.put("sync_in_progress", CommonMainString0.sync_in_progress)
  map.put("system_theme", CommonMainString0.system_theme)
  map.put("terms_conditions", CommonMainString0.terms_conditions)
  map.put("text_its_me", CommonMainString0.text_its_me)
  map.put("text_loading", CommonMainString0.text_loading)
  map.put("title_leave_group", CommonMainString0.title_leave_group)
  map.put("title_should_be_filled", CommonMainString0.title_should_be_filled)
  map.put("transactions", CommonMainString0.transactions)
  map.put("try_days", CommonMainString0.try_days)
  map.put("try_month_plus", CommonMainString0.try_month_plus)
  map.put("try_plus", CommonMainString0.try_plus)
  map.put("try_week_plus", CommonMainString0.try_week_plus)
  map.put("try_year_plus", CommonMainString0.try_year_plus)
  map.put("tutorial_info", CommonMainString0.tutorial_info)
  map.put("tutorial_step_add_expense_description",
      CommonMainString0.tutorial_step_add_expense_description)
  map.put("tutorial_step_add_expense_title", CommonMainString0.tutorial_step_add_expense_title)
  map.put("tutorial_step_add_participant_description",
      CommonMainString0.tutorial_step_add_participant_description)
  map.put("tutorial_step_add_participant_title",
      CommonMainString0.tutorial_step_add_participant_title)
  map.put("tutorial_step_balances_tab_description",
      CommonMainString0.tutorial_step_balances_tab_description)
  map.put("tutorial_step_balances_tab_title", CommonMainString0.tutorial_step_balances_tab_title)
  map.put("tutorial_step_check_balances_description",
      CommonMainString0.tutorial_step_check_balances_description)
  map.put("tutorial_step_check_balances_title",
      CommonMainString0.tutorial_step_check_balances_title)
  map.put("tutorial_step_choose_payer", CommonMainString0.tutorial_step_choose_payer)
  map.put("tutorial_step_choose_payer_description",
      CommonMainString0.tutorial_step_choose_payer_description)
  map.put("tutorial_step_confirm_selection_description",
      CommonMainString0.tutorial_step_confirm_selection_description)
  map.put("tutorial_step_confirm_selection_title",
      CommonMainString0.tutorial_step_confirm_selection_title)
  map.put("tutorial_step_create_group_description",
      CommonMainString0.tutorial_step_create_group_description)
  map.put("tutorial_step_create_group_title", CommonMainString0.tutorial_step_create_group_title)
  map.put("tutorial_step_date_currency_description",
      CommonMainString0.tutorial_step_date_currency_description)
  map.put("tutorial_step_date_currency_setup", CommonMainString0.tutorial_step_date_currency_setup)
  map.put("tutorial_step_expense_title", CommonMainString0.tutorial_step_expense_title)
  map.put("tutorial_step_expense_title_description",
      CommonMainString0.tutorial_step_expense_title_description)
  map.put("tutorial_step_input_name_description",
      CommonMainString0.tutorial_step_input_name_description)
  map.put("tutorial_step_input_name_title", CommonMainString0.tutorial_step_input_name_title)
  map.put("tutorial_step_invite_friends_description",
      CommonMainString0.tutorial_step_invite_friends_description)
  map.put("tutorial_step_invite_friends_title",
      CommonMainString0.tutorial_step_invite_friends_title)
  map.put("tutorial_step_put_amount", CommonMainString0.tutorial_step_put_amount)
  map.put("tutorial_step_put_amount_description",
      CommonMainString0.tutorial_step_put_amount_description)
  map.put("tutorial_step_save_expense", CommonMainString0.tutorial_step_save_expense)
  map.put("tutorial_step_save_expense_description",
      CommonMainString0.tutorial_step_save_expense_description)
  map.put("tutorial_step_select_user_description",
      CommonMainString0.tutorial_step_select_user_description)
  map.put("tutorial_step_select_user_title", CommonMainString0.tutorial_step_select_user_title)
  map.put("tutorial_step_settle_up_description",
      CommonMainString0.tutorial_step_settle_up_description)
  map.put("tutorial_step_settle_up_title", CommonMainString0.tutorial_step_settle_up_title)
  map.put("undistributed", CommonMainString0.undistributed)
  map.put("unlock_all_features", CommonMainString0.unlock_all_features)
  map.put("user_already_in_group", CommonMainString0.user_already_in_group)
  map.put("users_count", CommonMainString0.users_count)
  map.put("valid_subs", CommonMainString0.valid_subs)
  map.put("valid_subs_days_more", CommonMainString0.valid_subs_days_more)
  map.put("valid_subs_hours_more", CommonMainString0.valid_subs_hours_more)
  map.put("we_split_in", CommonMainString0.we_split_in)
  map.put("web_billing_in_progress", CommonMainString0.web_billing_in_progress)
  map.put("week_plus", CommonMainString0.week_plus)
  map.put("wesplit_logo", CommonMainString0.wesplit_logo)
  map.put("what_you_get_with", CommonMainString0.what_you_get_with)
  map.put("with_me", CommonMainString0.with_me)
  map.put("year_plus", CommonMainString0.year_plus)
  map.put("you", CommonMainString0.you)
  map.put("you_borrowed", CommonMainString0.you_borrowed)
  map.put("you_have_plus", CommonMainString0.you_have_plus)
  map.put("you_lent", CommonMainString0.you_lent)
  map.put("your_share", CommonMainString0.your_share)
}

internal val Res.string.add_expense_to_group: StringResource
  get() = CommonMainString0.add_expense_to_group

private fun init_add_expense_to_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_expense_to_group", "add_expense_to_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr", 10,
    88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr", 10,
    124),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10, 76),
    )
)

internal val Res.string.add_group_cd: StringResource
  get() = CommonMainString0.add_group_cd

private fun init_add_group_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_group_cd", "add_group_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr", 99,
    48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    135, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 87, 44),
    )
)

internal val Res.string.add_group_image: StringResource
  get() = CommonMainString0.add_group_image

private fun init_add_group_image(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_group_image", "add_group_image",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    148, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    208, 95),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 132, 43),
    )
)

internal val Res.string.add_user_to_group: StringResource
  get() = CommonMainString0.add_user_to_group

private fun init_add_user_to_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_user_to_group", "add_user_to_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    204, 77),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    304, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 176, 53),
    )
)

internal val Res.string.already_settled: StringResource
  get() = CommonMainString0.already_settled

private fun init_already_settled(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:already_settled", "already_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    282, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    378, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 230, 43),
    )
)

internal val Res.string.amount: StringResource
  get() = CommonMainString0.amount

private fun init_amount(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:amount", "amount",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    358, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    466, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 302, 22),
    )
)

internal val Res.string.amounts: StringResource
  get() = CommonMainString0.amounts

private fun init_amounts(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:amounts", "amounts",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    330, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    434, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 274, 27),
    )
)

internal val Res.string.andspaces: StringResource
  get() = CommonMainString0.andspaces

private fun init_andspaces(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:andspaces", "andspaces",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    381, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    497, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 325, 25),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/keys.commonMain.cvr", 10, 28),
    )
)

internal val Res.string.app_version: StringResource
  get() = CommonMainString0.app_version

private fun init_app_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_version", "app_version",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/keys.commonMain.cvr", 39, 31),
    )
)

internal val Res.string.at_date: StringResource
  get() = CommonMainString0.at_date

private fun init_at_date(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:at_date", "at_date",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    407, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    523, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 351, 27),
    )
)

internal val Res.string.available_in_mobile_app: StringResource
  get() = CommonMainString0.available_in_mobile_app

private fun init_available_in_mobile_app(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:available_in_mobile_app", "available_in_mobile_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    435, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    551, 135),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 379, 75),
    )
)

internal val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    551, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    731, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 487, 20),
    )
)

internal val Res.string.back_btn_cd: StringResource
  get() = CommonMainString0.back_btn_cd

private fun init_back_btn_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back_btn_cd", "back_btn_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    515, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    687, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 455, 31),
    )
)

internal val Res.string.balances: StringResource
  get() = CommonMainString0.balances

private fun init_balances(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:balances", "balances",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    849, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1109, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 725, 28),
    )
)

internal val Res.string.balances_explanation: StringResource
  get() = CommonMainString0.balances_explanation

private fun init_balances_explanation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:balances_explanation", "balances_explanation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    576, 272),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    760, 348),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 508,
    216),
    )
)

internal val Res.string.benefits: StringResource
  get() = CommonMainString0.benefits

private fun init_benefits(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:benefits", "benefits",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    874, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1146, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 754, 28),
    )
)

internal val Res.string.best_offer_badge: StringResource
  get() = CommonMainString0.best_offer_badge

private fun init_best_offer_badge(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:best_offer_badge", "best_offer_badge",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    903, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1195, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 783, 48),
    )
)

internal val Res.string.calculate_best_way_settle: StringResource
  get() = CommonMainString0.calculate_best_way_settle

private fun init_calculate_best_way_settle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:calculate_best_way_settle", "calculate_best_way_settle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    956, 93),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1288, 125),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 832, 73),
    )
)

internal val Res.string.cancel: StringResource
  get() = CommonMainString0.cancel

private fun init_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cancel", "cancel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1050, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1414, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 906, 22),
    )
)

internal val Res.string.category_bank: StringResource
  get() = CommonMainString0.category_bank

private fun init_category_bank(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_bank", "category_bank",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1077, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1445, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 929, 29),
    )
)

internal val Res.string.category_beauty: StringResource
  get() = CommonMainString0.category_beauty

private fun init_category_beauty(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_beauty", "category_beauty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1107, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1495, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 959, 31),
    )
)

internal val Res.string.category_books: StringResource
  get() = CommonMainString0.category_books

private fun init_category_books(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_books", "category_books",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1147, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1539, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 991, 30),
    )
)

internal val Res.string.category_car: StringResource
  get() = CommonMainString0.category_car

private fun init_category_car(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_car", "category_car",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1182, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1578, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1022,
    24),
    )
)

internal val Res.string.category_cash: StringResource
  get() = CommonMainString0.category_cash

private fun init_category_cash(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_cash", "category_cash",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1211, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1627, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1047,
    29),
    )
)

internal val Res.string.category_cleaning: StringResource
  get() = CommonMainString0.category_cleaning

private fun init_category_cleaning(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_cleaning", "category_cleaning",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1237, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1673, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1077,
    37),
    )
)

internal val Res.string.category_clothes: StringResource
  get() = CommonMainString0.category_clothes

private fun init_category_clothes(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_clothes", "category_clothes",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1275, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1715, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1115,
    36),
    )
)

internal val Res.string.category_coffee: StringResource
  get() = CommonMainString0.category_coffee

private fun init_category_coffee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_coffee", "category_coffee",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1312, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1756, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1152,
    31),
    )
)

internal val Res.string.category_concert: StringResource
  get() = CommonMainString0.category_concert

private fun init_category_concert(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_concert", "category_concert",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1344, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1792, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1184,
    36),
    )
)

internal val Res.string.category_crypto: StringResource
  get() = CommonMainString0.category_crypto

private fun init_category_crypto(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_crypto", "category_crypto",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1381, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1837, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1221,
    31),
    )
)

internal val Res.string.category_electricity: StringResource
  get() = CommonMainString0.category_electricity

private fun init_category_electricity(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_electricity", "category_electricity",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1413, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1893, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1253,
    48),
    )
)

internal val Res.string.category_entertainment: StringResource
  get() = CommonMainString0.category_entertainment

private fun init_category_entertainment(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_entertainment", "category_entertainment",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1454, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1958, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1302,
    50),
    )
)

internal val Res.string.category_fast_food: StringResource
  get() = CommonMainString0.category_fast_food

private fun init_category_fast_food(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_fast_food", "category_fast_food",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1501, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2021, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1353,
    38),
    )
)

internal val Res.string.category_fee: StringResource
  get() = CommonMainString0.category_fee

private fun init_category_fee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_fee", "category_fee",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1540, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2068, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1392,
    24),
    )
)

internal val Res.string.category_flight: StringResource
  get() = CommonMainString0.category_flight

private fun init_category_flight(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_flight", "category_flight",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1573, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2101, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1417,
    31),
    )
)

internal val Res.string.category_food_drink: StringResource
  get() = CommonMainString0.category_food_drink

private fun init_category_food_drink(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_food_drink", "category_food_drink",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1605, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2145, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1449,
    43),
    )
)

internal val Res.string.category_furnishing: StringResource
  get() = CommonMainString0.category_furnishing

private fun init_category_furnishing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_furnishing", "category_furnishing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1653, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2205, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1493,
    43),
    )
)

internal val Res.string.category_garbage: StringResource
  get() = CommonMainString0.category_garbage

private fun init_category_garbage(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_garbage", "category_garbage",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1697, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2261, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1537,
    36),
    )
)

internal val Res.string.category_gifts: StringResource
  get() = CommonMainString0.category_gifts

private fun init_category_gifts(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_gifts", "category_gifts",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1730, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2302, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1574,
    30),
    )
)

internal val Res.string.category_groceries: StringResource
  get() = CommonMainString0.category_groceries

private fun init_category_groceries(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_groceries", "category_groceries",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1765, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2345, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1605,
    38),
    )
)

internal val Res.string.category_health: StringResource
  get() = CommonMainString0.category_health

private fun init_category_health(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_health", "category_health",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1871, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2475, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1695,
    31),
    )
)

internal val Res.string.category_health_beauty: StringResource
  get() = CommonMainString0.category_health_beauty

private fun init_category_health_beauty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_health_beauty", "category_health_beauty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1808, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2396, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1644,
    50),
    )
)

internal val Res.string.category_hobby: StringResource
  get() = CommonMainString0.category_hobby

private fun init_category_hobby(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_hobby", "category_hobby",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1911, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2523, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1727,
    30),
    )
)

internal val Res.string.category_housing: StringResource
  get() = CommonMainString0.category_housing

private fun init_category_housing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_housing", "category_housing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1942, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2562, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1758,
    36),
    )
)

internal val Res.string.category_internet: StringResource
  get() = CommonMainString0.category_internet

private fun init_category_internet(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_internet", "category_internet",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1975, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2603, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1795,
    37),
    )
)

internal val Res.string.category_magic: StringResource
  get() = CommonMainString0.category_magic

private fun init_category_magic(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_magic", "category_magic",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2013, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2653, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1833,
    38),
    )
)

internal val Res.string.category_money: StringResource
  get() = CommonMainString0.category_money

private fun init_category_money(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_money", "category_money",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2052, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2716, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1872,
    42),
    )
)

internal val Res.string.category_movie: StringResource
  get() = CommonMainString0.category_movie

private fun init_category_movie(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_movie", "category_movie",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2091, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2759, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1915,
    30),
    )
)

internal val Res.string.category_none: StringResource
  get() = CommonMainString0.category_none

private fun init_category_none(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_none", "category_none",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2122, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2798, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1946,
    41),
    )
)

internal val Res.string.category_parking: StringResource
  get() = CommonMainString0.category_parking

private fun init_category_parking(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_parking", "category_parking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2164, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2856, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1988,
    36),
    )
)

internal val Res.string.category_public: StringResource
  get() = CommonMainString0.category_public

private fun init_category_public(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_public", "category_public",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2197, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2905, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2025,
    47),
    )
)

internal val Res.string.category_recycling: StringResource
  get() = CommonMainString0.category_recycling

private fun init_category_recycling(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_recycling", "category_recycling",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2257, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2965, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2073,
    38),
    )
)

internal val Res.string.category_rent: StringResource
  get() = CommonMainString0.category_rent

private fun init_category_rent(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_rent", "category_rent",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2296, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3024, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2112,
    29),
    )
)

internal val Res.string.category_repair: StringResource
  get() = CommonMainString0.category_repair

private fun init_category_repair(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_repair", "category_repair",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2326, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3062, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2142,
    31),
    )
)

internal val Res.string.category_restaurant: StringResource
  get() = CommonMainString0.category_restaurant

private fun init_category_restaurant(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_restaurant", "category_restaurant",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2362, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3102, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2174,
    43),
    )
)

internal val Res.string.category_security: StringResource
  get() = CommonMainString0.category_security

private fun init_category_security(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_security", "category_security",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2406, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3154, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2218,
    37),
    )
)

internal val Res.string.category_shoes: StringResource
  get() = CommonMainString0.category_shoes

private fun init_category_shoes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_shoes", "category_shoes",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2448, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3212, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2256,
    30),
    )
)

internal val Res.string.category_shopping: StringResource
  get() = CommonMainString0.category_shopping

private fun init_category_shopping(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_shopping", "category_shopping",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2479, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3251, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2287,
    37),
    )
)

internal val Res.string.category_sport: StringResource
  get() = CommonMainString0.category_sport

private fun init_category_sport(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_sport", "category_sport",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2570, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3374, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2370,
    30),
    )
)

internal val Res.string.category_sport_event: StringResource
  get() = CommonMainString0.category_sport_event

private fun init_category_sport_event(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_sport_event", "category_sport_event",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2517, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3297, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2325,
    44),
    )
)

internal val Res.string.category_tax: StringResource
  get() = CommonMainString0.category_tax

private fun init_category_tax(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_tax", "category_tax",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2631, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3451, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2431,
    36),
    )
)

internal val Res.string.category_taxi: StringResource
  get() = CommonMainString0.category_taxi

private fun init_category_taxi(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_taxi", "category_taxi",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2601, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3413, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2401,
    29),
    )
)

internal val Res.string.category_technology: StringResource
  get() = CommonMainString0.category_technology

private fun init_category_technology(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_technology", "category_technology",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2668, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3496, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2468,
    43),
    )
)

internal val Res.string.category_tolls: StringResource
  get() = CommonMainString0.category_tolls

private fun init_category_tolls(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_tolls", "category_tolls",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2712, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3552, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2512,
    30),
    )
)

internal val Res.string.category_transport_travel: StringResource
  get() = CommonMainString0.category_transport_travel

private fun init_category_transport_travel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_transport_travel", "category_transport_travel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2743, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3611, 81),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2543,
    57),
    )
)

internal val Res.string.category_transportation: StringResource
  get() = CommonMainString0.category_transportation

private fun init_category_transportation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_transportation", "category_transportation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2801, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3693, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2601,
    51),
    )
)

internal val Res.string.category_utilities: StringResource
  get() = CommonMainString0.category_utilities

private fun init_category_utilities(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_utilities", "category_utilities",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2845, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3749, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2653,
    38),
    )
)

internal val Res.string.category_water: StringResource
  get() = CommonMainString0.category_water

private fun init_category_water(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_water", "category_water",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2888, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3828, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2692,
    30),
    )
)

internal val Res.string.change_color_mode: StringResource
  get() = CommonMainString0.change_color_mode

private fun init_change_color_mode(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:change_color_mode", "change_color_mode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2919, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3863, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2723,
    49),
    )
)

internal val Res.string.change_theme: StringResource
  get() = CommonMainString0.change_theme

private fun init_change_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:change_theme", "change_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2969, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3949, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2773,
    36),
    )
)

internal val Res.string.close_picker: StringResource
  get() = CommonMainString0.close_picker

private fun init_close_picker(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:close_picker", "close_picker",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3010, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4006, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2810,
    40),
    )
)

internal val Res.string.collapsed: StringResource
  get() = CommonMainString0.collapsed

private fun init_collapsed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:collapsed", "collapsed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3063, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4051, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2851,
    29),
    )
)

internal val Res.string.confirm_delete_account: StringResource
  get() = CommonMainString0.confirm_delete_account

private fun init_confirm_delete_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_delete_account", "confirm_delete_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3105, 102),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4093, 162),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2881,
    114),
    )
)

internal val Res.string.confirm_delete_expense_message: StringResource
  get() = CommonMainString0.confirm_delete_expense_message

private fun init_confirm_delete_expense_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_delete_expense_message", "confirm_delete_expense_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3208, 114),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4256, 158),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2996,
    114),
    )
)

internal val Res.string.confirm_mark_all_settled: StringResource
  get() = CommonMainString0.confirm_mark_all_settled

private fun init_confirm_mark_all_settled(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_mark_all_settled", "confirm_mark_all_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3323, 128),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4415, 196),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3111,
    124),
    )
)

internal val Res.string.confirm_no_wait: StringResource
  get() = CommonMainString0.confirm_no_wait

private fun init_confirm_no_wait(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:confirm_no_wait", "confirm_no_wait",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3452, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4612, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3236,
    35),
    )
)

internal val Res.string.confirm_yes_delete: StringResource
  get() = CommonMainString0.confirm_yes_delete

private fun init_confirm_yes_delete(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_yes_delete", "confirm_yes_delete",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3492, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4672, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3272,
    42),
    )
)

internal val Res.string.confirm_yes_join: StringResource
  get() = CommonMainString0.confirm_yes_join

private fun init_confirm_yes_join(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_yes_join", "confirm_yes_join",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3535, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4727, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3315,
    36),
    )
)

internal val Res.string.confirm_yes_leave: StringResource
  get() = CommonMainString0.confirm_yes_leave

private fun init_confirm_yes_leave(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_yes_leave", "confirm_yes_leave",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3580, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4800, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3352,
    41),
    )
)

internal val Res.string.confirm_yes_settle: StringResource
  get() = CommonMainString0.confirm_yes_settle

private fun init_confirm_yes_settle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_yes_settle", "confirm_yes_settle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3626, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4850, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3394,
    42),
    )
)

internal val Res.string.contacts_in_book: StringResource
  get() = CommonMainString0.contacts_in_book

private fun init_contacts_in_book(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:contacts_in_book", "contacts_in_book",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3673, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4913, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3437,
    56),
    )
)

internal val Res.string.contacts_in_wesplit: StringResource
  get() = CommonMainString0.contacts_in_wesplit

private fun init_contacts_in_wesplit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:contacts_in_wesplit", "contacts_in_wesplit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3730, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5002, 111),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3494,
    75),
    )
)

internal val Res.string.create: StringResource
  get() = CommonMainString0.create

private fun init_create(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create", "create",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3881, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5209, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3637,
    22),
    )
)

internal val Res.string.create_new_contact: StringResource
  get() = CommonMainString0.create_new_contact

private fun init_create_new_contact(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_new_contact", "create_new_contact",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3814, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5114, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3570,
    66),
    )
)

internal val Res.string.current_group_participants: StringResource
  get() = CommonMainString0.current_group_participants

private fun init_current_group_participants(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:current_group_participants", "current_group_participants",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3908, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5244, 98),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3660,
    70),
    )
)

internal val Res.string.dark_theme: StringResource
  get() = CommonMainString0.dark_theme

private fun init_dark_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dark_theme", "dark_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3979, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5343, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3731,
    26),
    )
)

internal val Res.string.data_syncing: StringResource
  get() = CommonMainString0.data_syncing

private fun init_data_syncing(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:data_syncing", "data_syncing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4006, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5378, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3758,
    40),
    )
)

internal val Res.string.delete_account: StringResource
  get() = CommonMainString0.delete_account

private fun init_delete_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:delete_account", "delete_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4191, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5627, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3927,
    42),
    )
)

internal val Res.string.delete_account_confirmation: StringResource
  get() = CommonMainString0.delete_account_confirmation

private fun init_delete_account_confirmation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:delete_account_confirmation", "delete_account_confirmation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4063, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5459, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3799,
    55),
    )
)

internal val Res.string.delete_account_from_wesplit: StringResource
  get() = CommonMainString0.delete_account_from_wesplit

private fun init_delete_account_from_wesplit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:delete_account_from_wesplit", "delete_account_from_wesplit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4119, 71),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5535, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3855,
    71),
    )
)

internal val Res.string.delete_expense: StringResource
  get() = CommonMainString0.delete_expense

private fun init_delete_expense(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:delete_expense", "delete_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4348, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5836, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4080,
    42),
    )
)

internal val Res.string.delete_expense_group: StringResource
  get() = CommonMainString0.delete_expense_group

private fun init_delete_expense_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:delete_expense_group", "delete_expense_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4234, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5690, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3970,
    64),
    )
)

internal val Res.string.delete_expense_title: StringResource
  get() = CommonMainString0.delete_expense_title

private fun init_delete_expense_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:delete_expense_title", "delete_expense_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4299, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5779, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4035,
    44),
    )
)

internal val Res.string.download_mobile_app: StringResource
  get() = CommonMainString0.download_mobile_app

private fun init_download_mobile_app(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:download_mobile_app", "download_mobile_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4395, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5895, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4123,
    55),
    )
)

internal val Res.string.edit_group: StringResource
  get() = CommonMainString0.edit_group

private fun init_edit_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:edit_group", "edit_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4455, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5995, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4179,
    34),
    )
)

internal val Res.string.empty_group_cd: StringResource
  get() = CommonMainString0.empty_group_cd

private fun init_empty_group_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:empty_group_cd", "empty_group_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4498, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6066, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4214,
    46),
    )
)

internal val Res.string.empty_transaction_description: StringResource
  get() = CommonMainString0.empty_transaction_description

private fun init_empty_transaction_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:empty_transaction_description", "empty_transaction_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4553, 177),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6133, 205),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4261,
    125),
    )
)

internal val Res.string.empty_transactions_cd: StringResource
  get() = CommonMainString0.empty_transactions_cd

private fun init_empty_transactions_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:empty_transactions_cd", "empty_transactions_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4731, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6339, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4387,
    57),
    )
)

internal val Res.string.enter_promocode: StringResource
  get() = CommonMainString0.enter_promocode

private fun init_enter_promocode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_promocode", "enter_promocode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4793, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6405, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4445,
    43),
    )
)

internal val Res.string.enter_valid_promocode: StringResource
  get() = CommonMainString0.enter_valid_promocode

private fun init_enter_valid_promocode(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_valid_promocode", "enter_valid_promocode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4841, 81),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6473, 109),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4489,
    57),
    )
)

internal val Res.string.equal: StringResource
  get() = CommonMainString0.equal

private fun init_equal(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:equal", "equal",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4923, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6583, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4547,
    21),
    )
)

internal val Res.string.error: StringResource
  get() = CommonMainString0.error

private fun init_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error", "error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5121, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6873, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4725,
    21),
    )
)

internal val Res.string.error_encountered_problems: StringResource
  get() = CommonMainString0.error_encountered_problems

private fun init_error_encountered_problems(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:error_encountered_problems", "error_encountered_problems",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4945, 106),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6617, 166),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4569,
    98),
    )
)

internal val Res.string.error_fetching_plans: StringResource
  get() = CommonMainString0.error_fetching_plans

private fun init_error_fetching_plans(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:error_fetching_plans", "error_fetching_plans",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5052, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6784, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4668,
    56),
    )
)

internal val Res.string.expanded: StringResource
  get() = CommonMainString0.expanded

private fun init_expanded(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:expanded", "expanded",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5143, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6903, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4747,
    28),
    )
)

internal val Res.string.expense_title: StringResource
  get() = CommonMainString0.expense_title

private fun init_expense_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:expense_title", "expense_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5172, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6948, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4776,
    41),
    )
)

internal val Res.string.feedback: StringResource
  get() = CommonMainString0.feedback

private fun init_feedback(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:feedback", "feedback",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5218, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7006, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4818,
    28),
    )
)

internal val Res.string.fetch_available_plans: StringResource
  get() = CommonMainString0.fetch_available_plans

private fun init_fetch_available_plans(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fetch_available_plans", "fetch_available_plans",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5247, 73),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7039, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4847,
    65),
    )
)

internal val Res.string.forget_group: StringResource
  get() = CommonMainString0.forget_group

private fun init_forget_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:forget_group", "forget_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5321, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7141, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4913,
    36),
    )
)

internal val Res.string.forgive_and_forget: StringResource
  get() = CommonMainString0.forgive_and_forget

private fun init_forgive_and_forget(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:forgive_and_forget", "forgive_and_forget",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5366, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7198, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4950,
    62),
    )
)

internal val Res.string.fx_single_currency: StringResource
  get() = CommonMainString0.fx_single_currency

private fun init_fx_single_currency(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fx_single_currency", "fx_single_currency",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5441, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7269, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5013,
    54),
    )
)

internal val Res.string.get_app: StringResource
  get() = CommonMainString0.get_app

private fun init_get_app(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:get_app", "get_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5504, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7360, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5068,
    27),
    )
)

internal val Res.string.get_mobile_app: StringResource
  get() = CommonMainString0.get_mobile_app

private fun init_get_mobile_app(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:get_mobile_app", "get_mobile_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5532, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7396, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5096,
    42),
    )
)

internal val Res.string.get_plus_instant_balances: StringResource
  get() = CommonMainString0.get_plus_instant_balances

private fun init_get_plus_instant_balances(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:get_plus_instant_balances", "get_plus_instant_balances",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5579, 105),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7491, 149),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5139,
    81),
    )
)

internal val Res.string.gets_back: StringResource
  get() = CommonMainString0.gets_back

private fun init_gets_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:gets_back", "gets_back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5685, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7641, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5221,
    29),
    )
)

internal val Res.string.go_to_privacy: StringResource
  get() = CommonMainString0.go_to_privacy

private fun init_go_to_privacy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:go_to_privacy", "go_to_privacy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5723, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7703, 117),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5251,
    49),
    )
)

internal val Res.string.go_to_terms_conditions: StringResource
  get() = CommonMainString0.go_to_terms_conditions

private fun init_go_to_terms_conditions(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:go_to_terms_conditions", "go_to_terms_conditions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5789, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7821, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5301,
    66),
    )
)

internal val Res.string.got_it: StringResource
  get() = CommonMainString0.got_it

private fun init_got_it(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:got_it", "got_it",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5884, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7932, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5368,
    22),
    )
)

internal val Res.string.grant_permission: StringResource
  get() = CommonMainString0.grant_permission

private fun init_grant_permission(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:grant_permission", "grant_permission",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5915, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7963, 84),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5391,
    48),
    )
)

internal val Res.string.group_detailed_empty_description: StringResource
  get() = CommonMainString0.group_detailed_empty_description

private fun init_group_detailed_empty_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_detailed_empty_description", "group_detailed_empty_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5964, 164),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8048, 184),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5440,
    128),
    )
)

internal val Res.string.group_list_empty_description_authorized: StringResource
  get() = CommonMainString0.group_list_empty_description_authorized

private fun init_group_list_empty_description_authorized(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_list_empty_description_authorized", "group_list_empty_description_authorized",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6129, 135),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8233, 135),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5569,
    103),
    )
)

internal val Res.string.group_list_empty_description_unregistered: StringResource
  get() = CommonMainString0.group_list_empty_description_unregistered

private fun init_group_list_empty_description_unregistered(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_list_empty_description_unregistered", "group_list_empty_description_unregistered",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6265, 93),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8369, 117),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5673,
    81),
    )
)

internal val Res.string.group_list_title: StringResource
  get() = CommonMainString0.group_list_title

private fun init_group_list_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_list_title", "group_list_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6359, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8487, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5755,
    32),
    )
)

internal val Res.string.group_name: StringResource
  get() = CommonMainString0.group_name

private fun init_group_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:group_name", "group_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6396, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8528, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5788,
    34),
    )
)

internal val Res.string.groups: StringResource
  get() = CommonMainString0.groups

private fun init_groups(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:groups", "groups",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6431, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8587, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5823,
    22),
    )
)

internal val Res.string.image_description_ai: StringResource
  get() = CommonMainString0.image_description_ai

private fun init_image_description_ai(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:image_description_ai", "image_description_ai",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6458, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8618, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5846,
    60),
    )
)

internal val Res.string.info_sign: StringResource
  get() = CommonMainString0.info_sign

private fun init_info_sign(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:info_sign", "info_sign",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6523, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8707, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5907,
    29),
    )
)

internal val Res.string.invite_user: StringResource
  get() = CommonMainString0.invite_user

private fun init_invite_user(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:invite_user", "invite_user",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6557, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8781, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5937,
    35),
    )
)

internal val Res.string.join_agree: StringResource
  get() = CommonMainString0.join_agree

private fun init_join_agree(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_agree", "join_agree",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6601, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8861, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5973,
    58),
    )
)

internal val Res.string.join_group: StringResource
  get() = CommonMainString0.join_group

private fun init_join_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_group", "join_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6813, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9189, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6161,
    34),
    )
)

internal val Res.string.join_group_as_new_participant: StringResource
  get() = CommonMainString0.join_group_as_new_participant

private fun init_join_group_as_new_participant(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_group_as_new_participant", "join_group_as_new_participant",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6668, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8964, 133),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6032,
    77),
    )
)

internal val Res.string.join_group_as_user: StringResource
  get() = CommonMainString0.join_group_as_user

private fun init_join_group_as_user(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_group_as_user", "join_group_as_user",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6754, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9098, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6110,
    50),
    )
)

internal val Res.string.join_new_participant: StringResource
  get() = CommonMainString0.join_new_participant

private fun init_join_new_participant(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_new_participant", "join_new_participant",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6856, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9268, 112),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6196,
    60),
    )
)

internal val Res.string.label_apply: StringResource
  get() = CommonMainString0.label_apply

private fun init_label_apply(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:label_apply", "label_apply",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6925, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9381, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6257,
    27),
    )
)

internal val Res.string.label_code: StringResource
  get() = CommonMainString0.label_code

private fun init_label_code(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:label_code", "label_code",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6957, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9425, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6285,
    26),
    )
)

internal val Res.string.leave_group: StringResource
  get() = CommonMainString0.leave_group

private fun init_leave_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:leave_group", "leave_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7213, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9785, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6513,
    35),
    )
)

internal val Res.string.leave_group_confirmation: StringResource
  get() = CommonMainString0.leave_group_confirmation

private fun init_leave_group_confirmation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:leave_group_confirmation", "leave_group_confirmation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6984, 228),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9452, 332),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6312,
    200),
    )
)

internal val Res.string.light_theme: StringResource
  get() = CommonMainString0.light_theme

private fun init_light_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:light_theme", "light_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7257, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9845, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6549,
    27),
    )
)

internal val Res.string.loading: StringResource
  get() = CommonMainString0.loading

private fun init_loading(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:loading", "loading",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7285, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9885, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6577,
    27),
    )
)

internal val Res.string.locked_feature: StringResource
  get() = CommonMainString0.locked_feature

private fun init_locked_feature(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:locked_feature", "locked_feature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7309, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9925, 82),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6605,
    42),
    )
)

internal val Res.string.login: StringResource
  get() = CommonMainString0.login

private fun init_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login", "login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7775, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10511, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7015,
    21),
    )
)

internal val Res.string.login_anon: StringResource
  get() = CommonMainString0.login_anon

private fun init_login_anon(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_anon", "login_anon",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7356, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10008, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6648,
    42),
    )
)

internal val Res.string.login_button_cd: StringResource
  get() = CommonMainString0.login_button_cd

private fun init_login_button_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_button_cd", "login_button_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7395, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10063, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6691,
    31),
    )
)

internal val Res.string.login_to_create_descr: StringResource
  get() = CommonMainString0.login_to_create_descr

private fun init_login_to_create_descr(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_to_create_descr", "login_to_create_descr",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7431, 133),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10099, 149),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6723,
    113),
    )
)

internal val Res.string.login_to_protect_expense: StringResource
  get() = CommonMainString0.login_to_protect_expense

private fun init_login_to_protect_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_to_protect_expense", "login_to_protect_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7565, 100),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10249, 128),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6837,
    72),
    )
)

internal val Res.string.login_with_apple_cd: StringResource
  get() = CommonMainString0.login_with_apple_cd

private fun init_login_with_apple_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_with_apple_cd", "login_with_apple_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7666, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10378, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6910,
    51),
    )
)

internal val Res.string.login_with_google_cd: StringResource
  get() = CommonMainString0.login_with_google_cd

private fun init_login_with_google_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_with_google_cd", "login_with_google_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7718, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10442, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6962,
    52),
    )
)

internal val Res.string.logout: StringResource
  get() = CommonMainString0.logout

private fun init_logout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout", "logout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7801, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10537, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7037,
    22),
    )
)

internal val Res.string.mark_as_non_settled: StringResource
  get() = CommonMainString0.mark_as_non_settled

private fun init_mark_as_non_settled(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mark_as_non_settled", "mark_as_non_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7828, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10568, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7060,
    55),
    )
)

internal val Res.string.mark_as_settled: StringResource
  get() = CommonMainString0.mark_as_settled

private fun init_mark_as_settled(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:mark_as_settled", "mark_as_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7896, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10660, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7116,
    43),
    )
)

internal val Res.string.month_plus: StringResource
  get() = CommonMainString0.month_plus

private fun init_month_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:month_plus", "month_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7952, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10740, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7160,
    34),
    )
)

internal val Res.string.new: StringResource
  get() = CommonMainString0.new

private fun init_new(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new", "new",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8061, 15),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10889, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7261,
    15),
    )
)

internal val Res.string.new_expense: StringResource
  get() = CommonMainString0.new_expense

private fun init_new_expense(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_expense", "new_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7991, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10787, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7195,
    35),
    )
)

internal val Res.string.new_group: StringResource
  get() = CommonMainString0.new_group

private fun init_new_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_group", "new_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8027, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10839, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7231,
    29),
    )
)

internal val Res.string.no_contact_found: StringResource
  get() = CommonMainString0.no_contact_found

private fun init_no_contact_found(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:no_contact_found", "no_contact_found",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8077, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10917, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7277,
    64),
    )
)

internal val Res.string.no_image_group_cd: StringResource
  get() = CommonMainString0.no_image_group_cd

private fun init_no_image_group_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:no_image_group_cd", "no_image_group_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8146, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11006, 97),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7342,
    57),
    )
)

internal val Res.string.no_image_user: StringResource
  get() = CommonMainString0.no_image_user

private fun init_no_image_user(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:no_image_user", "no_image_user",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8208, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11104, 109),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7400,
    53),
    )
)

internal val Res.string.non_distr_cd: StringResource
  get() = CommonMainString0.non_distr_cd

private fun init_non_distr_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:non_distr_cd", "non_distr_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8270, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11214, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7454,
    52),
    )
)

internal val Res.string.not_allowed_to_update_expense: StringResource
  get() = CommonMainString0.not_allowed_to_update_expense

private fun init_not_allowed_to_update_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:not_allowed_to_update_expense", "not_allowed_to_update_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8323, 97),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11295, 129),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7507,
    93),
    )
)

internal val Res.string.not_found: StringResource
  get() = CommonMainString0.not_found

private fun init_not_found(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:not_found", "not_found",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8421, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11425, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7601,
    41),
    )
)

internal val Res.string.not_participating: StringResource
  get() = CommonMainString0.not_participating

private fun init_not_participating(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:not_participating", "not_participating",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8475, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11479, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7643,
    57),
    )
)

internal val Res.string.not_settled: StringResource
  get() = CommonMainString0.not_settled

private fun init_not_settled(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:not_settled", "not_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8529, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11545, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7701,
    35),
    )
)

internal val Res.string.not_split: StringResource
  get() = CommonMainString0.not_split

private fun init_not_split(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:not_split", "not_split",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8622, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11658, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7782,
    29),
    )
)

internal val Res.string.not_split_amount: StringResource
  get() = CommonMainString0.not_split_amount

private fun init_not_split_amount(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:not_split_amount", "not_split_amount",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8569, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11593, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7737,
    44),
    )
)

internal val Res.string.offer_badge: StringResource
  get() = CommonMainString0.offer_badge

private fun init_offer_badge(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:offer_badge", "offer_badge",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8660, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11712, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7812,
    35),
    )
)

internal val Res.string.offline_balances_shown: StringResource
  get() = CommonMainString0.offline_balances_shown

private fun init_offline_balances_shown(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:offline_balances_shown", "offline_balances_shown",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8700, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11780, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7848,
    66),
    )
)

internal val Res.string.okey: StringResource
  get() = CommonMainString0.okey

private fun init_okey(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:okey", "okey",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8775, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11883, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7915,
    16),
    )
)

internal val Res.string.only_this_transaction: StringResource
  get() = CommonMainString0.only_this_transaction

private fun init_only_this_transaction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:only_this_transaction", "only_this_transaction",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8792, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11904, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7932,
    57),
    )
)

internal val Res.string.only_you_can_update_expense: StringResource
  get() = CommonMainString0.only_you_can_update_expense

private fun init_only_you_can_update_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:only_you_can_update_expense", "only_you_can_update_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8850, 95),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11990, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7990,
    91),
    )
)

internal val Res.string.or: StringResource
  get() = CommonMainString0.or

private fun init_or(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:or", "or",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8946, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12090, 18),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8082,
    14),
    )
)

internal val Res.string.other: StringResource
  get() = CommonMainString0.other

private fun init_other(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:other", "other",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8965, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12109, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8097,
    21),
    )
)

internal val Res.string.paid_by: StringResource
  get() = CommonMainString0.paid_by

private fun init_paid_by(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paid_by", "paid_by",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9117, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12301, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8229,
    27),
    )
)

internal val Res.string.paid_by_me: StringResource
  get() = CommonMainString0.paid_by_me

private fun init_paid_by_me(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paid_by_me", "paid_by_me",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8987, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12139, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8119,
    34),
    )
)

internal val Res.string.paid_by_person: StringResource
  get() = CommonMainString0.paid_by_person

private fun init_paid_by_person(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paid_by_person", "paid_by_person",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9026, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12194, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8154,
    38),
    )
)

internal val Res.string.paid_by_you: StringResource
  get() = CommonMainString0.paid_by_you

private fun init_paid_by_you(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paid_by_you", "paid_by_you",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9073, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12245, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8193,
    35),
    )
)

internal val Res.string.per_month: StringResource
  get() = CommonMainString0.per_month

private fun init_per_month(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:per_month", "per_month",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9149, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12341, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8257,
    33),
    )
)

internal val Res.string.personal_expense: StringResource
  get() = CommonMainString0.personal_expense

private fun init_personal_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_expense", "personal_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9183, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12379, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8291,
    48),
    )
)

internal val Res.string.plus_active: StringResource
  get() = CommonMainString0.plus_active

private fun init_plus_active(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plus_active", "plus_active",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9236, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12436, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8340,
    47),
    )
)

internal val Res.string.plus_badge: StringResource
  get() = CommonMainString0.plus_badge

private fun init_plus_badge(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plus_badge", "plus_badge",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9348, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12584, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8448,
    34),
    )
)

internal val Res.string.plus_badge_benefits: StringResource
  get() = CommonMainString0.plus_badge_benefits

private fun init_plus_badge_benefits(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_badge_benefits", "plus_badge_benefits",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9280, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12492, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8388,
    59),
    )
)

internal val Res.string.plus_feature_cats_cta: StringResource
  get() = CommonMainString0.plus_feature_cats_cta

private fun init_plus_feature_cats_cta(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_cats_cta", "plus_feature_cats_cta",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9387, 77),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12627, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8483,
    61),
    )
)

internal val Res.string.plus_feature_cats_descr_full: StringResource
  get() = CommonMainString0.plus_feature_cats_descr_full

private fun init_plus_feature_cats_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_cats_descr_full", "plus_feature_cats_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9465, 172),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12729, 260),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8545,
    256),
    )
)

internal val Res.string.plus_feature_cats_descr_short: StringResource
  get() = CommonMainString0.plus_feature_cats_descr_short

private fun init_plus_feature_cats_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_cats_descr_short", "plus_feature_cats_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9638, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12990, 121),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8802,
    81),
    )
)

internal val Res.string.plus_feature_cats_title: StringResource
  get() = CommonMainString0.plus_feature_cats_title

private fun init_plus_feature_cats_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_cats_title", "plus_feature_cats_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9724, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13112, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8884,
    67),
    )
)

internal val Res.string.plus_feature_currencies_descr_full: StringResource
  get() = CommonMainString0.plus_feature_currencies_descr_full

private fun init_plus_feature_currencies_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_currencies_descr_full", "plus_feature_currencies_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9792, 274),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13204, 246),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8952,
    218),
    )
)

internal val Res.string.plus_feature_currencies_descr_short: StringResource
  get() = CommonMainString0.plus_feature_currencies_descr_short

private fun init_plus_feature_currencies_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_currencies_descr_short", "plus_feature_currencies_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10067, 107),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13451, 127),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9171,
    83),
    )
)

internal val Res.string.plus_feature_currencies_title: StringResource
  get() = CommonMainString0.plus_feature_currencies_title

private fun init_plus_feature_currencies_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_currencies_title", "plus_feature_currencies_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10175, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13579, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9255,
    49),
    )
)

internal val Res.string.plus_feature_images_descr_full: StringResource
  get() = CommonMainString0.plus_feature_images_descr_full

private fun init_plus_feature_images_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_images_descr_full", "plus_feature_images_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10241, 170),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13645, 282),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9305,
    154),
    )
)

internal val Res.string.plus_feature_images_descr_short: StringResource
  get() = CommonMainString0.plus_feature_images_descr_short

private fun init_plus_feature_images_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_images_descr_short", "plus_feature_images_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10412, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13928, 119),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9460,
    71),
    )
)

internal val Res.string.plus_feature_images_title: StringResource
  get() = CommonMainString0.plus_feature_images_title

private fun init_plus_feature_images_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_images_title", "plus_feature_images_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10492, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14048, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9532,
    49),
    )
)

internal val Res.string.plus_feature_more_descr: StringResource
  get() = CommonMainString0.plus_feature_more_descr

private fun init_plus_feature_more_descr(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_more_descr", "plus_feature_more_descr",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10546, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14126, 119),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9582,
    59),
    )
)

internal val Res.string.plus_feature_more_title: StringResource
  get() = CommonMainString0.plus_feature_more_title

private fun init_plus_feature_more_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_more_title", "plus_feature_more_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10630, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14246, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9642,
    47),
    )
)

internal val Res.string.plus_feature_noads_descr_full: StringResource
  get() = CommonMainString0.plus_feature_noads_descr_full

private fun init_plus_feature_noads_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_noads_descr_full", "plus_feature_noads_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10678, 277),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14322, 253),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9690,
    221),
    )
)

internal val Res.string.plus_feature_noads_descr_short: StringResource
  get() = CommonMainString0.plus_feature_noads_descr_short

private fun init_plus_feature_noads_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_noads_descr_short", "plus_feature_noads_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10956, 78),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14576, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9912,
    74),
    )
)

internal val Res.string.plus_feature_noads_title: StringResource
  get() = CommonMainString0.plus_feature_noads_title

private fun init_plus_feature_noads_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_noads_title", "plus_feature_noads_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11035, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14687, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9987,
    40),
    )
)

internal val Res.string.plus_feature_protect_descr_full: StringResource
  get() = CommonMainString0.plus_feature_protect_descr_full

private fun init_plus_feature_protect_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_protect_descr_full", "plus_feature_protect_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11088, 235),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14748, 279),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10028,
    195),
    )
)

internal val Res.string.plus_feature_protect_descr_short: StringResource
  get() = CommonMainString0.plus_feature_protect_descr_short

private fun init_plus_feature_protect_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_protect_descr_short", "plus_feature_protect_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11324, 96),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15028, 104),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10224,
    72),
    )
)

internal val Res.string.plus_feature_protect_title: StringResource
  get() = CommonMainString0.plus_feature_protect_title

private fun init_plus_feature_protect_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_protect_title", "plus_feature_protect_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11421, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15133, 98),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10297,
    58),
    )
)

internal val Res.string.plus_feature_quick_add_descr_full: StringResource
  get() = CommonMainString0.plus_feature_quick_add_descr_full

private fun init_plus_feature_quick_add_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_quick_add_descr_full", "plus_feature_quick_add_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11484, 225),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15232, 301),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10356,
    193),
    )
)

internal val Res.string.plus_feature_quick_add_descr_short: StringResource
  get() = CommonMainString0.plus_feature_quick_add_descr_short

private fun init_plus_feature_quick_add_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_quick_add_descr_short", "plus_feature_quick_add_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11710, 106),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15534, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10550,
    74),
    )
)

internal val Res.string.plus_feature_quick_add_title: StringResource
  get() = CommonMainString0.plus_feature_quick_add_title

private fun init_plus_feature_quick_add_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_quick_add_title", "plus_feature_quick_add_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11817, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15645, 96),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10625,
    64),
    )
)

internal val Res.string.plus_feature_single_settle_descr_full: StringResource
  get() = CommonMainString0.plus_feature_single_settle_descr_full

private fun init_plus_feature_single_settle_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_single_settle_descr_full", "plus_feature_single_settle_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11886, 193),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15742, 317),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10690,
    189),
    )
)

internal val Res.string.plus_feature_single_settle_descr_short: StringResource
  get() = CommonMainString0.plus_feature_single_settle_descr_short

private fun init_plus_feature_single_settle_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_single_settle_descr_short", "plus_feature_single_settle_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12080, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16060, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10880,
    90),
    )
)

internal val Res.string.plus_feature_single_settle_title: StringResource
  get() = CommonMainString0.plus_feature_single_settle_title

private fun init_plus_feature_single_settle_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_single_settle_title", "plus_feature_single_settle_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12175, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16171, 108),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10971,
    68),
    )
)

internal val Res.string.plus_feature_suggest_descr_full: StringResource
  get() = CommonMainString0.plus_feature_suggest_descr_full

private fun init_plus_feature_suggest_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_suggest_descr_full", "plus_feature_suggest_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12240, 303),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16280, 315),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11040,
    255),
    )
)

internal val Res.string.plus_feature_suggest_descr_short: StringResource
  get() = CommonMainString0.plus_feature_suggest_descr_short

private fun init_plus_feature_suggest_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_suggest_descr_short", "plus_feature_suggest_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12544, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16596, 148),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11296,
    92),
    )
)

internal val Res.string.plus_feature_suggest_title: StringResource
  get() = CommonMainString0.plus_feature_suggest_title

private fun init_plus_feature_suggest_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_suggest_title", "plus_feature_suggest_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12653, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16745, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11389,
    74),
    )
)

internal val Res.string.plus_subs_title: StringResource
  get() = CommonMainString0.plus_subs_title

private fun init_plus_subs_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plus_subs_title", "plus_subs_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12736, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16856, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11464,
    43),
    )
)

internal val Res.string.privacy_policy: StringResource
  get() = CommonMainString0.privacy_policy

private fun init_privacy_policy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:privacy_policy", "privacy_policy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12780, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16920, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11508,
    42),
    )
)

internal val Res.string.problems_encountered: StringResource
  get() = CommonMainString0.problems_encountered

private fun init_problems_encountered(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:problems_encountered", "problems_encountered",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12831, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17015, 160),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11551,
    92),
    )
)

internal val Res.string.profile: StringResource
  get() = CommonMainString0.profile

private fun init_profile(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:profile", "profile",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13023, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17283, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11715,
    27),
    )
)

internal val Res.string.profile_under_construction: StringResource
  get() = CommonMainString0.profile_under_construction

private fun init_profile_under_construction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_under_construction", "profile_under_construction",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12940, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17176, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11644,
    70),
    )
)

internal val Res.string.protect_expense: StringResource
  get() = CommonMainString0.protect_expense

private fun init_protect_expense(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:protect_expense", "protect_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13139, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17435, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11815,
    43),
    )
)

internal val Res.string.protect_expense_editing: StringResource
  get() = CommonMainString0.protect_expense_editing

private fun init_protect_expense_editing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:protect_expense_editing", "protect_expense_editing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13047, 91),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17319, 115),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11743,
    71),
    )
)

internal val Res.string.protected_expense: StringResource
  get() = CommonMainString0.protected_expense

private fun init_protected_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:protected_expense", "protected_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13187, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17499, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11859,
    49),
    )
)

internal val Res.string.protected_feature: StringResource
  get() = CommonMainString0.protected_feature

private fun init_protected_feature(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:protected_feature", "protected_feature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13241, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17569, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11909,
    49),
    )
)

internal val Res.string.quick_add: StringResource
  get() = CommonMainString0.quick_add

private fun init_quick_add(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:quick_add", "quick_add",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13295, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17643, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11959,
    45),
    )
)

internal val Res.string.recalculate: StringResource
  get() = CommonMainString0.recalculate

private fun init_recalculate(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:recalculate", "recalculate",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13353, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17709, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12005,
    35),
    )
)

internal val Res.string.recalculating_backend: StringResource
  get() = CommonMainString0.recalculating_backend

private fun init_recalculating_backend(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:recalculating_backend", "recalculating_backend",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13393, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17761, 89),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12041,
    61),
    )
)

internal val Res.string.retry: StringResource
  get() = CommonMainString0.retry

private fun init_retry(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:retry", "retry",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13455, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17851, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12103,
    21),
    )
)

internal val Res.string.save: StringResource
  get() = CommonMainString0.save

private fun init_save(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:save", "save",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13493, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17889, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12125,
    20),
    )
)

internal val Res.string.search_contact: StringResource
  get() = CommonMainString0.search_contact

private fun init_search_contact(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search_contact", "search_contact",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13518, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17926, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12146,
    46),
    )
)

internal val Res.string.search_currency: StringResource
  get() = CommonMainString0.search_currency

private fun init_search_currency(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search_currency", "search_currency",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13593, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18013, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12193,
    51),
    )
)

internal val Res.string.select_currency: StringResource
  get() = CommonMainString0.select_currency

private fun init_select_currency(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_currency", "select_currency",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13637, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18069, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12245,
    43),
    )
)

internal val Res.string.select_payer_cd: StringResource
  get() = CommonMainString0.select_payer_cd

private fun init_select_payer_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_payer_cd", "select_payer_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13689, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18133, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12289,
    55),
    )
)

internal val Res.string.settings: StringResource
  get() = CommonMainString0.settings

private fun init_settings(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings", "settings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13749, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18205, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12345,
    28),
    )
)

internal val Res.string.settle_all_balances: StringResource
  get() = CommonMainString0.settle_all_balances

private fun init_settle_all_balances(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settle_all_balances", "settle_all_balances",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13786, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18246, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12374,
    55),
    )
)

internal val Res.string.settle_balances: StringResource
  get() = CommonMainString0.settle_balances

private fun init_settle_balances(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settle_balances", "settle_balances",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13846, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18346, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12430,
    35),
    )
)

internal val Res.string.settled: StringResource
  get() = CommonMainString0.settled

private fun init_settled(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settled", "settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14003, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18539, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12567,
    27),
    )
)

internal val Res.string.settled_all_expenses: StringResource
  get() = CommonMainString0.settled_all_expenses

private fun init_settled_all_expenses(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settled_all_expenses", "settled_all_expenses",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13882, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18402, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12466,
    56),
    )
)

internal val Res.string.settled_message: StringResource
  get() = CommonMainString0.settled_message

private fun init_settled_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settled_message", "settled_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13947, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18475, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12523,
    43),
    )
)

internal val Res.string.share_group: StringResource
  get() = CommonMainString0.share_group

private fun init_share_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:share_group", "share_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14031, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18579, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12595,
    43),
    )
)

internal val Res.string.share_link_copied: StringResource
  get() = CommonMainString0.share_link_copied

private fun init_share_link_copied(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:share_link_copied", "share_link_copied",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14079, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18659, 97),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12639,
    53),
    )
)

internal val Res.string.share_subject: StringResource
  get() = CommonMainString0.share_subject

private fun init_share_subject(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:share_subject", "share_subject",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14137, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18757, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12693,
    37),
    )
)

internal val Res.string.shares: StringResource
  get() = CommonMainString0.shares

private fun init_shares(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:shares", "shares",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14179, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18807, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12731,
    22),
    )
)

internal val Res.string.should_pay_to: StringResource
  get() = CommonMainString0.should_pay_to

private fun init_should_pay_to(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:should_pay_to", "should_pay_to",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14206, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18838, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12754,
    41),
    )
)

internal val Res.string.start_type_creat_contact: StringResource
  get() = CommonMainString0.start_type_creat_contact

private fun init_start_type_creat_contact(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:start_type_creat_contact", "start_type_creat_contact",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14248, 120),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18900, 144),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12796,
    88),
    )
)

internal val Res.string.submit_feedback_cd: StringResource
  get() = CommonMainString0.submit_feedback_cd

private fun init_submit_feedback_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:submit_feedback_cd", "submit_feedback_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14369, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19045, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12885,
    46),
    )
)

internal val Res.string.subscribe_for: StringResource
  get() = CommonMainString0.subscribe_for

private fun init_subscribe_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:subscribe_for", "subscribe_for",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14416, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19112, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12932,
    45),
    )
)

internal val Res.string.subscribe_plus: StringResource
  get() = CommonMainString0.subscribe_plus

private fun init_subscribe_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:subscribe_plus", "subscribe_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14466, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19178, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12978,
    46),
    )
)

internal val Res.string.subscribe_via_mobile_app: StringResource
  get() = CommonMainString0.subscribe_via_mobile_app

private fun init_subscribe_via_mobile_app(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:subscribe_via_mobile_app", "subscribe_via_mobile_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14509, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19245, 156),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13025,
    92),
    )
)

internal val Res.string.suggest_split: StringResource
  get() = CommonMainString0.suggest_split

private fun init_suggest_split(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:suggest_split", "suggest_split",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14618, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19402, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13118,
    41),
    )
)

internal val Res.string.sync_in_progress: StringResource
  get() = CommonMainString0.sync_in_progress

private fun init_sync_in_progress(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sync_in_progress", "sync_in_progress",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14668, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19480, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13160,
    48),
    )
)

internal val Res.string.system_theme: StringResource
  get() = CommonMainString0.system_theme

private fun init_system_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:system_theme", "system_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14725, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19569, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13209,
    28),
    )
)

internal val Res.string.terms_conditions: StringResource
  get() = CommonMainString0.terms_conditions

private fun init_terms_conditions(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:terms_conditions", "terms_conditions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14754, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19614, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13238,
    48),
    )
)

internal val Res.string.text_its_me: StringResource
  get() = CommonMainString0.text_its_me

private fun init_text_its_me(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:text_its_me", "text_its_me",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14815, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19695, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13287,
    31),
    )
)

internal val Res.string.text_loading: StringResource
  get() = CommonMainString0.text_loading

private fun init_text_loading(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:text_loading", "text_loading",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14851, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19727, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13319,
    36),
    )
)

internal val Res.string.title_leave_group: StringResource
  get() = CommonMainString0.title_leave_group

private fun init_title_leave_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_leave_group", "title_leave_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14884, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19776, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13356,
    41),
    )
)

internal val Res.string.title_should_be_filled: StringResource
  get() = CommonMainString0.title_should_be_filled

private fun init_title_should_be_filled(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_should_be_filled", "title_should_be_filled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14930, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19834, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13398,
    62),
    )
)

internal val Res.string.transactions: StringResource
  get() = CommonMainString0.transactions

private fun init_transactions(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:transactions", "transactions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15005, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19941, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13461,
    36),
    )
)

internal val Res.string.try_days: StringResource
  get() = CommonMainString0.try_days

private fun init_try_days(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_days", "try_days",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15046, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19990, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13498,
    40),
    )
)

internal val Res.string.try_month_plus: StringResource
  get() = CommonMainString0.try_month_plus

private fun init_try_month_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_month_plus", "try_month_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15099, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20079, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13539,
    50),
    )
)

internal val Res.string.try_plus: StringResource
  get() = CommonMainString0.try_plus

private fun init_try_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_plus", "try_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15154, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20146, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13590,
    28),
    )
)

internal val Res.string.try_week_plus: StringResource
  get() = CommonMainString0.try_week_plus

private fun init_try_week_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_week_plus", "try_week_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15199, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20199, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13619,
    49),
    )
)

internal val Res.string.try_year_plus: StringResource
  get() = CommonMainString0.try_year_plus

private fun init_try_year_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_year_plus", "try_year_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15257, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20269, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13669,
    49),
    )
)

internal val Res.string.tutorial_info: StringResource
  get() = CommonMainString0.tutorial_info

private fun init_tutorial_info(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tutorial_info", "tutorial_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15311, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20335, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13719,
    49),
    )
)

internal val Res.string.tutorial_step_add_expense_description: StringResource
  get() = CommonMainString0.tutorial_step_add_expense_description

private fun init_tutorial_step_add_expense_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_add_expense_description", "tutorial_step_add_expense_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15361, 165),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20421, 221),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13769,
    129),
    )
)

internal val Res.string.tutorial_step_add_expense_title: StringResource
  get() = CommonMainString0.tutorial_step_add_expense_title

private fun init_tutorial_step_add_expense_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_add_expense_title", "tutorial_step_add_expense_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15527, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20643, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13899,
    67),
    )
)

internal val Res.string.tutorial_step_add_participant_description: StringResource
  get() = CommonMainString0.tutorial_step_add_participant_description

private fun init_tutorial_step_add_participant_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_add_participant_description", "tutorial_step_add_participant_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15607, 233),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20743, 277),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13967,
    197),
    )
)

internal val Res.string.tutorial_step_add_participant_title: StringResource
  get() = CommonMainString0.tutorial_step_add_participant_title

private fun init_tutorial_step_add_participant_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_add_participant_title", "tutorial_step_add_participant_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15841, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21021, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14165,
    63),
    )
)

internal val Res.string.tutorial_step_balances_tab_description: StringResource
  get() = CommonMainString0.tutorial_step_balances_tab_description

private fun init_tutorial_step_balances_tab_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_balances_tab_description", "tutorial_step_balances_tab_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15917, 150),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21113, 182),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14229,
    110),
    )
)

internal val Res.string.tutorial_step_balances_tab_title: StringResource
  get() = CommonMainString0.tutorial_step_balances_tab_title

private fun init_tutorial_step_balances_tab_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_balances_tab_title", "tutorial_step_balances_tab_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16068, 76),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21296, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14340,
    56),
    )
)

internal val Res.string.tutorial_step_check_balances_description: StringResource
  get() = CommonMainString0.tutorial_step_check_balances_description

private fun init_tutorial_step_check_balances_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_check_balances_description", "tutorial_step_check_balances_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16145, 232),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21377, 344),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14397,
    188),
    )
)

internal val Res.string.tutorial_step_check_balances_title: StringResource
  get() = CommonMainString0.tutorial_step_check_balances_title

private fun init_tutorial_step_check_balances_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_check_balances_title", "tutorial_step_check_balances_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16378, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21722, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14586,
    62),
    )
)

internal val Res.string.tutorial_step_choose_payer: StringResource
  get() = CommonMainString0.tutorial_step_choose_payer

private fun init_tutorial_step_choose_payer(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_choose_payer", "tutorial_step_choose_payer",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16612, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22072, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14800,
    58),
    )
)

internal val Res.string.tutorial_step_choose_payer_description: StringResource
  get() = CommonMainString0.tutorial_step_choose_payer_description

private fun init_tutorial_step_choose_payer_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_choose_payer_description", "tutorial_step_choose_payer_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16441, 170),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21809, 262),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14649,
    150),
    )
)

internal val Res.string.tutorial_step_confirm_selection_description: StringResource
  get() = CommonMainString0.tutorial_step_confirm_selection_description

private fun init_tutorial_step_confirm_selection_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_confirm_selection_description",
    "tutorial_step_confirm_selection_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16679, 167),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22159, 279),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14859,
    159),
    )
)

internal val Res.string.tutorial_step_confirm_selection_title: StringResource
  get() = CommonMainString0.tutorial_step_confirm_selection_title

private fun init_tutorial_step_confirm_selection_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_confirm_selection_title", "tutorial_step_confirm_selection_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16847, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22439, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15019,
    77),
    )
)

internal val Res.string.tutorial_step_create_group_description: StringResource
  get() = CommonMainString0.tutorial_step_create_group_description

private fun init_tutorial_step_create_group_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_create_group_description", "tutorial_step_create_group_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16933, 186),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22541, 254),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15097,
    154),
    )
)

internal val Res.string.tutorial_step_create_group_title: StringResource
  get() = CommonMainString0.tutorial_step_create_group_title

private fun init_tutorial_step_create_group_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_create_group_title", "tutorial_step_create_group_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17120, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22796, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15252,
    64),
    )
)

internal val Res.string.tutorial_step_date_currency_description: StringResource
  get() = CommonMainString0.tutorial_step_date_currency_description

private fun init_tutorial_step_date_currency_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_date_currency_description", "tutorial_step_date_currency_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17189, 255),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22889, 335),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15317,
    223),
    )
)

internal val Res.string.tutorial_step_date_currency_setup: StringResource
  get() = CommonMainString0.tutorial_step_date_currency_setup

private fun init_tutorial_step_date_currency_setup(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_date_currency_setup", "tutorial_step_date_currency_setup",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17445, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23225, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15541,
    73),
    )
)

internal val Res.string.tutorial_step_expense_title: StringResource
  get() = CommonMainString0.tutorial_step_expense_title

private fun init_tutorial_step_expense_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_expense_title", "tutorial_step_expense_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17691, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23531, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15759,
    63),
    )
)

internal val Res.string.tutorial_step_expense_title_description: StringResource
  get() = CommonMainString0.tutorial_step_expense_title_description

private fun init_tutorial_step_expense_title_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_expense_title_description", "tutorial_step_expense_title_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17531, 159),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23327, 203),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15615,
    143),
    )
)

internal val Res.string.tutorial_step_input_name_description: StringResource
  get() = CommonMainString0.tutorial_step_input_name_description

private fun init_tutorial_step_input_name_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_input_name_description", "tutorial_step_input_name_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17771, 184),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23623, 240),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15823,
    152),
    )
)

internal val Res.string.tutorial_step_input_name_title: StringResource
  get() = CommonMainString0.tutorial_step_input_name_title

private fun init_tutorial_step_input_name_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_input_name_title", "tutorial_step_input_name_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17956, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23864, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15976,
    66),
    )
)

internal val Res.string.tutorial_step_invite_friends_description: StringResource
  get() = CommonMainString0.tutorial_step_invite_friends_description

private fun init_tutorial_step_invite_friends_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_invite_friends_description", "tutorial_step_invite_friends_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18047, 208),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23971, 292),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16043,
    164),
    )
)

internal val Res.string.tutorial_step_invite_friends_title: StringResource
  get() = CommonMainString0.tutorial_step_invite_friends_title

private fun init_tutorial_step_invite_friends_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_invite_friends_title", "tutorial_step_invite_friends_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18256, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24264, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16208,
    78),
    )
)

internal val Res.string.tutorial_step_put_amount: StringResource
  get() = CommonMainString0.tutorial_step_put_amount

private fun init_tutorial_step_put_amount(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_put_amount", "tutorial_step_put_amount",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18496, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24532, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16404,
    52),
    )
)

internal val Res.string.tutorial_step_put_amount_description: StringResource
  get() = CommonMainString0.tutorial_step_put_amount_description

private fun init_tutorial_step_put_amount_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_put_amount_description", "tutorial_step_put_amount_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18347, 148),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24375, 156),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16287,
    116),
    )
)

internal val Res.string.tutorial_step_save_expense: StringResource
  get() = CommonMainString0.tutorial_step_save_expense

private fun init_tutorial_step_save_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_save_expense", "tutorial_step_save_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18764, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24936, 74),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16624,
    58),
    )
)

internal val Res.string.tutorial_step_save_expense_description: StringResource
  get() = CommonMainString0.tutorial_step_save_expense_description

private fun init_tutorial_step_save_expense_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_save_expense_description", "tutorial_step_save_expense_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18561, 202),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24601, 334),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16457,
    166),
    )
)

internal val Res.string.tutorial_step_select_user_description: StringResource
  get() = CommonMainString0.tutorial_step_select_user_description

private fun init_tutorial_step_select_user_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_select_user_description", "tutorial_step_select_user_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18835, 233),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25011, 353),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16683,
    185),
    )
)

internal val Res.string.tutorial_step_select_user_title: StringResource
  get() = CommonMainString0.tutorial_step_select_user_title

private fun init_tutorial_step_select_user_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_select_user_title", "tutorial_step_select_user_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19069, 99),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25365, 123),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16869,
    71),
    )
)

internal val Res.string.tutorial_step_settle_up_description: StringResource
  get() = CommonMainString0.tutorial_step_settle_up_description

private fun init_tutorial_step_settle_up_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_settle_up_description", "tutorial_step_settle_up_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19169, 123),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25489, 175),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16941,
    111),
    )
)

internal val Res.string.tutorial_step_settle_up_title: StringResource
  get() = CommonMainString0.tutorial_step_settle_up_title

private fun init_tutorial_step_settle_up_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_settle_up_title", "tutorial_step_settle_up_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19293, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25665, 121),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17053,
    73),
    )
)

internal val Res.string.undistributed: StringResource
  get() = CommonMainString0.undistributed

private fun init_undistributed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:undistributed", "undistributed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19379, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25787, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17127,
    41),
    )
)

internal val Res.string.unlock_all_features: StringResource
  get() = CommonMainString0.unlock_all_features

private fun init_unlock_all_features(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unlock_all_features", "unlock_all_features",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19421, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25849, 95),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17169,
    55),
    )
)

internal val Res.string.user_already_in_group: StringResource
  get() = CommonMainString0.user_already_in_group

private fun init_user_already_in_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:user_already_in_group", "user_already_in_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19489, 101),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25945, 137),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17225,
    81),
    )
)

internal val Res.string.users_count: StringResource
  get() = CommonMainString0.users_count

private fun init_users_count(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:users_count", "users_count",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19591, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26083, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17307,
    35),
    )
)

internal val Res.string.valid_subs: StringResource
  get() = CommonMainString0.valid_subs

private fun init_valid_subs(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:valid_subs", "valid_subs",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19758, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26314, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17458,
    42),
    )
)

internal val Res.string.valid_subs_days_more: StringResource
  get() = CommonMainString0.valid_subs_days_more

private fun init_valid_subs_days_more(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:valid_subs_days_more", "valid_subs_days_more",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19631, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26143, 84),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17343,
    56),
    )
)

internal val Res.string.valid_subs_hours_more: StringResource
  get() = CommonMainString0.valid_subs_hours_more

private fun init_valid_subs_hours_more(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:valid_subs_hours_more", "valid_subs_hours_more",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19692, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26228, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17400,
    57),
    )
)

internal val Res.string.we_split_in: StringResource
  get() = CommonMainString0.we_split_in

private fun init_we_split_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:we_split_in", "we_split_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19805, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26385, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17501,
    35),
    )
)

internal val Res.string.web_billing_in_progress: StringResource
  get() = CommonMainString0.web_billing_in_progress

private fun init_web_billing_in_progress(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:web_billing_in_progress", "web_billing_in_progress",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19845, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26437, 115),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17537,
    75),
    )
)

internal val Res.string.week_plus: StringResource
  get() = CommonMainString0.week_plus

private fun init_week_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:week_plus", "week_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19921, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26553, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17613,
    29),
    )
)

internal val Res.string.wesplit_logo: StringResource
  get() = CommonMainString0.wesplit_logo

private fun init_wesplit_logo(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:wesplit_logo", "wesplit_logo",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19967, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26603, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17643,
    36),
    )
)

internal val Res.string.what_you_get_with: StringResource
  get() = CommonMainString0.what_you_get_with

private fun init_what_you_get_with(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:what_you_get_with", "what_you_get_with",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20004, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26656, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17680,
    49),
    )
)

internal val Res.string.with_me: StringResource
  get() = CommonMainString0.with_me

private fun init_with_me(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:with_me", "with_me",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20046, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26726, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17730,
    27),
    )
)

internal val Res.string.year_plus: StringResource
  get() = CommonMainString0.year_plus

private fun init_year_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:year_plus", "year_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20074, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26762, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17758,
    29),
    )
)

internal val Res.string.you: StringResource
  get() = CommonMainString0.you

private fun init_you(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:you", "you",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20299, 15),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27003, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17943,
    15),
    )
)

internal val Res.string.you_borrowed: StringResource
  get() = CommonMainString0.you_borrowed

private fun init_you_borrowed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:you_borrowed", "you_borrowed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20116, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26808, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17788,
    44),
    )
)

internal val Res.string.you_have_plus: StringResource
  get() = CommonMainString0.you_have_plus

private fun init_you_have_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:you_have_plus", "you_have_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20173, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26861, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17833,
    41),
    )
)

internal val Res.string.you_lent: StringResource
  get() = CommonMainString0.you_lent

private fun init_you_lent(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:you_lent", "you_lent",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20215, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26915, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17875,
    36),
    )
)

internal val Res.string.your_share: StringResource
  get() = CommonMainString0.your_share

private fun init_your_share(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:your_share", "your_share",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20268, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26968, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17912,
    30),
    )
)
