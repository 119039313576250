@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package split.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val btn_apple_black: DrawableResource by 
      lazy { init_btn_apple_black() }

  public val btn_apple_white: DrawableResource by 
      lazy { init_btn_apple_white() }

  public val btn_google_black: DrawableResource by 
      lazy { init_btn_google_black() }

  public val btn_google_neutral: DrawableResource by 
      lazy { init_btn_google_neutral() }

  public val btn_google_white: DrawableResource by 
      lazy { init_btn_google_white() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val ic_android: DrawableResource by 
      lazy { init_ic_android() }

  public val ic_badge_white: DrawableResource by 
      lazy { init_ic_badge_white() }

  public val ic_dark: DrawableResource by 
      lazy { init_ic_dark() }

  public val ic_down: DrawableResource by 
      lazy { init_ic_down() }

  public val ic_feedback: DrawableResource by 
      lazy { init_ic_feedback() }

  public val ic_flag: DrawableResource by 
      lazy { init_ic_flag() }

  public val ic_group: DrawableResource by 
      lazy { init_ic_group() }

  public val ic_light: DrawableResource by 
      lazy { init_ic_light() }

  public val ic_mobile_app: DrawableResource by 
      lazy { init_ic_mobile_app() }

  public val ic_plus: DrawableResource by 
      lazy { init_ic_plus() }

  public val ic_plus_round: DrawableResource by 
      lazy { init_ic_plus_round() }

  public val ic_profile: DrawableResource by 
      lazy { init_ic_profile() }

  public val ic_promo: DrawableResource by 
      lazy { init_ic_promo() }

  public val ic_split_money: DrawableResource by 
      lazy { init_ic_split_money() }

  public val ic_system: DrawableResource by 
      lazy { init_ic_system() }

  public val ic_user: DrawableResource by 
      lazy { init_ic_user() }

  public val ic_user_add: DrawableResource by 
      lazy { init_ic_user_add() }

  public val ic_web: DrawableResource by 
      lazy { init_ic_web() }

  public val img_add_data: DrawableResource by 
      lazy { init_img_add_data() }

  public val img_best_offer: DrawableResource by 
      lazy { init_img_best_offer() }

  public val img_construct: DrawableResource by 
      lazy { init_img_construct() }

  public val img_empty_group: DrawableResource by 
      lazy { init_img_empty_group() }

  public val img_feature_fx: DrawableResource by 
      lazy { init_img_feature_fx() }

  public val img_feature_images: DrawableResource by 
      lazy { init_img_feature_images() }

  public val img_feature_no_ads: DrawableResource by 
      lazy { init_img_feature_no_ads() }

  public val img_feature_protect: DrawableResource by 
      lazy { init_img_feature_protect() }

  public val img_feature_single_settle: DrawableResource by 
      lazy { init_img_feature_single_settle() }

  public val img_feature_suggest: DrawableResource by 
      lazy { init_img_feature_suggest() }

  public val img_login: DrawableResource by 
      lazy { init_img_login() }

  public val img_logo_black: DrawableResource by 
      lazy { init_img_logo_black() }

  public val img_logo_color: DrawableResource by 
      lazy { init_img_logo_color() }

  public val img_logo_color_plus: DrawableResource by 
      lazy { init_img_logo_color_plus() }

  public val img_logo_white: DrawableResource by 
      lazy { init_img_logo_white() }

  public val img_search_empty: DrawableResource by 
      lazy { init_img_search_empty() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("btn_apple_black", CommonMainDrawable0.btn_apple_black)
  map.put("btn_apple_white", CommonMainDrawable0.btn_apple_white)
  map.put("btn_google_black", CommonMainDrawable0.btn_google_black)
  map.put("btn_google_neutral", CommonMainDrawable0.btn_google_neutral)
  map.put("btn_google_white", CommonMainDrawable0.btn_google_white)
  map.put("compose_multiplatform", CommonMainDrawable0.compose_multiplatform)
  map.put("ic_android", CommonMainDrawable0.ic_android)
  map.put("ic_badge_white", CommonMainDrawable0.ic_badge_white)
  map.put("ic_dark", CommonMainDrawable0.ic_dark)
  map.put("ic_down", CommonMainDrawable0.ic_down)
  map.put("ic_feedback", CommonMainDrawable0.ic_feedback)
  map.put("ic_flag", CommonMainDrawable0.ic_flag)
  map.put("ic_group", CommonMainDrawable0.ic_group)
  map.put("ic_light", CommonMainDrawable0.ic_light)
  map.put("ic_mobile_app", CommonMainDrawable0.ic_mobile_app)
  map.put("ic_plus", CommonMainDrawable0.ic_plus)
  map.put("ic_plus_round", CommonMainDrawable0.ic_plus_round)
  map.put("ic_profile", CommonMainDrawable0.ic_profile)
  map.put("ic_promo", CommonMainDrawable0.ic_promo)
  map.put("ic_split_money", CommonMainDrawable0.ic_split_money)
  map.put("ic_system", CommonMainDrawable0.ic_system)
  map.put("ic_user", CommonMainDrawable0.ic_user)
  map.put("ic_user_add", CommonMainDrawable0.ic_user_add)
  map.put("ic_web", CommonMainDrawable0.ic_web)
  map.put("img_add_data", CommonMainDrawable0.img_add_data)
  map.put("img_best_offer", CommonMainDrawable0.img_best_offer)
  map.put("img_construct", CommonMainDrawable0.img_construct)
  map.put("img_empty_group", CommonMainDrawable0.img_empty_group)
  map.put("img_feature_fx", CommonMainDrawable0.img_feature_fx)
  map.put("img_feature_images", CommonMainDrawable0.img_feature_images)
  map.put("img_feature_no_ads", CommonMainDrawable0.img_feature_no_ads)
  map.put("img_feature_protect", CommonMainDrawable0.img_feature_protect)
  map.put("img_feature_single_settle", CommonMainDrawable0.img_feature_single_settle)
  map.put("img_feature_suggest", CommonMainDrawable0.img_feature_suggest)
  map.put("img_login", CommonMainDrawable0.img_login)
  map.put("img_logo_black", CommonMainDrawable0.img_logo_black)
  map.put("img_logo_color", CommonMainDrawable0.img_logo_color)
  map.put("img_logo_color_plus", CommonMainDrawable0.img_logo_color_plus)
  map.put("img_logo_white", CommonMainDrawable0.img_logo_white)
  map.put("img_search_empty", CommonMainDrawable0.img_search_empty)
}

internal val Res.drawable.btn_apple_black: DrawableResource
  get() = CommonMainDrawable0.btn_apple_black

private fun init_btn_apple_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_apple_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_apple_black.xml", -1, -1),
    )
)

internal val Res.drawable.btn_apple_white: DrawableResource
  get() = CommonMainDrawable0.btn_apple_white

private fun init_btn_apple_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_apple_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_apple_white.xml", -1, -1),
    )
)

internal val Res.drawable.btn_google_black: DrawableResource
  get() = CommonMainDrawable0.btn_google_black

private fun init_btn_google_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_google_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_google_black.xml", -1, -1),
    )
)

internal val Res.drawable.btn_google_neutral: DrawableResource
  get() = CommonMainDrawable0.btn_google_neutral

private fun init_btn_google_neutral(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_google_neutral",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_google_neutral.xml", -1, -1),
    )
)

internal val Res.drawable.btn_google_white: DrawableResource
  get() = CommonMainDrawable0.btn_google_white

private fun init_btn_google_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_google_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_google_white.xml", -1, -1),
    )
)

internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

internal val Res.drawable.ic_android: DrawableResource
  get() = CommonMainDrawable0.ic_android

private fun init_ic_android(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_android",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_android.xml", -1, -1),
    )
)

internal val Res.drawable.ic_badge_white: DrawableResource
  get() = CommonMainDrawable0.ic_badge_white

private fun init_ic_badge_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_badge_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_badge_white.xml", -1, -1),
    )
)

internal val Res.drawable.ic_dark: DrawableResource
  get() = CommonMainDrawable0.ic_dark

private fun init_ic_dark(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_dark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_dark.xml", -1, -1),
    )
)

internal val Res.drawable.ic_down: DrawableResource
  get() = CommonMainDrawable0.ic_down

private fun init_ic_down(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_down",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_down.xml", -1, -1),
    )
)

internal val Res.drawable.ic_feedback: DrawableResource
  get() = CommonMainDrawable0.ic_feedback

private fun init_ic_feedback(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_feedback",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_feedback.xml", -1, -1),
    )
)

internal val Res.drawable.ic_flag: DrawableResource
  get() = CommonMainDrawable0.ic_flag

private fun init_ic_flag(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_flag",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_flag.xml", -1, -1),
    )
)

internal val Res.drawable.ic_group: DrawableResource
  get() = CommonMainDrawable0.ic_group

private fun init_ic_group(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_group.xml", -1, -1),
    )
)

internal val Res.drawable.ic_light: DrawableResource
  get() = CommonMainDrawable0.ic_light

private fun init_ic_light(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_light.xml", -1, -1),
    )
)

internal val Res.drawable.ic_mobile_app: DrawableResource
  get() = CommonMainDrawable0.ic_mobile_app

private fun init_ic_mobile_app(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_mobile_app",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_mobile_app.xml", -1, -1),
    )
)

internal val Res.drawable.ic_plus: DrawableResource
  get() = CommonMainDrawable0.ic_plus

private fun init_ic_plus(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_plus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_plus.xml", -1, -1),
    )
)

internal val Res.drawable.ic_plus_round: DrawableResource
  get() = CommonMainDrawable0.ic_plus_round

private fun init_ic_plus_round(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_plus_round",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_plus_round.xml", -1, -1),
    )
)

internal val Res.drawable.ic_profile: DrawableResource
  get() = CommonMainDrawable0.ic_profile

private fun init_ic_profile(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_profile",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_profile.xml", -1, -1),
    )
)

internal val Res.drawable.ic_promo: DrawableResource
  get() = CommonMainDrawable0.ic_promo

private fun init_ic_promo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_promo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_promo.xml", -1, -1),
    )
)

internal val Res.drawable.ic_split_money: DrawableResource
  get() = CommonMainDrawable0.ic_split_money

private fun init_ic_split_money(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_split_money",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_split_money.png", -1, -1),
    )
)

internal val Res.drawable.ic_system: DrawableResource
  get() = CommonMainDrawable0.ic_system

private fun init_ic_system(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_system",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_system.xml", -1, -1),
    )
)

internal val Res.drawable.ic_user: DrawableResource
  get() = CommonMainDrawable0.ic_user

private fun init_ic_user(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_user",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_user.xml", -1, -1),
    )
)

internal val Res.drawable.ic_user_add: DrawableResource
  get() = CommonMainDrawable0.ic_user_add

private fun init_ic_user_add(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_user_add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_user_add.xml", -1, -1),
    )
)

internal val Res.drawable.ic_web: DrawableResource
  get() = CommonMainDrawable0.ic_web

private fun init_ic_web(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_web",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_web.xml", -1, -1),
    )
)

internal val Res.drawable.img_add_data: DrawableResource
  get() = CommonMainDrawable0.img_add_data

private fun init_img_add_data(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_add_data",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_add_data.xml", -1, -1),
    )
)

internal val Res.drawable.img_best_offer: DrawableResource
  get() = CommonMainDrawable0.img_best_offer

private fun init_img_best_offer(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_best_offer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_best_offer.xml", -1, -1),
    )
)

internal val Res.drawable.img_construct: DrawableResource
  get() = CommonMainDrawable0.img_construct

private fun init_img_construct(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_construct",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_construct.xml", -1, -1),
    )
)

internal val Res.drawable.img_empty_group: DrawableResource
  get() = CommonMainDrawable0.img_empty_group

private fun init_img_empty_group(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_empty_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_empty_group.xml", -1, -1),
    )
)

internal val Res.drawable.img_feature_fx: DrawableResource
  get() = CommonMainDrawable0.img_feature_fx

private fun init_img_feature_fx(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_fx",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_fx.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_images: DrawableResource
  get() = CommonMainDrawable0.img_feature_images

private fun init_img_feature_images(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_images",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_images.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_no_ads: DrawableResource
  get() = CommonMainDrawable0.img_feature_no_ads

private fun init_img_feature_no_ads(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_no_ads",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_no_ads.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_protect: DrawableResource
  get() = CommonMainDrawable0.img_feature_protect

private fun init_img_feature_protect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_protect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_protect.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_single_settle: DrawableResource
  get() = CommonMainDrawable0.img_feature_single_settle

private fun init_img_feature_single_settle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_single_settle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_single_settle.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_suggest: DrawableResource
  get() = CommonMainDrawable0.img_feature_suggest

private fun init_img_feature_suggest(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_suggest",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_suggest.png", -1, -1),
    )
)

internal val Res.drawable.img_login: DrawableResource
  get() = CommonMainDrawable0.img_login

private fun init_img_login(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_login",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_login.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_black: DrawableResource
  get() = CommonMainDrawable0.img_logo_black

private fun init_img_logo_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_black.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_color: DrawableResource
  get() = CommonMainDrawable0.img_logo_color

private fun init_img_logo_color(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_color",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_color.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_color_plus: DrawableResource
  get() = CommonMainDrawable0.img_logo_color_plus

private fun init_img_logo_color_plus(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_color_plus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_color_plus.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_white: DrawableResource
  get() = CommonMainDrawable0.img_logo_white

private fun init_img_logo_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_white.xml", -1, -1),
    )
)

internal val Res.drawable.img_search_empty: DrawableResource
  get() = CommonMainDrawable0.img_search_empty

private fun init_img_search_empty(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_search_empty",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_search_empty.xml", -1, -1),
    )
)
