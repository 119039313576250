package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val app_wesplit_domain_di_DomainModule : Module get() = module {
	single() { app.wesplit.domain.balance.BalanceFxCalculationUseCase() } 
	single() { app.wesplit.domain.balance.BalanceLocalCalculationUseCase() } 
	single() { app.wesplit.domain.settle.SettleSuggestionUseCase() } 
}
public val app.wesplit.domain.di.DomainModule.module : org.koin.core.module.Module get() = app_wesplit_domain_di_DomainModule