@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package split.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val btn_apple_black: DrawableResource by 
      lazy { init_btn_apple_black() }

  public val btn_apple_white: DrawableResource by 
      lazy { init_btn_apple_white() }

  public val btn_google_black: DrawableResource by 
      lazy { init_btn_google_black() }

  public val btn_google_neutral: DrawableResource by 
      lazy { init_btn_google_neutral() }

  public val btn_google_white: DrawableResource by 
      lazy { init_btn_google_white() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val ic_add_image: DrawableResource by 
      lazy { init_ic_add_image() }

  public val ic_add_many: DrawableResource by 
      lazy { init_ic_add_many() }

  public val ic_ai: DrawableResource by 
      lazy { init_ic_ai() }

  public val ic_android: DrawableResource by 
      lazy { init_ic_android() }

  public val ic_badge_white: DrawableResource by 
      lazy { init_ic_badge_white() }

  public val ic_cat_bank: DrawableResource by 
      lazy { init_ic_cat_bank() }

  public val ic_cat_beauty: DrawableResource by 
      lazy { init_ic_cat_beauty() }

  public val ic_cat_books: DrawableResource by 
      lazy { init_ic_cat_books() }

  public val ic_cat_car: DrawableResource by 
      lazy { init_ic_cat_car() }

  public val ic_cat_cash: DrawableResource by 
      lazy { init_ic_cat_cash() }

  public val ic_cat_cleaning: DrawableResource by 
      lazy { init_ic_cat_cleaning() }

  public val ic_cat_clothes: DrawableResource by 
      lazy { init_ic_cat_clothes() }

  public val ic_cat_coffee: DrawableResource by 
      lazy { init_ic_cat_coffee() }

  public val ic_cat_concert: DrawableResource by 
      lazy { init_ic_cat_concert() }

  public val ic_cat_crypto: DrawableResource by 
      lazy { init_ic_cat_crypto() }

  public val ic_cat_electricity: DrawableResource by 
      lazy { init_ic_cat_electricity() }

  public val ic_cat_entertainment: DrawableResource by 
      lazy { init_ic_cat_entertainment() }

  public val ic_cat_fastfood: DrawableResource by 
      lazy { init_ic_cat_fastfood() }

  public val ic_cat_fee: DrawableResource by 
      lazy { init_ic_cat_fee() }

  public val ic_cat_flight: DrawableResource by 
      lazy { init_ic_cat_flight() }

  public val ic_cat_food_drink: DrawableResource by 
      lazy { init_ic_cat_food_drink() }

  public val ic_cat_furnishing: DrawableResource by 
      lazy { init_ic_cat_furnishing() }

  public val ic_cat_garbage: DrawableResource by 
      lazy { init_ic_cat_garbage() }

  public val ic_cat_gift: DrawableResource by 
      lazy { init_ic_cat_gift() }

  public val ic_cat_groceries: DrawableResource by 
      lazy { init_ic_cat_groceries() }

  public val ic_cat_health: DrawableResource by 
      lazy { init_ic_cat_health() }

  public val ic_cat_hobby: DrawableResource by 
      lazy { init_ic_cat_hobby() }

  public val ic_cat_housing: DrawableResource by 
      lazy { init_ic_cat_housing() }

  public val ic_cat_internet: DrawableResource by 
      lazy { init_ic_cat_internet() }

  public val ic_cat_magic: DrawableResource by 
      lazy { init_ic_cat_magic() }

  public val ic_cat_money: DrawableResource by 
      lazy { init_ic_cat_money() }

  public val ic_cat_movie: DrawableResource by 
      lazy { init_ic_cat_movie() }

  public val ic_cat_none: DrawableResource by 
      lazy { init_ic_cat_none() }

  public val ic_cat_parking: DrawableResource by 
      lazy { init_ic_cat_parking() }

  public val ic_cat_property_tax: DrawableResource by 
      lazy { init_ic_cat_property_tax() }

  public val ic_cat_public_transport: DrawableResource by 
      lazy { init_ic_cat_public_transport() }

  public val ic_cat_recycle: DrawableResource by 
      lazy { init_ic_cat_recycle() }

  public val ic_cat_rent: DrawableResource by 
      lazy { init_ic_cat_rent() }

  public val ic_cat_repair: DrawableResource by 
      lazy { init_ic_cat_repair() }

  public val ic_cat_restaurant: DrawableResource by 
      lazy { init_ic_cat_restaurant() }

  public val ic_cat_security: DrawableResource by 
      lazy { init_ic_cat_security() }

  public val ic_cat_shoes: DrawableResource by 
      lazy { init_ic_cat_shoes() }

  public val ic_cat_shopping: DrawableResource by 
      lazy { init_ic_cat_shopping() }

  public val ic_cat_sport: DrawableResource by 
      lazy { init_ic_cat_sport() }

  public val ic_cat_sport_event: DrawableResource by 
      lazy { init_ic_cat_sport_event() }

  public val ic_cat_taxi: DrawableResource by 
      lazy { init_ic_cat_taxi() }

  public val ic_cat_technology: DrawableResource by 
      lazy { init_ic_cat_technology() }

  public val ic_cat_toll: DrawableResource by 
      lazy { init_ic_cat_toll() }

  public val ic_cat_transport: DrawableResource by 
      lazy { init_ic_cat_transport() }

  public val ic_cat_transport_travel: DrawableResource by 
      lazy { init_ic_cat_transport_travel() }

  public val ic_cat_utilities: DrawableResource by 
      lazy { init_ic_cat_utilities() }

  public val ic_cat_water: DrawableResource by 
      lazy { init_ic_cat_water() }

  public val ic_dark: DrawableResource by 
      lazy { init_ic_dark() }

  public val ic_date: DrawableResource by 
      lazy { init_ic_date() }

  public val ic_down: DrawableResource by 
      lazy { init_ic_down() }

  public val ic_eifell_ads: DrawableResource by 
      lazy { init_ic_eifell_ads() }

  public val ic_feedback: DrawableResource by 
      lazy { init_ic_feedback() }

  public val ic_flag: DrawableResource by 
      lazy { init_ic_flag() }

  public val ic_group: DrawableResource by 
      lazy { init_ic_group() }

  public val ic_health_beauty: DrawableResource by 
      lazy { init_ic_health_beauty() }

  public val ic_light: DrawableResource by 
      lazy { init_ic_light() }

  public val ic_minus: DrawableResource by 
      lazy { init_ic_minus() }

  public val ic_mobile_app: DrawableResource by 
      lazy { init_ic_mobile_app() }

  public val ic_money_send: DrawableResource by 
      lazy { init_ic_money_send() }

  public val ic_money_track: DrawableResource by 
      lazy { init_ic_money_track() }

  public val ic_plus: DrawableResource by 
      lazy { init_ic_plus() }

  public val ic_plus_round: DrawableResource by 
      lazy { init_ic_plus_round() }

  public val ic_profile: DrawableResource by 
      lazy { init_ic_profile() }

  public val ic_promo: DrawableResource by 
      lazy { init_ic_promo() }

  public val ic_quick_split: DrawableResource by 
      lazy { init_ic_quick_split() }

  public val ic_split_money: DrawableResource by 
      lazy { init_ic_split_money() }

  public val ic_system: DrawableResource by 
      lazy { init_ic_system() }

  public val ic_user: DrawableResource by 
      lazy { init_ic_user() }

  public val ic_user_add: DrawableResource by 
      lazy { init_ic_user_add() }

  public val ic_web: DrawableResource by 
      lazy { init_ic_web() }

  public val img_add_data: DrawableResource by 
      lazy { init_img_add_data() }

  public val img_best_offer: DrawableResource by 
      lazy { init_img_best_offer() }

  public val img_construct: DrawableResource by 
      lazy { init_img_construct() }

  public val img_empty_group: DrawableResource by 
      lazy { init_img_empty_group() }

  public val img_feature_ai_group: DrawableResource by 
      lazy { init_img_feature_ai_group() }

  public val img_feature_cat_arrow: DrawableResource by 
      lazy { init_img_feature_cat_arrow() }

  public val img_feature_fx: DrawableResource by 
      lazy { init_img_feature_fx() }

  public val img_feature_images: DrawableResource by 
      lazy { init_img_feature_images() }

  public val img_feature_no_ads: DrawableResource by 
      lazy { init_img_feature_no_ads() }

  public val img_feature_protect: DrawableResource by 
      lazy { init_img_feature_protect() }

  public val img_feature_quick_add: DrawableResource by 
      lazy { init_img_feature_quick_add() }

  public val img_feature_single_settle: DrawableResource by 
      lazy { init_img_feature_single_settle() }

  public val img_feature_suggest: DrawableResource by 
      lazy { init_img_feature_suggest() }

  public val img_login: DrawableResource by 
      lazy { init_img_login() }

  public val img_logo_black: DrawableResource by 
      lazy { init_img_logo_black() }

  public val img_logo_color: DrawableResource by 
      lazy { init_img_logo_color() }

  public val img_logo_color_plus: DrawableResource by 
      lazy { init_img_logo_color_plus() }

  public val img_logo_white: DrawableResource by 
      lazy { init_img_logo_white() }

  public val img_search_empty: DrawableResource by 
      lazy { init_img_search_empty() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("btn_apple_black", CommonMainDrawable0.btn_apple_black)
  map.put("btn_apple_white", CommonMainDrawable0.btn_apple_white)
  map.put("btn_google_black", CommonMainDrawable0.btn_google_black)
  map.put("btn_google_neutral", CommonMainDrawable0.btn_google_neutral)
  map.put("btn_google_white", CommonMainDrawable0.btn_google_white)
  map.put("compose_multiplatform", CommonMainDrawable0.compose_multiplatform)
  map.put("ic_add_image", CommonMainDrawable0.ic_add_image)
  map.put("ic_add_many", CommonMainDrawable0.ic_add_many)
  map.put("ic_ai", CommonMainDrawable0.ic_ai)
  map.put("ic_android", CommonMainDrawable0.ic_android)
  map.put("ic_badge_white", CommonMainDrawable0.ic_badge_white)
  map.put("ic_cat_bank", CommonMainDrawable0.ic_cat_bank)
  map.put("ic_cat_beauty", CommonMainDrawable0.ic_cat_beauty)
  map.put("ic_cat_books", CommonMainDrawable0.ic_cat_books)
  map.put("ic_cat_car", CommonMainDrawable0.ic_cat_car)
  map.put("ic_cat_cash", CommonMainDrawable0.ic_cat_cash)
  map.put("ic_cat_cleaning", CommonMainDrawable0.ic_cat_cleaning)
  map.put("ic_cat_clothes", CommonMainDrawable0.ic_cat_clothes)
  map.put("ic_cat_coffee", CommonMainDrawable0.ic_cat_coffee)
  map.put("ic_cat_concert", CommonMainDrawable0.ic_cat_concert)
  map.put("ic_cat_crypto", CommonMainDrawable0.ic_cat_crypto)
  map.put("ic_cat_electricity", CommonMainDrawable0.ic_cat_electricity)
  map.put("ic_cat_entertainment", CommonMainDrawable0.ic_cat_entertainment)
  map.put("ic_cat_fastfood", CommonMainDrawable0.ic_cat_fastfood)
  map.put("ic_cat_fee", CommonMainDrawable0.ic_cat_fee)
  map.put("ic_cat_flight", CommonMainDrawable0.ic_cat_flight)
  map.put("ic_cat_food_drink", CommonMainDrawable0.ic_cat_food_drink)
  map.put("ic_cat_furnishing", CommonMainDrawable0.ic_cat_furnishing)
  map.put("ic_cat_garbage", CommonMainDrawable0.ic_cat_garbage)
  map.put("ic_cat_gift", CommonMainDrawable0.ic_cat_gift)
  map.put("ic_cat_groceries", CommonMainDrawable0.ic_cat_groceries)
  map.put("ic_cat_health", CommonMainDrawable0.ic_cat_health)
  map.put("ic_cat_hobby", CommonMainDrawable0.ic_cat_hobby)
  map.put("ic_cat_housing", CommonMainDrawable0.ic_cat_housing)
  map.put("ic_cat_internet", CommonMainDrawable0.ic_cat_internet)
  map.put("ic_cat_magic", CommonMainDrawable0.ic_cat_magic)
  map.put("ic_cat_money", CommonMainDrawable0.ic_cat_money)
  map.put("ic_cat_movie", CommonMainDrawable0.ic_cat_movie)
  map.put("ic_cat_none", CommonMainDrawable0.ic_cat_none)
  map.put("ic_cat_parking", CommonMainDrawable0.ic_cat_parking)
  map.put("ic_cat_property_tax", CommonMainDrawable0.ic_cat_property_tax)
  map.put("ic_cat_public_transport", CommonMainDrawable0.ic_cat_public_transport)
  map.put("ic_cat_recycle", CommonMainDrawable0.ic_cat_recycle)
  map.put("ic_cat_rent", CommonMainDrawable0.ic_cat_rent)
  map.put("ic_cat_repair", CommonMainDrawable0.ic_cat_repair)
  map.put("ic_cat_restaurant", CommonMainDrawable0.ic_cat_restaurant)
  map.put("ic_cat_security", CommonMainDrawable0.ic_cat_security)
  map.put("ic_cat_shoes", CommonMainDrawable0.ic_cat_shoes)
  map.put("ic_cat_shopping", CommonMainDrawable0.ic_cat_shopping)
  map.put("ic_cat_sport", CommonMainDrawable0.ic_cat_sport)
  map.put("ic_cat_sport_event", CommonMainDrawable0.ic_cat_sport_event)
  map.put("ic_cat_taxi", CommonMainDrawable0.ic_cat_taxi)
  map.put("ic_cat_technology", CommonMainDrawable0.ic_cat_technology)
  map.put("ic_cat_toll", CommonMainDrawable0.ic_cat_toll)
  map.put("ic_cat_transport", CommonMainDrawable0.ic_cat_transport)
  map.put("ic_cat_transport_travel", CommonMainDrawable0.ic_cat_transport_travel)
  map.put("ic_cat_utilities", CommonMainDrawable0.ic_cat_utilities)
  map.put("ic_cat_water", CommonMainDrawable0.ic_cat_water)
  map.put("ic_dark", CommonMainDrawable0.ic_dark)
  map.put("ic_date", CommonMainDrawable0.ic_date)
  map.put("ic_down", CommonMainDrawable0.ic_down)
  map.put("ic_eifell_ads", CommonMainDrawable0.ic_eifell_ads)
  map.put("ic_feedback", CommonMainDrawable0.ic_feedback)
  map.put("ic_flag", CommonMainDrawable0.ic_flag)
  map.put("ic_group", CommonMainDrawable0.ic_group)
  map.put("ic_health_beauty", CommonMainDrawable0.ic_health_beauty)
  map.put("ic_light", CommonMainDrawable0.ic_light)
  map.put("ic_minus", CommonMainDrawable0.ic_minus)
  map.put("ic_mobile_app", CommonMainDrawable0.ic_mobile_app)
  map.put("ic_money_send", CommonMainDrawable0.ic_money_send)
  map.put("ic_money_track", CommonMainDrawable0.ic_money_track)
  map.put("ic_plus", CommonMainDrawable0.ic_plus)
  map.put("ic_plus_round", CommonMainDrawable0.ic_plus_round)
  map.put("ic_profile", CommonMainDrawable0.ic_profile)
  map.put("ic_promo", CommonMainDrawable0.ic_promo)
  map.put("ic_quick_split", CommonMainDrawable0.ic_quick_split)
  map.put("ic_split_money", CommonMainDrawable0.ic_split_money)
  map.put("ic_system", CommonMainDrawable0.ic_system)
  map.put("ic_user", CommonMainDrawable0.ic_user)
  map.put("ic_user_add", CommonMainDrawable0.ic_user_add)
  map.put("ic_web", CommonMainDrawable0.ic_web)
  map.put("img_add_data", CommonMainDrawable0.img_add_data)
  map.put("img_best_offer", CommonMainDrawable0.img_best_offer)
  map.put("img_construct", CommonMainDrawable0.img_construct)
  map.put("img_empty_group", CommonMainDrawable0.img_empty_group)
  map.put("img_feature_ai_group", CommonMainDrawable0.img_feature_ai_group)
  map.put("img_feature_cat_arrow", CommonMainDrawable0.img_feature_cat_arrow)
  map.put("img_feature_fx", CommonMainDrawable0.img_feature_fx)
  map.put("img_feature_images", CommonMainDrawable0.img_feature_images)
  map.put("img_feature_no_ads", CommonMainDrawable0.img_feature_no_ads)
  map.put("img_feature_protect", CommonMainDrawable0.img_feature_protect)
  map.put("img_feature_quick_add", CommonMainDrawable0.img_feature_quick_add)
  map.put("img_feature_single_settle", CommonMainDrawable0.img_feature_single_settle)
  map.put("img_feature_suggest", CommonMainDrawable0.img_feature_suggest)
  map.put("img_login", CommonMainDrawable0.img_login)
  map.put("img_logo_black", CommonMainDrawable0.img_logo_black)
  map.put("img_logo_color", CommonMainDrawable0.img_logo_color)
  map.put("img_logo_color_plus", CommonMainDrawable0.img_logo_color_plus)
  map.put("img_logo_white", CommonMainDrawable0.img_logo_white)
  map.put("img_search_empty", CommonMainDrawable0.img_search_empty)
}

internal val Res.drawable.btn_apple_black: DrawableResource
  get() = CommonMainDrawable0.btn_apple_black

private fun init_btn_apple_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_apple_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_apple_black.xml", -1, -1),
    )
)

internal val Res.drawable.btn_apple_white: DrawableResource
  get() = CommonMainDrawable0.btn_apple_white

private fun init_btn_apple_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_apple_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_apple_white.xml", -1, -1),
    )
)

internal val Res.drawable.btn_google_black: DrawableResource
  get() = CommonMainDrawable0.btn_google_black

private fun init_btn_google_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_google_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_google_black.xml", -1, -1),
    )
)

internal val Res.drawable.btn_google_neutral: DrawableResource
  get() = CommonMainDrawable0.btn_google_neutral

private fun init_btn_google_neutral(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_google_neutral",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_google_neutral.xml", -1, -1),
    )
)

internal val Res.drawable.btn_google_white: DrawableResource
  get() = CommonMainDrawable0.btn_google_white

private fun init_btn_google_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_google_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_google_white.xml", -1, -1),
    )
)

internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

internal val Res.drawable.ic_add_image: DrawableResource
  get() = CommonMainDrawable0.ic_add_image

private fun init_ic_add_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_add_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_add_image.xml", -1, -1),
    )
)

internal val Res.drawable.ic_add_many: DrawableResource
  get() = CommonMainDrawable0.ic_add_many

private fun init_ic_add_many(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_add_many",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_add_many.xml", -1, -1),
    )
)

internal val Res.drawable.ic_ai: DrawableResource
  get() = CommonMainDrawable0.ic_ai

private fun init_ic_ai(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_ai",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_ai.xml", -1, -1),
    )
)

internal val Res.drawable.ic_android: DrawableResource
  get() = CommonMainDrawable0.ic_android

private fun init_ic_android(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_android",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_android.xml", -1, -1),
    )
)

internal val Res.drawable.ic_badge_white: DrawableResource
  get() = CommonMainDrawable0.ic_badge_white

private fun init_ic_badge_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_badge_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_badge_white.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_bank: DrawableResource
  get() = CommonMainDrawable0.ic_cat_bank

private fun init_ic_cat_bank(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_bank",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_bank.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_beauty: DrawableResource
  get() = CommonMainDrawable0.ic_cat_beauty

private fun init_ic_cat_beauty(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_beauty",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_beauty.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_books: DrawableResource
  get() = CommonMainDrawable0.ic_cat_books

private fun init_ic_cat_books(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_books",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_books.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_car: DrawableResource
  get() = CommonMainDrawable0.ic_cat_car

private fun init_ic_cat_car(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_car",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_car.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_cash: DrawableResource
  get() = CommonMainDrawable0.ic_cat_cash

private fun init_ic_cat_cash(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_cash",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_cash.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_cleaning: DrawableResource
  get() = CommonMainDrawable0.ic_cat_cleaning

private fun init_ic_cat_cleaning(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_cleaning",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_cleaning.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_clothes: DrawableResource
  get() = CommonMainDrawable0.ic_cat_clothes

private fun init_ic_cat_clothes(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_clothes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_clothes.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_coffee: DrawableResource
  get() = CommonMainDrawable0.ic_cat_coffee

private fun init_ic_cat_coffee(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_coffee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_coffee.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_concert: DrawableResource
  get() = CommonMainDrawable0.ic_cat_concert

private fun init_ic_cat_concert(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_concert",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_concert.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_crypto: DrawableResource
  get() = CommonMainDrawable0.ic_cat_crypto

private fun init_ic_cat_crypto(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_crypto",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_crypto.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_electricity: DrawableResource
  get() = CommonMainDrawable0.ic_cat_electricity

private fun init_ic_cat_electricity(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_electricity",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_electricity.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_entertainment: DrawableResource
  get() = CommonMainDrawable0.ic_cat_entertainment

private fun init_ic_cat_entertainment(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_entertainment",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_entertainment.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_fastfood: DrawableResource
  get() = CommonMainDrawable0.ic_cat_fastfood

private fun init_ic_cat_fastfood(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_fastfood",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_fastfood.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_fee: DrawableResource
  get() = CommonMainDrawable0.ic_cat_fee

private fun init_ic_cat_fee(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_fee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_fee.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_flight: DrawableResource
  get() = CommonMainDrawable0.ic_cat_flight

private fun init_ic_cat_flight(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_flight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_flight.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_food_drink: DrawableResource
  get() = CommonMainDrawable0.ic_cat_food_drink

private fun init_ic_cat_food_drink(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_food_drink",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_food_drink.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_furnishing: DrawableResource
  get() = CommonMainDrawable0.ic_cat_furnishing

private fun init_ic_cat_furnishing(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_furnishing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_furnishing.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_garbage: DrawableResource
  get() = CommonMainDrawable0.ic_cat_garbage

private fun init_ic_cat_garbage(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_garbage",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_garbage.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_gift: DrawableResource
  get() = CommonMainDrawable0.ic_cat_gift

private fun init_ic_cat_gift(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_gift",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_gift.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_groceries: DrawableResource
  get() = CommonMainDrawable0.ic_cat_groceries

private fun init_ic_cat_groceries(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_groceries",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_groceries.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_health: DrawableResource
  get() = CommonMainDrawable0.ic_cat_health

private fun init_ic_cat_health(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_health",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_health.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_hobby: DrawableResource
  get() = CommonMainDrawable0.ic_cat_hobby

private fun init_ic_cat_hobby(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_hobby",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_hobby.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_housing: DrawableResource
  get() = CommonMainDrawable0.ic_cat_housing

private fun init_ic_cat_housing(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_housing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_housing.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_internet: DrawableResource
  get() = CommonMainDrawable0.ic_cat_internet

private fun init_ic_cat_internet(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_internet",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_internet.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_magic: DrawableResource
  get() = CommonMainDrawable0.ic_cat_magic

private fun init_ic_cat_magic(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_magic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_magic.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_money: DrawableResource
  get() = CommonMainDrawable0.ic_cat_money

private fun init_ic_cat_money(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_money",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_money.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_movie: DrawableResource
  get() = CommonMainDrawable0.ic_cat_movie

private fun init_ic_cat_movie(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_movie",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_movie.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_none: DrawableResource
  get() = CommonMainDrawable0.ic_cat_none

private fun init_ic_cat_none(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_none",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_none.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_parking: DrawableResource
  get() = CommonMainDrawable0.ic_cat_parking

private fun init_ic_cat_parking(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_parking",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_parking.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_property_tax: DrawableResource
  get() = CommonMainDrawable0.ic_cat_property_tax

private fun init_ic_cat_property_tax(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_property_tax",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_property_tax.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_public_transport: DrawableResource
  get() = CommonMainDrawable0.ic_cat_public_transport

private fun init_ic_cat_public_transport(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_public_transport",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_public_transport.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_recycle: DrawableResource
  get() = CommonMainDrawable0.ic_cat_recycle

private fun init_ic_cat_recycle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_recycle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_recycle.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_rent: DrawableResource
  get() = CommonMainDrawable0.ic_cat_rent

private fun init_ic_cat_rent(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_rent",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_rent.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_repair: DrawableResource
  get() = CommonMainDrawable0.ic_cat_repair

private fun init_ic_cat_repair(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_repair",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_repair.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_restaurant: DrawableResource
  get() = CommonMainDrawable0.ic_cat_restaurant

private fun init_ic_cat_restaurant(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_restaurant",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_restaurant.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_security: DrawableResource
  get() = CommonMainDrawable0.ic_cat_security

private fun init_ic_cat_security(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_security",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_security.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_shoes: DrawableResource
  get() = CommonMainDrawable0.ic_cat_shoes

private fun init_ic_cat_shoes(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_shoes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_shoes.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_shopping: DrawableResource
  get() = CommonMainDrawable0.ic_cat_shopping

private fun init_ic_cat_shopping(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_shopping",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_shopping.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_sport: DrawableResource
  get() = CommonMainDrawable0.ic_cat_sport

private fun init_ic_cat_sport(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_sport",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_sport.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_sport_event: DrawableResource
  get() = CommonMainDrawable0.ic_cat_sport_event

private fun init_ic_cat_sport_event(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_sport_event",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_sport_event.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_taxi: DrawableResource
  get() = CommonMainDrawable0.ic_cat_taxi

private fun init_ic_cat_taxi(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_taxi",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_taxi.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_technology: DrawableResource
  get() = CommonMainDrawable0.ic_cat_technology

private fun init_ic_cat_technology(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_technology",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_technology.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_toll: DrawableResource
  get() = CommonMainDrawable0.ic_cat_toll

private fun init_ic_cat_toll(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_toll",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_toll.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_transport: DrawableResource
  get() = CommonMainDrawable0.ic_cat_transport

private fun init_ic_cat_transport(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_transport",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_transport.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_transport_travel: DrawableResource
  get() = CommonMainDrawable0.ic_cat_transport_travel

private fun init_ic_cat_transport_travel(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_transport_travel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_transport_travel.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_utilities: DrawableResource
  get() = CommonMainDrawable0.ic_cat_utilities

private fun init_ic_cat_utilities(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_utilities",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_utilities.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cat_water: DrawableResource
  get() = CommonMainDrawable0.ic_cat_water

private fun init_ic_cat_water(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cat_water",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_cat_water.xml", -1, -1),
    )
)

internal val Res.drawable.ic_dark: DrawableResource
  get() = CommonMainDrawable0.ic_dark

private fun init_ic_dark(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_dark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_dark.xml", -1, -1),
    )
)

internal val Res.drawable.ic_date: DrawableResource
  get() = CommonMainDrawable0.ic_date

private fun init_ic_date(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_date",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_date.xml", -1, -1),
    )
)

internal val Res.drawable.ic_down: DrawableResource
  get() = CommonMainDrawable0.ic_down

private fun init_ic_down(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_down",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_down.xml", -1, -1),
    )
)

internal val Res.drawable.ic_eifell_ads: DrawableResource
  get() = CommonMainDrawable0.ic_eifell_ads

private fun init_ic_eifell_ads(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_eifell_ads",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_eifell_ads.xml", -1, -1),
    )
)

internal val Res.drawable.ic_feedback: DrawableResource
  get() = CommonMainDrawable0.ic_feedback

private fun init_ic_feedback(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_feedback",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_feedback.xml", -1, -1),
    )
)

internal val Res.drawable.ic_flag: DrawableResource
  get() = CommonMainDrawable0.ic_flag

private fun init_ic_flag(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_flag",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_flag.xml", -1, -1),
    )
)

internal val Res.drawable.ic_group: DrawableResource
  get() = CommonMainDrawable0.ic_group

private fun init_ic_group(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_group.xml", -1, -1),
    )
)

internal val Res.drawable.ic_health_beauty: DrawableResource
  get() = CommonMainDrawable0.ic_health_beauty

private fun init_ic_health_beauty(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_health_beauty",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_health_beauty.xml", -1, -1),
    )
)

internal val Res.drawable.ic_light: DrawableResource
  get() = CommonMainDrawable0.ic_light

private fun init_ic_light(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_light.xml", -1, -1),
    )
)

internal val Res.drawable.ic_minus: DrawableResource
  get() = CommonMainDrawable0.ic_minus

private fun init_ic_minus(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_minus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_minus.xml", -1, -1),
    )
)

internal val Res.drawable.ic_mobile_app: DrawableResource
  get() = CommonMainDrawable0.ic_mobile_app

private fun init_ic_mobile_app(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_mobile_app",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_mobile_app.xml", -1, -1),
    )
)

internal val Res.drawable.ic_money_send: DrawableResource
  get() = CommonMainDrawable0.ic_money_send

private fun init_ic_money_send(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_money_send",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_money_send.xml", -1, -1),
    )
)

internal val Res.drawable.ic_money_track: DrawableResource
  get() = CommonMainDrawable0.ic_money_track

private fun init_ic_money_track(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_money_track",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_money_track.xml", -1, -1),
    )
)

internal val Res.drawable.ic_plus: DrawableResource
  get() = CommonMainDrawable0.ic_plus

private fun init_ic_plus(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_plus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_plus.xml", -1, -1),
    )
)

internal val Res.drawable.ic_plus_round: DrawableResource
  get() = CommonMainDrawable0.ic_plus_round

private fun init_ic_plus_round(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_plus_round",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_plus_round.xml", -1, -1),
    )
)

internal val Res.drawable.ic_profile: DrawableResource
  get() = CommonMainDrawable0.ic_profile

private fun init_ic_profile(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_profile",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_profile.xml", -1, -1),
    )
)

internal val Res.drawable.ic_promo: DrawableResource
  get() = CommonMainDrawable0.ic_promo

private fun init_ic_promo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_promo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_promo.xml", -1, -1),
    )
)

internal val Res.drawable.ic_quick_split: DrawableResource
  get() = CommonMainDrawable0.ic_quick_split

private fun init_ic_quick_split(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_quick_split",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_quick_split.xml", -1, -1),
    )
)

internal val Res.drawable.ic_split_money: DrawableResource
  get() = CommonMainDrawable0.ic_split_money

private fun init_ic_split_money(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_split_money",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_split_money.png", -1, -1),
    )
)

internal val Res.drawable.ic_system: DrawableResource
  get() = CommonMainDrawable0.ic_system

private fun init_ic_system(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_system",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_system.xml", -1, -1),
    )
)

internal val Res.drawable.ic_user: DrawableResource
  get() = CommonMainDrawable0.ic_user

private fun init_ic_user(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_user",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_user.xml", -1, -1),
    )
)

internal val Res.drawable.ic_user_add: DrawableResource
  get() = CommonMainDrawable0.ic_user_add

private fun init_ic_user_add(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_user_add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_user_add.xml", -1, -1),
    )
)

internal val Res.drawable.ic_web: DrawableResource
  get() = CommonMainDrawable0.ic_web

private fun init_ic_web(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_web",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_web.xml", -1, -1),
    )
)

internal val Res.drawable.img_add_data: DrawableResource
  get() = CommonMainDrawable0.img_add_data

private fun init_img_add_data(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_add_data",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_add_data.xml", -1, -1),
    )
)

internal val Res.drawable.img_best_offer: DrawableResource
  get() = CommonMainDrawable0.img_best_offer

private fun init_img_best_offer(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_best_offer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_best_offer.xml", -1, -1),
    )
)

internal val Res.drawable.img_construct: DrawableResource
  get() = CommonMainDrawable0.img_construct

private fun init_img_construct(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_construct",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_construct.xml", -1, -1),
    )
)

internal val Res.drawable.img_empty_group: DrawableResource
  get() = CommonMainDrawable0.img_empty_group

private fun init_img_empty_group(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_empty_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_empty_group.xml", -1, -1),
    )
)

internal val Res.drawable.img_feature_ai_group: DrawableResource
  get() = CommonMainDrawable0.img_feature_ai_group

private fun init_img_feature_ai_group(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_ai_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_ai_group.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_cat_arrow: DrawableResource
  get() = CommonMainDrawable0.img_feature_cat_arrow

private fun init_img_feature_cat_arrow(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_cat_arrow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_cat_arrow.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_fx: DrawableResource
  get() = CommonMainDrawable0.img_feature_fx

private fun init_img_feature_fx(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_fx",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_fx.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_images: DrawableResource
  get() = CommonMainDrawable0.img_feature_images

private fun init_img_feature_images(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_images",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_images.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_no_ads: DrawableResource
  get() = CommonMainDrawable0.img_feature_no_ads

private fun init_img_feature_no_ads(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_no_ads",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_no_ads.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_protect: DrawableResource
  get() = CommonMainDrawable0.img_feature_protect

private fun init_img_feature_protect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_protect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_protect.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_quick_add: DrawableResource
  get() = CommonMainDrawable0.img_feature_quick_add

private fun init_img_feature_quick_add(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_quick_add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_quick_add.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_single_settle: DrawableResource
  get() = CommonMainDrawable0.img_feature_single_settle

private fun init_img_feature_single_settle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_single_settle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_single_settle.png", -1, -1),
    )
)

internal val Res.drawable.img_feature_suggest: DrawableResource
  get() = CommonMainDrawable0.img_feature_suggest

private fun init_img_feature_suggest(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_feature_suggest",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_feature_suggest.png", -1, -1),
    )
)

internal val Res.drawable.img_login: DrawableResource
  get() = CommonMainDrawable0.img_login

private fun init_img_login(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_login",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_login.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_black: DrawableResource
  get() = CommonMainDrawable0.img_logo_black

private fun init_img_logo_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_black.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_color: DrawableResource
  get() = CommonMainDrawable0.img_logo_color

private fun init_img_logo_color(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_color",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_color.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_color_plus: DrawableResource
  get() = CommonMainDrawable0.img_logo_color_plus

private fun init_img_logo_color_plus(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_color_plus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_color_plus.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_white: DrawableResource
  get() = CommonMainDrawable0.img_logo_white

private fun init_img_logo_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_white.xml", -1, -1),
    )
)

internal val Res.drawable.img_search_empty: DrawableResource
  get() = CommonMainDrawable0.img_search_empty

private fun init_img_search_empty(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_search_empty",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_search_empty.xml", -1, -1),
    )
)
