@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package split.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val add_expense_to_group: StringResource by 
      lazy { init_add_expense_to_group() }

  public val add_group_cd: StringResource by 
      lazy { init_add_group_cd() }

  public val add_group_image: StringResource by 
      lazy { init_add_group_image() }

  public val add_share: StringResource by 
      lazy { init_add_share() }

  public val add_user_to_group: StringResource by 
      lazy { init_add_user_to_group() }

  public val all_participants: StringResource by 
      lazy { init_all_participants() }

  public val already_settled: StringResource by 
      lazy { init_already_settled() }

  public val amount: StringResource by 
      lazy { init_amount() }

  public val amounts: StringResource by 
      lazy { init_amounts() }

  public val andspaces: StringResource by 
      lazy { init_andspaces() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val app_version: StringResource by 
      lazy { init_app_version() }

  public val at_date: StringResource by 
      lazy { init_at_date() }

  public val available_in_mobile_app: StringResource by 
      lazy { init_available_in_mobile_app() }

  public val back: StringResource by 
      lazy { init_back() }

  public val back_btn_cd: StringResource by 
      lazy { init_back_btn_cd() }

  public val balances: StringResource by 
      lazy { init_balances() }

  public val balances_explanation: StringResource by 
      lazy { init_balances_explanation() }

  public val benefits: StringResource by 
      lazy { init_benefits() }

  public val best_offer_badge: StringResource by 
      lazy { init_best_offer_badge() }

  public val calculate_best_way_settle: StringResource by 
      lazy { init_calculate_best_way_settle() }

  public val cancel: StringResource by 
      lazy { init_cancel() }

  public val category_bank: StringResource by 
      lazy { init_category_bank() }

  public val category_beauty: StringResource by 
      lazy { init_category_beauty() }

  public val category_books: StringResource by 
      lazy { init_category_books() }

  public val category_car: StringResource by 
      lazy { init_category_car() }

  public val category_cash: StringResource by 
      lazy { init_category_cash() }

  public val category_cleaning: StringResource by 
      lazy { init_category_cleaning() }

  public val category_clothes: StringResource by 
      lazy { init_category_clothes() }

  public val category_coffee: StringResource by 
      lazy { init_category_coffee() }

  public val category_concert: StringResource by 
      lazy { init_category_concert() }

  public val category_crypto: StringResource by 
      lazy { init_category_crypto() }

  public val category_electricity: StringResource by 
      lazy { init_category_electricity() }

  public val category_entertainment: StringResource by 
      lazy { init_category_entertainment() }

  public val category_fast_food: StringResource by 
      lazy { init_category_fast_food() }

  public val category_fee: StringResource by 
      lazy { init_category_fee() }

  public val category_flight: StringResource by 
      lazy { init_category_flight() }

  public val category_food_drink: StringResource by 
      lazy { init_category_food_drink() }

  public val category_furnishing: StringResource by 
      lazy { init_category_furnishing() }

  public val category_garbage: StringResource by 
      lazy { init_category_garbage() }

  public val category_gifts: StringResource by 
      lazy { init_category_gifts() }

  public val category_groceries: StringResource by 
      lazy { init_category_groceries() }

  public val category_health: StringResource by 
      lazy { init_category_health() }

  public val category_health_beauty: StringResource by 
      lazy { init_category_health_beauty() }

  public val category_hobby: StringResource by 
      lazy { init_category_hobby() }

  public val category_housing: StringResource by 
      lazy { init_category_housing() }

  public val category_internet: StringResource by 
      lazy { init_category_internet() }

  public val category_magic: StringResource by 
      lazy { init_category_magic() }

  public val category_money: StringResource by 
      lazy { init_category_money() }

  public val category_movie: StringResource by 
      lazy { init_category_movie() }

  public val category_none: StringResource by 
      lazy { init_category_none() }

  public val category_parking: StringResource by 
      lazy { init_category_parking() }

  public val category_public: StringResource by 
      lazy { init_category_public() }

  public val category_recycling: StringResource by 
      lazy { init_category_recycling() }

  public val category_rent: StringResource by 
      lazy { init_category_rent() }

  public val category_repair: StringResource by 
      lazy { init_category_repair() }

  public val category_restaurant: StringResource by 
      lazy { init_category_restaurant() }

  public val category_security: StringResource by 
      lazy { init_category_security() }

  public val category_shoes: StringResource by 
      lazy { init_category_shoes() }

  public val category_shopping: StringResource by 
      lazy { init_category_shopping() }

  public val category_sport: StringResource by 
      lazy { init_category_sport() }

  public val category_sport_event: StringResource by 
      lazy { init_category_sport_event() }

  public val category_tax: StringResource by 
      lazy { init_category_tax() }

  public val category_taxi: StringResource by 
      lazy { init_category_taxi() }

  public val category_technology: StringResource by 
      lazy { init_category_technology() }

  public val category_tolls: StringResource by 
      lazy { init_category_tolls() }

  public val category_transport_travel: StringResource by 
      lazy { init_category_transport_travel() }

  public val category_transportation: StringResource by 
      lazy { init_category_transportation() }

  public val category_utilities: StringResource by 
      lazy { init_category_utilities() }

  public val category_water: StringResource by 
      lazy { init_category_water() }

  public val change_color_mode: StringResource by 
      lazy { init_change_color_mode() }

  public val change_theme: StringResource by 
      lazy { init_change_theme() }

  public val close_picker: StringResource by 
      lazy { init_close_picker() }

  public val collapsed: StringResource by 
      lazy { init_collapsed() }

  public val confirm_delete_account: StringResource by 
      lazy { init_confirm_delete_account() }

  public val confirm_delete_expense_message: StringResource by 
      lazy { init_confirm_delete_expense_message() }

  public val confirm_mark_all_settled: StringResource by 
      lazy { init_confirm_mark_all_settled() }

  public val confirm_no_wait: StringResource by 
      lazy { init_confirm_no_wait() }

  public val confirm_yes_delete: StringResource by 
      lazy { init_confirm_yes_delete() }

  public val confirm_yes_join: StringResource by 
      lazy { init_confirm_yes_join() }

  public val confirm_yes_leave: StringResource by 
      lazy { init_confirm_yes_leave() }

  public val confirm_yes_settle: StringResource by 
      lazy { init_confirm_yes_settle() }

  public val contacts_in_book: StringResource by 
      lazy { init_contacts_in_book() }

  public val contacts_in_wesplit: StringResource by 
      lazy { init_contacts_in_wesplit() }

  public val create: StringResource by 
      lazy { init_create() }

  public val create_new_contact: StringResource by 
      lazy { init_create_new_contact() }

  public val current_group_participants: StringResource by 
      lazy { init_current_group_participants() }

  public val dark_theme: StringResource by 
      lazy { init_dark_theme() }

  public val data_syncing: StringResource by 
      lazy { init_data_syncing() }

  public val delete_account: StringResource by 
      lazy { init_delete_account() }

  public val delete_account_confirmation: StringResource by 
      lazy { init_delete_account_confirmation() }

  public val delete_account_from_wesplit: StringResource by 
      lazy { init_delete_account_from_wesplit() }

  public val delete_expense: StringResource by 
      lazy { init_delete_expense() }

  public val delete_expense_group: StringResource by 
      lazy { init_delete_expense_group() }

  public val delete_expense_title: StringResource by 
      lazy { init_delete_expense_title() }

  public val detailed: StringResource by 
      lazy { init_detailed() }

  public val download_mobile_app: StringResource by 
      lazy { init_download_mobile_app() }

  public val edit_group: StringResource by 
      lazy { init_edit_group() }

  public val empty_group_cd: StringResource by 
      lazy { init_empty_group_cd() }

  public val empty_transaction_description: StringResource by 
      lazy { init_empty_transaction_description() }

  public val empty_transactions_cd: StringResource by 
      lazy { init_empty_transactions_cd() }

  public val enter_promocode: StringResource by 
      lazy { init_enter_promocode() }

  public val enter_valid_promocode: StringResource by 
      lazy { init_enter_valid_promocode() }

  public val equal: StringResource by 
      lazy { init_equal() }

  public val error: StringResource by 
      lazy { init_error() }

  public val error_encountered_problems: StringResource by 
      lazy { init_error_encountered_problems() }

  public val error_fetching_plans: StringResource by 
      lazy { init_error_fetching_plans() }

  public val expanded: StringResource by 
      lazy { init_expanded() }

  public val expense_title: StringResource by 
      lazy { init_expense_title() }

  public val feedback: StringResource by 
      lazy { init_feedback() }

  public val fetch_available_plans: StringResource by 
      lazy { init_fetch_available_plans() }

  public val forget_group: StringResource by 
      lazy { init_forget_group() }

  public val forgive_and_forget: StringResource by 
      lazy { init_forgive_and_forget() }

  public val free: StringResource by 
      lazy { init_free() }

  public val fx_single_currency: StringResource by 
      lazy { init_fx_single_currency() }

  public val get_app: StringResource by 
      lazy { init_get_app() }

  public val get_mobile_app: StringResource by 
      lazy { init_get_mobile_app() }

  public val get_plus_instant_balances: StringResource by 
      lazy { init_get_plus_instant_balances() }

  public val gets_back: StringResource by 
      lazy { init_gets_back() }

  public val go_to_privacy: StringResource by 
      lazy { init_go_to_privacy() }

  public val go_to_terms_conditions: StringResource by 
      lazy { init_go_to_terms_conditions() }

  public val got_it: StringResource by 
      lazy { init_got_it() }

  public val grant_permission: StringResource by 
      lazy { init_grant_permission() }

  public val group_detailed_empty_description: StringResource by 
      lazy { init_group_detailed_empty_description() }

  public val group_list_empty_description_authorized: StringResource by 
      lazy { init_group_list_empty_description_authorized() }

  public val group_list_empty_description_unregistered: StringResource by 
      lazy { init_group_list_empty_description_unregistered() }

  public val group_list_title: StringResource by 
      lazy { init_group_list_title() }

  public val group_name: StringResource by 
      lazy { init_group_name() }

  public val groups: StringResource by 
      lazy { init_groups() }

  public val image_description_ai: StringResource by 
      lazy { init_image_description_ai() }

  public val info_sign: StringResource by 
      lazy { init_info_sign() }

  public val invite_user: StringResource by 
      lazy { init_invite_user() }

  public val join_agree: StringResource by 
      lazy { init_join_agree() }

  public val join_group: StringResource by 
      lazy { init_join_group() }

  public val join_group_as_new_participant: StringResource by 
      lazy { init_join_group_as_new_participant() }

  public val join_group_as_user: StringResource by 
      lazy { init_join_group_as_user() }

  public val join_new_participant: StringResource by 
      lazy { init_join_new_participant() }

  public val label_apply: StringResource by 
      lazy { init_label_apply() }

  public val label_code: StringResource by 
      lazy { init_label_code() }

  public val leave_group: StringResource by 
      lazy { init_leave_group() }

  public val leave_group_confirmation: StringResource by 
      lazy { init_leave_group_confirmation() }

  public val light_theme: StringResource by 
      lazy { init_light_theme() }

  public val loading: StringResource by 
      lazy { init_loading() }

  public val locked_feature: StringResource by 
      lazy { init_locked_feature() }

  public val login: StringResource by 
      lazy { init_login() }

  public val login_anon: StringResource by 
      lazy { init_login_anon() }

  public val login_button_cd: StringResource by 
      lazy { init_login_button_cd() }

  public val login_to_create_descr: StringResource by 
      lazy { init_login_to_create_descr() }

  public val login_to_protect_expense: StringResource by 
      lazy { init_login_to_protect_expense() }

  public val login_with_apple_cd: StringResource by 
      lazy { init_login_with_apple_cd() }

  public val login_with_google_cd: StringResource by 
      lazy { init_login_with_google_cd() }

  public val logout: StringResource by 
      lazy { init_logout() }

  public val mark_as_non_settled: StringResource by 
      lazy { init_mark_as_non_settled() }

  public val mark_as_settled: StringResource by 
      lazy { init_mark_as_settled() }

  public val month_plus: StringResource by 
      lazy { init_month_plus() }

  public val new: StringResource by 
      lazy { init_new() }

  public val new_expense: StringResource by 
      lazy { init_new_expense() }

  public val new_group: StringResource by 
      lazy { init_new_group() }

  public val no_contact_found: StringResource by 
      lazy { init_no_contact_found() }

  public val no_image_group_cd: StringResource by 
      lazy { init_no_image_group_cd() }

  public val no_image_user: StringResource by 
      lazy { init_no_image_user() }

  public val no_selected_user: StringResource by 
      lazy { init_no_selected_user() }

  public val non_distr_cd: StringResource by 
      lazy { init_non_distr_cd() }

  public val not_allowed_to_update_expense: StringResource by 
      lazy { init_not_allowed_to_update_expense() }

  public val not_found: StringResource by 
      lazy { init_not_found() }

  public val not_participating: StringResource by 
      lazy { init_not_participating() }

  public val not_settled: StringResource by 
      lazy { init_not_settled() }

  public val not_split: StringResource by 
      lazy { init_not_split() }

  public val not_split_amount: StringResource by 
      lazy { init_not_split_amount() }

  public val offer_badge: StringResource by 
      lazy { init_offer_badge() }

  public val offline_balances_shown: StringResource by 
      lazy { init_offline_balances_shown() }

  public val okey: StringResource by 
      lazy { init_okey() }

  public val only_this_transaction: StringResource by 
      lazy { init_only_this_transaction() }

  public val only_you_can_update_expense: StringResource by 
      lazy { init_only_you_can_update_expense() }

  public val or: StringResource by 
      lazy { init_or() }

  public val other: StringResource by 
      lazy { init_other() }

  public val paid_by: StringResource by 
      lazy { init_paid_by() }

  public val paid_by_me: StringResource by 
      lazy { init_paid_by_me() }

  public val paid_by_person: StringResource by 
      lazy { init_paid_by_person() }

  public val paid_by_you: StringResource by 
      lazy { init_paid_by_you() }

  public val per_month: StringResource by 
      lazy { init_per_month() }

  public val per_week: StringResource by 
      lazy { init_per_week() }

  public val per_year: StringResource by 
      lazy { init_per_year() }

  public val personal_expense: StringResource by 
      lazy { init_personal_expense() }

  public val plus_active: StringResource by 
      lazy { init_plus_active() }

  public val plus_badge: StringResource by 
      lazy { init_plus_badge() }

  public val plus_badge_benefits: StringResource by 
      lazy { init_plus_badge_benefits() }

  public val plus_feature_cats_cta: StringResource by 
      lazy { init_plus_feature_cats_cta() }

  public val plus_feature_cats_descr_full: StringResource by 
      lazy { init_plus_feature_cats_descr_full() }

  public val plus_feature_cats_descr_short: StringResource by 
      lazy { init_plus_feature_cats_descr_short() }

  public val plus_feature_cats_title: StringResource by 
      lazy { init_plus_feature_cats_title() }

  public val plus_feature_currencies_descr_full: StringResource by 
      lazy { init_plus_feature_currencies_descr_full() }

  public val plus_feature_currencies_descr_short: StringResource by 
      lazy { init_plus_feature_currencies_descr_short() }

  public val plus_feature_currencies_title: StringResource by 
      lazy { init_plus_feature_currencies_title() }

  public val plus_feature_images_descr_full: StringResource by 
      lazy { init_plus_feature_images_descr_full() }

  public val plus_feature_images_descr_short: StringResource by 
      lazy { init_plus_feature_images_descr_short() }

  public val plus_feature_images_title: StringResource by 
      lazy { init_plus_feature_images_title() }

  public val plus_feature_more_descr: StringResource by 
      lazy { init_plus_feature_more_descr() }

  public val plus_feature_more_title: StringResource by 
      lazy { init_plus_feature_more_title() }

  public val plus_feature_noads_descr_full: StringResource by 
      lazy { init_plus_feature_noads_descr_full() }

  public val plus_feature_noads_descr_short: StringResource by 
      lazy { init_plus_feature_noads_descr_short() }

  public val plus_feature_noads_title: StringResource by 
      lazy { init_plus_feature_noads_title() }

  public val plus_feature_protect_descr_full: StringResource by 
      lazy { init_plus_feature_protect_descr_full() }

  public val plus_feature_protect_descr_short: StringResource by 
      lazy { init_plus_feature_protect_descr_short() }

  public val plus_feature_protect_title: StringResource by 
      lazy { init_plus_feature_protect_title() }

  public val plus_feature_quick_add_descr_full: StringResource by 
      lazy { init_plus_feature_quick_add_descr_full() }

  public val plus_feature_quick_add_descr_short: StringResource by 
      lazy { init_plus_feature_quick_add_descr_short() }

  public val plus_feature_quick_add_title: StringResource by 
      lazy { init_plus_feature_quick_add_title() }

  public val plus_feature_single_settle_descr_full: StringResource by 
      lazy { init_plus_feature_single_settle_descr_full() }

  public val plus_feature_single_settle_descr_short: StringResource by 
      lazy { init_plus_feature_single_settle_descr_short() }

  public val plus_feature_single_settle_title: StringResource by 
      lazy { init_plus_feature_single_settle_title() }

  public val plus_feature_suggest_descr_full: StringResource by 
      lazy { init_plus_feature_suggest_descr_full() }

  public val plus_feature_suggest_descr_short: StringResource by 
      lazy { init_plus_feature_suggest_descr_short() }

  public val plus_feature_suggest_title: StringResource by 
      lazy { init_plus_feature_suggest_title() }

  public val plus_subs_title: StringResource by 
      lazy { init_plus_subs_title() }

  public val privacy_policy: StringResource by 
      lazy { init_privacy_policy() }

  public val problems_encountered: StringResource by 
      lazy { init_problems_encountered() }

  public val profile: StringResource by 
      lazy { init_profile() }

  public val profile_under_construction: StringResource by 
      lazy { init_profile_under_construction() }

  public val protect_expense: StringResource by 
      lazy { init_protect_expense() }

  public val protect_expense_editing: StringResource by 
      lazy { init_protect_expense_editing() }

  public val protected_expense: StringResource by 
      lazy { init_protected_expense() }

  public val protected_feature: StringResource by 
      lazy { init_protected_feature() }

  public val quick_add: StringResource by 
      lazy { init_quick_add() }

  public val quick_add_empty: StringResource by 
      lazy { init_quick_add_empty() }

  public val quick_split: StringResource by 
      lazy { init_quick_split() }

  public val quick_split_total_participants: StringResource by 
      lazy { init_quick_split_total_participants() }

  public val quick_split_turn: StringResource by 
      lazy { init_quick_split_turn() }

  public val recalculate: StringResource by 
      lazy { init_recalculate() }

  public val recalculating_backend: StringResource by 
      lazy { init_recalculating_backend() }

  public val remove_share: StringResource by 
      lazy { init_remove_share() }

  public val retry: StringResource by 
      lazy { init_retry() }

  public val save: StringResource by 
      lazy { init_save() }

  public val search_contact: StringResource by 
      lazy { init_search_contact() }

  public val search_currency: StringResource by 
      lazy { init_search_currency() }

  public val select_currency: StringResource by 
      lazy { init_select_currency() }

  public val select_payer_cd: StringResource by 
      lazy { init_select_payer_cd() }

  public val settings: StringResource by 
      lazy { init_settings() }

  public val settle_all_balances: StringResource by 
      lazy { init_settle_all_balances() }

  public val settle_balances: StringResource by 
      lazy { init_settle_balances() }

  public val settled: StringResource by 
      lazy { init_settled() }

  public val settled_all_expenses: StringResource by 
      lazy { init_settled_all_expenses() }

  public val settled_message: StringResource by 
      lazy { init_settled_message() }

  public val share_group: StringResource by 
      lazy { init_share_group() }

  public val share_link_copied: StringResource by 
      lazy { init_share_link_copied() }

  public val share_subject: StringResource by 
      lazy { init_share_subject() }

  public val shares: StringResource by 
      lazy { init_shares() }

  public val should_pay_to: StringResource by 
      lazy { init_should_pay_to() }

  public val start_type_creat_contact: StringResource by 
      lazy { init_start_type_creat_contact() }

  public val submit_feedback_cd: StringResource by 
      lazy { init_submit_feedback_cd() }

  public val subscribe_for: StringResource by 
      lazy { init_subscribe_for() }

  public val subscribe_plus: StringResource by 
      lazy { init_subscribe_plus() }

  public val subscribe_via_mobile_app: StringResource by 
      lazy { init_subscribe_via_mobile_app() }

  public val suggest_split: StringResource by 
      lazy { init_suggest_split() }

  public val sync_in_progress: StringResource by 
      lazy { init_sync_in_progress() }

  public val system_theme: StringResource by 
      lazy { init_system_theme() }

  public val terms_conditions: StringResource by 
      lazy { init_terms_conditions() }

  public val text_its_me: StringResource by 
      lazy { init_text_its_me() }

  public val text_loading: StringResource by 
      lazy { init_text_loading() }

  public val title_leave_group: StringResource by 
      lazy { init_title_leave_group() }

  public val title_should_be_filled: StringResource by 
      lazy { init_title_should_be_filled() }

  public val transactions: StringResource by 
      lazy { init_transactions() }

  public val try_days: StringResource by 
      lazy { init_try_days() }

  public val try_month_plus: StringResource by 
      lazy { init_try_month_plus() }

  public val try_plus: StringResource by 
      lazy { init_try_plus() }

  public val try_week_plus: StringResource by 
      lazy { init_try_week_plus() }

  public val try_year_plus: StringResource by 
      lazy { init_try_year_plus() }

  public val tutorial_info: StringResource by 
      lazy { init_tutorial_info() }

  public val tutorial_step_add_expense_description: StringResource by 
      lazy { init_tutorial_step_add_expense_description() }

  public val tutorial_step_add_expense_title: StringResource by 
      lazy { init_tutorial_step_add_expense_title() }

  public val tutorial_step_add_participant_description: StringResource by 
      lazy { init_tutorial_step_add_participant_description() }

  public val tutorial_step_add_participant_title: StringResource by 
      lazy { init_tutorial_step_add_participant_title() }

  public val tutorial_step_balances_tab_description: StringResource by 
      lazy { init_tutorial_step_balances_tab_description() }

  public val tutorial_step_balances_tab_title: StringResource by 
      lazy { init_tutorial_step_balances_tab_title() }

  public val tutorial_step_check_balances_description: StringResource by 
      lazy { init_tutorial_step_check_balances_description() }

  public val tutorial_step_check_balances_title: StringResource by 
      lazy { init_tutorial_step_check_balances_title() }

  public val tutorial_step_choose_payer: StringResource by 
      lazy { init_tutorial_step_choose_payer() }

  public val tutorial_step_choose_payer_description: StringResource by 
      lazy { init_tutorial_step_choose_payer_description() }

  public val tutorial_step_confirm_selection_description: StringResource by 
      lazy { init_tutorial_step_confirm_selection_description() }

  public val tutorial_step_confirm_selection_title: StringResource by 
      lazy { init_tutorial_step_confirm_selection_title() }

  public val tutorial_step_create_group_description: StringResource by 
      lazy { init_tutorial_step_create_group_description() }

  public val tutorial_step_create_group_title: StringResource by 
      lazy { init_tutorial_step_create_group_title() }

  public val tutorial_step_date_currency_description: StringResource by 
      lazy { init_tutorial_step_date_currency_description() }

  public val tutorial_step_date_currency_setup: StringResource by 
      lazy { init_tutorial_step_date_currency_setup() }

  public val tutorial_step_expense_title: StringResource by 
      lazy { init_tutorial_step_expense_title() }

  public val tutorial_step_expense_title_description: StringResource by 
      lazy { init_tutorial_step_expense_title_description() }

  public val tutorial_step_input_name_description: StringResource by 
      lazy { init_tutorial_step_input_name_description() }

  public val tutorial_step_input_name_title: StringResource by 
      lazy { init_tutorial_step_input_name_title() }

  public val tutorial_step_invite_friends_description: StringResource by 
      lazy { init_tutorial_step_invite_friends_description() }

  public val tutorial_step_invite_friends_title: StringResource by 
      lazy { init_tutorial_step_invite_friends_title() }

  public val tutorial_step_put_amount: StringResource by 
      lazy { init_tutorial_step_put_amount() }

  public val tutorial_step_put_amount_description: StringResource by 
      lazy { init_tutorial_step_put_amount_description() }

  public val tutorial_step_save_expense: StringResource by 
      lazy { init_tutorial_step_save_expense() }

  public val tutorial_step_save_expense_description: StringResource by 
      lazy { init_tutorial_step_save_expense_description() }

  public val tutorial_step_select_user_description: StringResource by 
      lazy { init_tutorial_step_select_user_description() }

  public val tutorial_step_select_user_title: StringResource by 
      lazy { init_tutorial_step_select_user_title() }

  public val tutorial_step_settle_up_description: StringResource by 
      lazy { init_tutorial_step_settle_up_description() }

  public val tutorial_step_settle_up_title: StringResource by 
      lazy { init_tutorial_step_settle_up_title() }

  public val undistributed: StringResource by 
      lazy { init_undistributed() }

  public val unlock_all_features: StringResource by 
      lazy { init_unlock_all_features() }

  public val user_already_in_group: StringResource by 
      lazy { init_user_already_in_group() }

  public val users_count: StringResource by 
      lazy { init_users_count() }

  public val valid_subs: StringResource by 
      lazy { init_valid_subs() }

  public val valid_subs_days_more: StringResource by 
      lazy { init_valid_subs_days_more() }

  public val valid_subs_hours_more: StringResource by 
      lazy { init_valid_subs_hours_more() }

  public val we_split_in: StringResource by 
      lazy { init_we_split_in() }

  public val web_billing_in_progress: StringResource by 
      lazy { init_web_billing_in_progress() }

  public val week_plus: StringResource by 
      lazy { init_week_plus() }

  public val wesplit_logo: StringResource by 
      lazy { init_wesplit_logo() }

  public val what_you_get_with: StringResource by 
      lazy { init_what_you_get_with() }

  public val with_me: StringResource by 
      lazy { init_with_me() }

  public val year_plus: StringResource by 
      lazy { init_year_plus() }

  public val you: StringResource by 
      lazy { init_you() }

  public val you_borrowed: StringResource by 
      lazy { init_you_borrowed() }

  public val you_have_plus: StringResource by 
      lazy { init_you_have_plus() }

  public val you_lent: StringResource by 
      lazy { init_you_lent() }

  public val your_share: StringResource by 
      lazy { init_your_share() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("add_expense_to_group", CommonMainString0.add_expense_to_group)
  map.put("add_group_cd", CommonMainString0.add_group_cd)
  map.put("add_group_image", CommonMainString0.add_group_image)
  map.put("add_share", CommonMainString0.add_share)
  map.put("add_user_to_group", CommonMainString0.add_user_to_group)
  map.put("all_participants", CommonMainString0.all_participants)
  map.put("already_settled", CommonMainString0.already_settled)
  map.put("amount", CommonMainString0.amount)
  map.put("amounts", CommonMainString0.amounts)
  map.put("andspaces", CommonMainString0.andspaces)
  map.put("app_name", CommonMainString0.app_name)
  map.put("app_version", CommonMainString0.app_version)
  map.put("at_date", CommonMainString0.at_date)
  map.put("available_in_mobile_app", CommonMainString0.available_in_mobile_app)
  map.put("back", CommonMainString0.back)
  map.put("back_btn_cd", CommonMainString0.back_btn_cd)
  map.put("balances", CommonMainString0.balances)
  map.put("balances_explanation", CommonMainString0.balances_explanation)
  map.put("benefits", CommonMainString0.benefits)
  map.put("best_offer_badge", CommonMainString0.best_offer_badge)
  map.put("calculate_best_way_settle", CommonMainString0.calculate_best_way_settle)
  map.put("cancel", CommonMainString0.cancel)
  map.put("category_bank", CommonMainString0.category_bank)
  map.put("category_beauty", CommonMainString0.category_beauty)
  map.put("category_books", CommonMainString0.category_books)
  map.put("category_car", CommonMainString0.category_car)
  map.put("category_cash", CommonMainString0.category_cash)
  map.put("category_cleaning", CommonMainString0.category_cleaning)
  map.put("category_clothes", CommonMainString0.category_clothes)
  map.put("category_coffee", CommonMainString0.category_coffee)
  map.put("category_concert", CommonMainString0.category_concert)
  map.put("category_crypto", CommonMainString0.category_crypto)
  map.put("category_electricity", CommonMainString0.category_electricity)
  map.put("category_entertainment", CommonMainString0.category_entertainment)
  map.put("category_fast_food", CommonMainString0.category_fast_food)
  map.put("category_fee", CommonMainString0.category_fee)
  map.put("category_flight", CommonMainString0.category_flight)
  map.put("category_food_drink", CommonMainString0.category_food_drink)
  map.put("category_furnishing", CommonMainString0.category_furnishing)
  map.put("category_garbage", CommonMainString0.category_garbage)
  map.put("category_gifts", CommonMainString0.category_gifts)
  map.put("category_groceries", CommonMainString0.category_groceries)
  map.put("category_health", CommonMainString0.category_health)
  map.put("category_health_beauty", CommonMainString0.category_health_beauty)
  map.put("category_hobby", CommonMainString0.category_hobby)
  map.put("category_housing", CommonMainString0.category_housing)
  map.put("category_internet", CommonMainString0.category_internet)
  map.put("category_magic", CommonMainString0.category_magic)
  map.put("category_money", CommonMainString0.category_money)
  map.put("category_movie", CommonMainString0.category_movie)
  map.put("category_none", CommonMainString0.category_none)
  map.put("category_parking", CommonMainString0.category_parking)
  map.put("category_public", CommonMainString0.category_public)
  map.put("category_recycling", CommonMainString0.category_recycling)
  map.put("category_rent", CommonMainString0.category_rent)
  map.put("category_repair", CommonMainString0.category_repair)
  map.put("category_restaurant", CommonMainString0.category_restaurant)
  map.put("category_security", CommonMainString0.category_security)
  map.put("category_shoes", CommonMainString0.category_shoes)
  map.put("category_shopping", CommonMainString0.category_shopping)
  map.put("category_sport", CommonMainString0.category_sport)
  map.put("category_sport_event", CommonMainString0.category_sport_event)
  map.put("category_tax", CommonMainString0.category_tax)
  map.put("category_taxi", CommonMainString0.category_taxi)
  map.put("category_technology", CommonMainString0.category_technology)
  map.put("category_tolls", CommonMainString0.category_tolls)
  map.put("category_transport_travel", CommonMainString0.category_transport_travel)
  map.put("category_transportation", CommonMainString0.category_transportation)
  map.put("category_utilities", CommonMainString0.category_utilities)
  map.put("category_water", CommonMainString0.category_water)
  map.put("change_color_mode", CommonMainString0.change_color_mode)
  map.put("change_theme", CommonMainString0.change_theme)
  map.put("close_picker", CommonMainString0.close_picker)
  map.put("collapsed", CommonMainString0.collapsed)
  map.put("confirm_delete_account", CommonMainString0.confirm_delete_account)
  map.put("confirm_delete_expense_message", CommonMainString0.confirm_delete_expense_message)
  map.put("confirm_mark_all_settled", CommonMainString0.confirm_mark_all_settled)
  map.put("confirm_no_wait", CommonMainString0.confirm_no_wait)
  map.put("confirm_yes_delete", CommonMainString0.confirm_yes_delete)
  map.put("confirm_yes_join", CommonMainString0.confirm_yes_join)
  map.put("confirm_yes_leave", CommonMainString0.confirm_yes_leave)
  map.put("confirm_yes_settle", CommonMainString0.confirm_yes_settle)
  map.put("contacts_in_book", CommonMainString0.contacts_in_book)
  map.put("contacts_in_wesplit", CommonMainString0.contacts_in_wesplit)
  map.put("create", CommonMainString0.create)
  map.put("create_new_contact", CommonMainString0.create_new_contact)
  map.put("current_group_participants", CommonMainString0.current_group_participants)
  map.put("dark_theme", CommonMainString0.dark_theme)
  map.put("data_syncing", CommonMainString0.data_syncing)
  map.put("delete_account", CommonMainString0.delete_account)
  map.put("delete_account_confirmation", CommonMainString0.delete_account_confirmation)
  map.put("delete_account_from_wesplit", CommonMainString0.delete_account_from_wesplit)
  map.put("delete_expense", CommonMainString0.delete_expense)
  map.put("delete_expense_group", CommonMainString0.delete_expense_group)
  map.put("delete_expense_title", CommonMainString0.delete_expense_title)
  map.put("detailed", CommonMainString0.detailed)
  map.put("download_mobile_app", CommonMainString0.download_mobile_app)
  map.put("edit_group", CommonMainString0.edit_group)
  map.put("empty_group_cd", CommonMainString0.empty_group_cd)
  map.put("empty_transaction_description", CommonMainString0.empty_transaction_description)
  map.put("empty_transactions_cd", CommonMainString0.empty_transactions_cd)
  map.put("enter_promocode", CommonMainString0.enter_promocode)
  map.put("enter_valid_promocode", CommonMainString0.enter_valid_promocode)
  map.put("equal", CommonMainString0.equal)
  map.put("error", CommonMainString0.error)
  map.put("error_encountered_problems", CommonMainString0.error_encountered_problems)
  map.put("error_fetching_plans", CommonMainString0.error_fetching_plans)
  map.put("expanded", CommonMainString0.expanded)
  map.put("expense_title", CommonMainString0.expense_title)
  map.put("feedback", CommonMainString0.feedback)
  map.put("fetch_available_plans", CommonMainString0.fetch_available_plans)
  map.put("forget_group", CommonMainString0.forget_group)
  map.put("forgive_and_forget", CommonMainString0.forgive_and_forget)
  map.put("free", CommonMainString0.free)
  map.put("fx_single_currency", CommonMainString0.fx_single_currency)
  map.put("get_app", CommonMainString0.get_app)
  map.put("get_mobile_app", CommonMainString0.get_mobile_app)
  map.put("get_plus_instant_balances", CommonMainString0.get_plus_instant_balances)
  map.put("gets_back", CommonMainString0.gets_back)
  map.put("go_to_privacy", CommonMainString0.go_to_privacy)
  map.put("go_to_terms_conditions", CommonMainString0.go_to_terms_conditions)
  map.put("got_it", CommonMainString0.got_it)
  map.put("grant_permission", CommonMainString0.grant_permission)
  map.put("group_detailed_empty_description", CommonMainString0.group_detailed_empty_description)
  map.put("group_list_empty_description_authorized",
      CommonMainString0.group_list_empty_description_authorized)
  map.put("group_list_empty_description_unregistered",
      CommonMainString0.group_list_empty_description_unregistered)
  map.put("group_list_title", CommonMainString0.group_list_title)
  map.put("group_name", CommonMainString0.group_name)
  map.put("groups", CommonMainString0.groups)
  map.put("image_description_ai", CommonMainString0.image_description_ai)
  map.put("info_sign", CommonMainString0.info_sign)
  map.put("invite_user", CommonMainString0.invite_user)
  map.put("join_agree", CommonMainString0.join_agree)
  map.put("join_group", CommonMainString0.join_group)
  map.put("join_group_as_new_participant", CommonMainString0.join_group_as_new_participant)
  map.put("join_group_as_user", CommonMainString0.join_group_as_user)
  map.put("join_new_participant", CommonMainString0.join_new_participant)
  map.put("label_apply", CommonMainString0.label_apply)
  map.put("label_code", CommonMainString0.label_code)
  map.put("leave_group", CommonMainString0.leave_group)
  map.put("leave_group_confirmation", CommonMainString0.leave_group_confirmation)
  map.put("light_theme", CommonMainString0.light_theme)
  map.put("loading", CommonMainString0.loading)
  map.put("locked_feature", CommonMainString0.locked_feature)
  map.put("login", CommonMainString0.login)
  map.put("login_anon", CommonMainString0.login_anon)
  map.put("login_button_cd", CommonMainString0.login_button_cd)
  map.put("login_to_create_descr", CommonMainString0.login_to_create_descr)
  map.put("login_to_protect_expense", CommonMainString0.login_to_protect_expense)
  map.put("login_with_apple_cd", CommonMainString0.login_with_apple_cd)
  map.put("login_with_google_cd", CommonMainString0.login_with_google_cd)
  map.put("logout", CommonMainString0.logout)
  map.put("mark_as_non_settled", CommonMainString0.mark_as_non_settled)
  map.put("mark_as_settled", CommonMainString0.mark_as_settled)
  map.put("month_plus", CommonMainString0.month_plus)
  map.put("new", CommonMainString0.new)
  map.put("new_expense", CommonMainString0.new_expense)
  map.put("new_group", CommonMainString0.new_group)
  map.put("no_contact_found", CommonMainString0.no_contact_found)
  map.put("no_image_group_cd", CommonMainString0.no_image_group_cd)
  map.put("no_image_user", CommonMainString0.no_image_user)
  map.put("no_selected_user", CommonMainString0.no_selected_user)
  map.put("non_distr_cd", CommonMainString0.non_distr_cd)
  map.put("not_allowed_to_update_expense", CommonMainString0.not_allowed_to_update_expense)
  map.put("not_found", CommonMainString0.not_found)
  map.put("not_participating", CommonMainString0.not_participating)
  map.put("not_settled", CommonMainString0.not_settled)
  map.put("not_split", CommonMainString0.not_split)
  map.put("not_split_amount", CommonMainString0.not_split_amount)
  map.put("offer_badge", CommonMainString0.offer_badge)
  map.put("offline_balances_shown", CommonMainString0.offline_balances_shown)
  map.put("okey", CommonMainString0.okey)
  map.put("only_this_transaction", CommonMainString0.only_this_transaction)
  map.put("only_you_can_update_expense", CommonMainString0.only_you_can_update_expense)
  map.put("or", CommonMainString0.or)
  map.put("other", CommonMainString0.other)
  map.put("paid_by", CommonMainString0.paid_by)
  map.put("paid_by_me", CommonMainString0.paid_by_me)
  map.put("paid_by_person", CommonMainString0.paid_by_person)
  map.put("paid_by_you", CommonMainString0.paid_by_you)
  map.put("per_month", CommonMainString0.per_month)
  map.put("per_week", CommonMainString0.per_week)
  map.put("per_year", CommonMainString0.per_year)
  map.put("personal_expense", CommonMainString0.personal_expense)
  map.put("plus_active", CommonMainString0.plus_active)
  map.put("plus_badge", CommonMainString0.plus_badge)
  map.put("plus_badge_benefits", CommonMainString0.plus_badge_benefits)
  map.put("plus_feature_cats_cta", CommonMainString0.plus_feature_cats_cta)
  map.put("plus_feature_cats_descr_full", CommonMainString0.plus_feature_cats_descr_full)
  map.put("plus_feature_cats_descr_short", CommonMainString0.plus_feature_cats_descr_short)
  map.put("plus_feature_cats_title", CommonMainString0.plus_feature_cats_title)
  map.put("plus_feature_currencies_descr_full",
      CommonMainString0.plus_feature_currencies_descr_full)
  map.put("plus_feature_currencies_descr_short",
      CommonMainString0.plus_feature_currencies_descr_short)
  map.put("plus_feature_currencies_title", CommonMainString0.plus_feature_currencies_title)
  map.put("plus_feature_images_descr_full", CommonMainString0.plus_feature_images_descr_full)
  map.put("plus_feature_images_descr_short", CommonMainString0.plus_feature_images_descr_short)
  map.put("plus_feature_images_title", CommonMainString0.plus_feature_images_title)
  map.put("plus_feature_more_descr", CommonMainString0.plus_feature_more_descr)
  map.put("plus_feature_more_title", CommonMainString0.plus_feature_more_title)
  map.put("plus_feature_noads_descr_full", CommonMainString0.plus_feature_noads_descr_full)
  map.put("plus_feature_noads_descr_short", CommonMainString0.plus_feature_noads_descr_short)
  map.put("plus_feature_noads_title", CommonMainString0.plus_feature_noads_title)
  map.put("plus_feature_protect_descr_full", CommonMainString0.plus_feature_protect_descr_full)
  map.put("plus_feature_protect_descr_short", CommonMainString0.plus_feature_protect_descr_short)
  map.put("plus_feature_protect_title", CommonMainString0.plus_feature_protect_title)
  map.put("plus_feature_quick_add_descr_full", CommonMainString0.plus_feature_quick_add_descr_full)
  map.put("plus_feature_quick_add_descr_short",
      CommonMainString0.plus_feature_quick_add_descr_short)
  map.put("plus_feature_quick_add_title", CommonMainString0.plus_feature_quick_add_title)
  map.put("plus_feature_single_settle_descr_full",
      CommonMainString0.plus_feature_single_settle_descr_full)
  map.put("plus_feature_single_settle_descr_short",
      CommonMainString0.plus_feature_single_settle_descr_short)
  map.put("plus_feature_single_settle_title", CommonMainString0.plus_feature_single_settle_title)
  map.put("plus_feature_suggest_descr_full", CommonMainString0.plus_feature_suggest_descr_full)
  map.put("plus_feature_suggest_descr_short", CommonMainString0.plus_feature_suggest_descr_short)
  map.put("plus_feature_suggest_title", CommonMainString0.plus_feature_suggest_title)
  map.put("plus_subs_title", CommonMainString0.plus_subs_title)
  map.put("privacy_policy", CommonMainString0.privacy_policy)
  map.put("problems_encountered", CommonMainString0.problems_encountered)
  map.put("profile", CommonMainString0.profile)
  map.put("profile_under_construction", CommonMainString0.profile_under_construction)
  map.put("protect_expense", CommonMainString0.protect_expense)
  map.put("protect_expense_editing", CommonMainString0.protect_expense_editing)
  map.put("protected_expense", CommonMainString0.protected_expense)
  map.put("protected_feature", CommonMainString0.protected_feature)
  map.put("quick_add", CommonMainString0.quick_add)
  map.put("quick_add_empty", CommonMainString0.quick_add_empty)
  map.put("quick_split", CommonMainString0.quick_split)
  map.put("quick_split_total_participants", CommonMainString0.quick_split_total_participants)
  map.put("quick_split_turn", CommonMainString0.quick_split_turn)
  map.put("recalculate", CommonMainString0.recalculate)
  map.put("recalculating_backend", CommonMainString0.recalculating_backend)
  map.put("remove_share", CommonMainString0.remove_share)
  map.put("retry", CommonMainString0.retry)
  map.put("save", CommonMainString0.save)
  map.put("search_contact", CommonMainString0.search_contact)
  map.put("search_currency", CommonMainString0.search_currency)
  map.put("select_currency", CommonMainString0.select_currency)
  map.put("select_payer_cd", CommonMainString0.select_payer_cd)
  map.put("settings", CommonMainString0.settings)
  map.put("settle_all_balances", CommonMainString0.settle_all_balances)
  map.put("settle_balances", CommonMainString0.settle_balances)
  map.put("settled", CommonMainString0.settled)
  map.put("settled_all_expenses", CommonMainString0.settled_all_expenses)
  map.put("settled_message", CommonMainString0.settled_message)
  map.put("share_group", CommonMainString0.share_group)
  map.put("share_link_copied", CommonMainString0.share_link_copied)
  map.put("share_subject", CommonMainString0.share_subject)
  map.put("shares", CommonMainString0.shares)
  map.put("should_pay_to", CommonMainString0.should_pay_to)
  map.put("start_type_creat_contact", CommonMainString0.start_type_creat_contact)
  map.put("submit_feedback_cd", CommonMainString0.submit_feedback_cd)
  map.put("subscribe_for", CommonMainString0.subscribe_for)
  map.put("subscribe_plus", CommonMainString0.subscribe_plus)
  map.put("subscribe_via_mobile_app", CommonMainString0.subscribe_via_mobile_app)
  map.put("suggest_split", CommonMainString0.suggest_split)
  map.put("sync_in_progress", CommonMainString0.sync_in_progress)
  map.put("system_theme", CommonMainString0.system_theme)
  map.put("terms_conditions", CommonMainString0.terms_conditions)
  map.put("text_its_me", CommonMainString0.text_its_me)
  map.put("text_loading", CommonMainString0.text_loading)
  map.put("title_leave_group", CommonMainString0.title_leave_group)
  map.put("title_should_be_filled", CommonMainString0.title_should_be_filled)
  map.put("transactions", CommonMainString0.transactions)
  map.put("try_days", CommonMainString0.try_days)
  map.put("try_month_plus", CommonMainString0.try_month_plus)
  map.put("try_plus", CommonMainString0.try_plus)
  map.put("try_week_plus", CommonMainString0.try_week_plus)
  map.put("try_year_plus", CommonMainString0.try_year_plus)
  map.put("tutorial_info", CommonMainString0.tutorial_info)
  map.put("tutorial_step_add_expense_description",
      CommonMainString0.tutorial_step_add_expense_description)
  map.put("tutorial_step_add_expense_title", CommonMainString0.tutorial_step_add_expense_title)
  map.put("tutorial_step_add_participant_description",
      CommonMainString0.tutorial_step_add_participant_description)
  map.put("tutorial_step_add_participant_title",
      CommonMainString0.tutorial_step_add_participant_title)
  map.put("tutorial_step_balances_tab_description",
      CommonMainString0.tutorial_step_balances_tab_description)
  map.put("tutorial_step_balances_tab_title", CommonMainString0.tutorial_step_balances_tab_title)
  map.put("tutorial_step_check_balances_description",
      CommonMainString0.tutorial_step_check_balances_description)
  map.put("tutorial_step_check_balances_title",
      CommonMainString0.tutorial_step_check_balances_title)
  map.put("tutorial_step_choose_payer", CommonMainString0.tutorial_step_choose_payer)
  map.put("tutorial_step_choose_payer_description",
      CommonMainString0.tutorial_step_choose_payer_description)
  map.put("tutorial_step_confirm_selection_description",
      CommonMainString0.tutorial_step_confirm_selection_description)
  map.put("tutorial_step_confirm_selection_title",
      CommonMainString0.tutorial_step_confirm_selection_title)
  map.put("tutorial_step_create_group_description",
      CommonMainString0.tutorial_step_create_group_description)
  map.put("tutorial_step_create_group_title", CommonMainString0.tutorial_step_create_group_title)
  map.put("tutorial_step_date_currency_description",
      CommonMainString0.tutorial_step_date_currency_description)
  map.put("tutorial_step_date_currency_setup", CommonMainString0.tutorial_step_date_currency_setup)
  map.put("tutorial_step_expense_title", CommonMainString0.tutorial_step_expense_title)
  map.put("tutorial_step_expense_title_description",
      CommonMainString0.tutorial_step_expense_title_description)
  map.put("tutorial_step_input_name_description",
      CommonMainString0.tutorial_step_input_name_description)
  map.put("tutorial_step_input_name_title", CommonMainString0.tutorial_step_input_name_title)
  map.put("tutorial_step_invite_friends_description",
      CommonMainString0.tutorial_step_invite_friends_description)
  map.put("tutorial_step_invite_friends_title",
      CommonMainString0.tutorial_step_invite_friends_title)
  map.put("tutorial_step_put_amount", CommonMainString0.tutorial_step_put_amount)
  map.put("tutorial_step_put_amount_description",
      CommonMainString0.tutorial_step_put_amount_description)
  map.put("tutorial_step_save_expense", CommonMainString0.tutorial_step_save_expense)
  map.put("tutorial_step_save_expense_description",
      CommonMainString0.tutorial_step_save_expense_description)
  map.put("tutorial_step_select_user_description",
      CommonMainString0.tutorial_step_select_user_description)
  map.put("tutorial_step_select_user_title", CommonMainString0.tutorial_step_select_user_title)
  map.put("tutorial_step_settle_up_description",
      CommonMainString0.tutorial_step_settle_up_description)
  map.put("tutorial_step_settle_up_title", CommonMainString0.tutorial_step_settle_up_title)
  map.put("undistributed", CommonMainString0.undistributed)
  map.put("unlock_all_features", CommonMainString0.unlock_all_features)
  map.put("user_already_in_group", CommonMainString0.user_already_in_group)
  map.put("users_count", CommonMainString0.users_count)
  map.put("valid_subs", CommonMainString0.valid_subs)
  map.put("valid_subs_days_more", CommonMainString0.valid_subs_days_more)
  map.put("valid_subs_hours_more", CommonMainString0.valid_subs_hours_more)
  map.put("we_split_in", CommonMainString0.we_split_in)
  map.put("web_billing_in_progress", CommonMainString0.web_billing_in_progress)
  map.put("week_plus", CommonMainString0.week_plus)
  map.put("wesplit_logo", CommonMainString0.wesplit_logo)
  map.put("what_you_get_with", CommonMainString0.what_you_get_with)
  map.put("with_me", CommonMainString0.with_me)
  map.put("year_plus", CommonMainString0.year_plus)
  map.put("you", CommonMainString0.you)
  map.put("you_borrowed", CommonMainString0.you_borrowed)
  map.put("you_have_plus", CommonMainString0.you_have_plus)
  map.put("you_lent", CommonMainString0.you_lent)
  map.put("your_share", CommonMainString0.your_share)
}

internal val Res.string.add_expense_to_group: StringResource
  get() = CommonMainString0.add_expense_to_group

private fun init_add_expense_to_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_expense_to_group", "add_expense_to_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr", 59,
    88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr", 95,
    124),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 59, 76),
    )
)

internal val Res.string.add_group_cd: StringResource
  get() = CommonMainString0.add_group_cd

private fun init_add_group_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_group_cd", "add_group_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    148, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    220, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 136, 44),
    )
)

internal val Res.string.add_group_image: StringResource
  get() = CommonMainString0.add_group_image

private fun init_add_group_image(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_group_image", "add_group_image",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    197, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    293, 95),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 181, 43),
    )
)

internal val Res.string.add_share: StringResource
  get() = CommonMainString0.add_share

private fun init_add_share(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_share", "add_share",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    253, 93),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    389, 113),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 225, 73),
    )
)

internal val Res.string.add_user_to_group: StringResource
  get() = CommonMainString0.add_user_to_group

private fun init_add_user_to_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_user_to_group", "add_user_to_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    347, 77),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    503, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 299, 53),
    )
)

internal val Res.string.all_participants: StringResource
  get() = CommonMainString0.all_participants

private fun init_all_participants(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:all_participants", "all_participants",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    425, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    577, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 353, 48),
    )
)

internal val Res.string.already_settled: StringResource
  get() = CommonMainString0.already_settled

private fun init_already_settled(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:already_settled", "already_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    470, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    638, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 402, 43),
    )
)

internal val Res.string.amount: StringResource
  get() = CommonMainString0.amount

private fun init_amount(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:amount", "amount",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    546, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    726, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 474, 22),
    )
)

internal val Res.string.amounts: StringResource
  get() = CommonMainString0.amounts

private fun init_amounts(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:amounts", "amounts",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    518, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    694, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 446, 27),
    )
)

internal val Res.string.andspaces: StringResource
  get() = CommonMainString0.andspaces

private fun init_andspaces(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:andspaces", "andspaces",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    569, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    757, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 497, 25),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/keys.commonMain.cvr", 10, 28),
    )
)

internal val Res.string.app_version: StringResource
  get() = CommonMainString0.app_version

private fun init_app_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_version", "app_version",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/keys.commonMain.cvr", 39, 31),
    )
)

internal val Res.string.at_date: StringResource
  get() = CommonMainString0.at_date

private fun init_at_date(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:at_date", "at_date",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    595, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    783, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 523, 27),
    )
)

internal val Res.string.available_in_mobile_app: StringResource
  get() = CommonMainString0.available_in_mobile_app

private fun init_available_in_mobile_app(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:available_in_mobile_app", "available_in_mobile_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    623, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    811, 135),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 551, 75),
    )
)

internal val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    739, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    991, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 659, 20),
    )
)

internal val Res.string.back_btn_cd: StringResource
  get() = CommonMainString0.back_btn_cd

private fun init_back_btn_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back_btn_cd", "back_btn_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    703, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    947, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 627, 31),
    )
)

internal val Res.string.balances: StringResource
  get() = CommonMainString0.balances

private fun init_balances(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:balances", "balances",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1037, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1369, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 897, 28),
    )
)

internal val Res.string.balances_explanation: StringResource
  get() = CommonMainString0.balances_explanation

private fun init_balances_explanation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:balances_explanation", "balances_explanation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    764, 272),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1020, 348),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 680,
    216),
    )
)

internal val Res.string.benefits: StringResource
  get() = CommonMainString0.benefits

private fun init_benefits(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:benefits", "benefits",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1062, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1406, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 926, 28),
    )
)

internal val Res.string.best_offer_badge: StringResource
  get() = CommonMainString0.best_offer_badge

private fun init_best_offer_badge(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:best_offer_badge", "best_offer_badge",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1091, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1455, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 955, 48),
    )
)

internal val Res.string.calculate_best_way_settle: StringResource
  get() = CommonMainString0.calculate_best_way_settle

private fun init_calculate_best_way_settle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:calculate_best_way_settle", "calculate_best_way_settle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1144, 93),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1548, 125),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1004,
    73),
    )
)

internal val Res.string.cancel: StringResource
  get() = CommonMainString0.cancel

private fun init_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cancel", "cancel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1238, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1674, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1078,
    22),
    )
)

internal val Res.string.category_bank: StringResource
  get() = CommonMainString0.category_bank

private fun init_category_bank(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_bank", "category_bank",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1265, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1705, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1101,
    29),
    )
)

internal val Res.string.category_beauty: StringResource
  get() = CommonMainString0.category_beauty

private fun init_category_beauty(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_beauty", "category_beauty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1295, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1755, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1131,
    31),
    )
)

internal val Res.string.category_books: StringResource
  get() = CommonMainString0.category_books

private fun init_category_books(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_books", "category_books",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1335, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1799, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1163,
    30),
    )
)

internal val Res.string.category_car: StringResource
  get() = CommonMainString0.category_car

private fun init_category_car(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_car", "category_car",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1370, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1838, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1194,
    24),
    )
)

internal val Res.string.category_cash: StringResource
  get() = CommonMainString0.category_cash

private fun init_category_cash(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_cash", "category_cash",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1399, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1887, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1219,
    29),
    )
)

internal val Res.string.category_cleaning: StringResource
  get() = CommonMainString0.category_cleaning

private fun init_category_cleaning(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_cleaning", "category_cleaning",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1425, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1933, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1249,
    37),
    )
)

internal val Res.string.category_clothes: StringResource
  get() = CommonMainString0.category_clothes

private fun init_category_clothes(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_clothes", "category_clothes",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1463, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1975, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1287,
    36),
    )
)

internal val Res.string.category_coffee: StringResource
  get() = CommonMainString0.category_coffee

private fun init_category_coffee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_coffee", "category_coffee",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1500, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2016, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1324,
    31),
    )
)

internal val Res.string.category_concert: StringResource
  get() = CommonMainString0.category_concert

private fun init_category_concert(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_concert", "category_concert",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1532, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2052, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1356,
    36),
    )
)

internal val Res.string.category_crypto: StringResource
  get() = CommonMainString0.category_crypto

private fun init_category_crypto(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_crypto", "category_crypto",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1569, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2097, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1393,
    31),
    )
)

internal val Res.string.category_electricity: StringResource
  get() = CommonMainString0.category_electricity

private fun init_category_electricity(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_electricity", "category_electricity",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1601, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2153, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1425,
    48),
    )
)

internal val Res.string.category_entertainment: StringResource
  get() = CommonMainString0.category_entertainment

private fun init_category_entertainment(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_entertainment", "category_entertainment",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1642, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2218, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1474,
    50),
    )
)

internal val Res.string.category_fast_food: StringResource
  get() = CommonMainString0.category_fast_food

private fun init_category_fast_food(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_fast_food", "category_fast_food",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1689, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2281, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1525,
    38),
    )
)

internal val Res.string.category_fee: StringResource
  get() = CommonMainString0.category_fee

private fun init_category_fee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_fee", "category_fee",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1728, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2328, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1564,
    24),
    )
)

internal val Res.string.category_flight: StringResource
  get() = CommonMainString0.category_flight

private fun init_category_flight(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_flight", "category_flight",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1761, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2361, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1589,
    31),
    )
)

internal val Res.string.category_food_drink: StringResource
  get() = CommonMainString0.category_food_drink

private fun init_category_food_drink(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_food_drink", "category_food_drink",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1793, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2405, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1621,
    43),
    )
)

internal val Res.string.category_furnishing: StringResource
  get() = CommonMainString0.category_furnishing

private fun init_category_furnishing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_furnishing", "category_furnishing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1841, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2465, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1665,
    43),
    )
)

internal val Res.string.category_garbage: StringResource
  get() = CommonMainString0.category_garbage

private fun init_category_garbage(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_garbage", "category_garbage",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1885, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2521, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1709,
    36),
    )
)

internal val Res.string.category_gifts: StringResource
  get() = CommonMainString0.category_gifts

private fun init_category_gifts(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_gifts", "category_gifts",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1918, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2562, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1746,
    30),
    )
)

internal val Res.string.category_groceries: StringResource
  get() = CommonMainString0.category_groceries

private fun init_category_groceries(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_groceries", "category_groceries",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1953, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2605, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1777,
    38),
    )
)

internal val Res.string.category_health: StringResource
  get() = CommonMainString0.category_health

private fun init_category_health(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_health", "category_health",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2059, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2735, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1867,
    31),
    )
)

internal val Res.string.category_health_beauty: StringResource
  get() = CommonMainString0.category_health_beauty

private fun init_category_health_beauty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_health_beauty", "category_health_beauty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1996, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2656, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1816,
    50),
    )
)

internal val Res.string.category_hobby: StringResource
  get() = CommonMainString0.category_hobby

private fun init_category_hobby(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_hobby", "category_hobby",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2099, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2783, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1899,
    30),
    )
)

internal val Res.string.category_housing: StringResource
  get() = CommonMainString0.category_housing

private fun init_category_housing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_housing", "category_housing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2130, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2822, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1930,
    36),
    )
)

internal val Res.string.category_internet: StringResource
  get() = CommonMainString0.category_internet

private fun init_category_internet(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_internet", "category_internet",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2163, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2863, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1967,
    37),
    )
)

internal val Res.string.category_magic: StringResource
  get() = CommonMainString0.category_magic

private fun init_category_magic(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_magic", "category_magic",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2201, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2913, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2005,
    38),
    )
)

internal val Res.string.category_money: StringResource
  get() = CommonMainString0.category_money

private fun init_category_money(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_money", "category_money",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2240, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2976, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2044,
    42),
    )
)

internal val Res.string.category_movie: StringResource
  get() = CommonMainString0.category_movie

private fun init_category_movie(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_movie", "category_movie",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2279, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3019, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2087,
    30),
    )
)

internal val Res.string.category_none: StringResource
  get() = CommonMainString0.category_none

private fun init_category_none(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_none", "category_none",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2310, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3058, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2118,
    41),
    )
)

internal val Res.string.category_parking: StringResource
  get() = CommonMainString0.category_parking

private fun init_category_parking(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_parking", "category_parking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2352, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3116, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2160,
    36),
    )
)

internal val Res.string.category_public: StringResource
  get() = CommonMainString0.category_public

private fun init_category_public(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_public", "category_public",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2385, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3165, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2197,
    47),
    )
)

internal val Res.string.category_recycling: StringResource
  get() = CommonMainString0.category_recycling

private fun init_category_recycling(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_recycling", "category_recycling",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2445, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3225, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2245,
    38),
    )
)

internal val Res.string.category_rent: StringResource
  get() = CommonMainString0.category_rent

private fun init_category_rent(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_rent", "category_rent",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2484, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3284, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2284,
    29),
    )
)

internal val Res.string.category_repair: StringResource
  get() = CommonMainString0.category_repair

private fun init_category_repair(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_repair", "category_repair",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2514, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3322, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2314,
    31),
    )
)

internal val Res.string.category_restaurant: StringResource
  get() = CommonMainString0.category_restaurant

private fun init_category_restaurant(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_restaurant", "category_restaurant",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2550, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3362, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2346,
    43),
    )
)

internal val Res.string.category_security: StringResource
  get() = CommonMainString0.category_security

private fun init_category_security(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_security", "category_security",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2594, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3414, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2390,
    37),
    )
)

internal val Res.string.category_shoes: StringResource
  get() = CommonMainString0.category_shoes

private fun init_category_shoes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_shoes", "category_shoes",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2636, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3472, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2428,
    30),
    )
)

internal val Res.string.category_shopping: StringResource
  get() = CommonMainString0.category_shopping

private fun init_category_shopping(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_shopping", "category_shopping",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2667, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3511, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2459,
    37),
    )
)

internal val Res.string.category_sport: StringResource
  get() = CommonMainString0.category_sport

private fun init_category_sport(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_sport", "category_sport",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2758, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3634, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2542,
    30),
    )
)

internal val Res.string.category_sport_event: StringResource
  get() = CommonMainString0.category_sport_event

private fun init_category_sport_event(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_sport_event", "category_sport_event",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2705, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3557, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2497,
    44),
    )
)

internal val Res.string.category_tax: StringResource
  get() = CommonMainString0.category_tax

private fun init_category_tax(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_tax", "category_tax",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2819, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3711, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2603,
    36),
    )
)

internal val Res.string.category_taxi: StringResource
  get() = CommonMainString0.category_taxi

private fun init_category_taxi(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_taxi", "category_taxi",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2789, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3673, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2573,
    29),
    )
)

internal val Res.string.category_technology: StringResource
  get() = CommonMainString0.category_technology

private fun init_category_technology(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_technology", "category_technology",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2856, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3756, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2640,
    43),
    )
)

internal val Res.string.category_tolls: StringResource
  get() = CommonMainString0.category_tolls

private fun init_category_tolls(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_tolls", "category_tolls",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2900, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3812, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2684,
    30),
    )
)

internal val Res.string.category_transport_travel: StringResource
  get() = CommonMainString0.category_transport_travel

private fun init_category_transport_travel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_transport_travel", "category_transport_travel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2931, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3871, 81),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2715,
    57),
    )
)

internal val Res.string.category_transportation: StringResource
  get() = CommonMainString0.category_transportation

private fun init_category_transportation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_transportation", "category_transportation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2989, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3953, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2773,
    51),
    )
)

internal val Res.string.category_utilities: StringResource
  get() = CommonMainString0.category_utilities

private fun init_category_utilities(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:category_utilities", "category_utilities",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3033, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4009, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2825,
    38),
    )
)

internal val Res.string.category_water: StringResource
  get() = CommonMainString0.category_water

private fun init_category_water(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:category_water", "category_water",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3076, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4088, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2864,
    30),
    )
)

internal val Res.string.change_color_mode: StringResource
  get() = CommonMainString0.change_color_mode

private fun init_change_color_mode(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:change_color_mode", "change_color_mode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3107, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4123, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2895,
    49),
    )
)

internal val Res.string.change_theme: StringResource
  get() = CommonMainString0.change_theme

private fun init_change_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:change_theme", "change_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3157, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4209, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2945,
    36),
    )
)

internal val Res.string.close_picker: StringResource
  get() = CommonMainString0.close_picker

private fun init_close_picker(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:close_picker", "close_picker",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3198, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4266, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2982,
    40),
    )
)

internal val Res.string.collapsed: StringResource
  get() = CommonMainString0.collapsed

private fun init_collapsed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:collapsed", "collapsed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3251, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4311, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3023,
    29),
    )
)

internal val Res.string.confirm_delete_account: StringResource
  get() = CommonMainString0.confirm_delete_account

private fun init_confirm_delete_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_delete_account", "confirm_delete_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3293, 102),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4353, 162),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3053,
    114),
    )
)

internal val Res.string.confirm_delete_expense_message: StringResource
  get() = CommonMainString0.confirm_delete_expense_message

private fun init_confirm_delete_expense_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_delete_expense_message", "confirm_delete_expense_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3396, 114),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4516, 158),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3168,
    114),
    )
)

internal val Res.string.confirm_mark_all_settled: StringResource
  get() = CommonMainString0.confirm_mark_all_settled

private fun init_confirm_mark_all_settled(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_mark_all_settled", "confirm_mark_all_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3511, 128),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4675, 196),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3283,
    124),
    )
)

internal val Res.string.confirm_no_wait: StringResource
  get() = CommonMainString0.confirm_no_wait

private fun init_confirm_no_wait(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:confirm_no_wait", "confirm_no_wait",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3640, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4872, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3408,
    35),
    )
)

internal val Res.string.confirm_yes_delete: StringResource
  get() = CommonMainString0.confirm_yes_delete

private fun init_confirm_yes_delete(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_yes_delete", "confirm_yes_delete",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3680, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4932, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3444,
    42),
    )
)

internal val Res.string.confirm_yes_join: StringResource
  get() = CommonMainString0.confirm_yes_join

private fun init_confirm_yes_join(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_yes_join", "confirm_yes_join",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3723, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4987, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3487,
    36),
    )
)

internal val Res.string.confirm_yes_leave: StringResource
  get() = CommonMainString0.confirm_yes_leave

private fun init_confirm_yes_leave(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_yes_leave", "confirm_yes_leave",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3768, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5060, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3524,
    41),
    )
)

internal val Res.string.confirm_yes_settle: StringResource
  get() = CommonMainString0.confirm_yes_settle

private fun init_confirm_yes_settle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_yes_settle", "confirm_yes_settle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3814, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5110, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3566,
    42),
    )
)

internal val Res.string.contacts_in_book: StringResource
  get() = CommonMainString0.contacts_in_book

private fun init_contacts_in_book(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:contacts_in_book", "contacts_in_book",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3861, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5173, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3609,
    56),
    )
)

internal val Res.string.contacts_in_wesplit: StringResource
  get() = CommonMainString0.contacts_in_wesplit

private fun init_contacts_in_wesplit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:contacts_in_wesplit", "contacts_in_wesplit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3918, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5262, 111),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3666,
    75),
    )
)

internal val Res.string.create: StringResource
  get() = CommonMainString0.create

private fun init_create(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create", "create",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4069, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5469, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3809,
    22),
    )
)

internal val Res.string.create_new_contact: StringResource
  get() = CommonMainString0.create_new_contact

private fun init_create_new_contact(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_new_contact", "create_new_contact",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4002, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5374, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3742,
    66),
    )
)

internal val Res.string.current_group_participants: StringResource
  get() = CommonMainString0.current_group_participants

private fun init_current_group_participants(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:current_group_participants", "current_group_participants",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4096, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5504, 98),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3832,
    70),
    )
)

internal val Res.string.dark_theme: StringResource
  get() = CommonMainString0.dark_theme

private fun init_dark_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dark_theme", "dark_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4167, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5603, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3903,
    26),
    )
)

internal val Res.string.data_syncing: StringResource
  get() = CommonMainString0.data_syncing

private fun init_data_syncing(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:data_syncing", "data_syncing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4194, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5638, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3930,
    40),
    )
)

internal val Res.string.delete_account: StringResource
  get() = CommonMainString0.delete_account

private fun init_delete_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:delete_account", "delete_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4379, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5887, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4099,
    42),
    )
)

internal val Res.string.delete_account_confirmation: StringResource
  get() = CommonMainString0.delete_account_confirmation

private fun init_delete_account_confirmation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:delete_account_confirmation", "delete_account_confirmation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4251, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5719, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 3971,
    55),
    )
)

internal val Res.string.delete_account_from_wesplit: StringResource
  get() = CommonMainString0.delete_account_from_wesplit

private fun init_delete_account_from_wesplit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:delete_account_from_wesplit", "delete_account_from_wesplit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4307, 71),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5795, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4027,
    71),
    )
)

internal val Res.string.delete_expense: StringResource
  get() = CommonMainString0.delete_expense

private fun init_delete_expense(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:delete_expense", "delete_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4536, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6096, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4252,
    42),
    )
)

internal val Res.string.delete_expense_group: StringResource
  get() = CommonMainString0.delete_expense_group

private fun init_delete_expense_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:delete_expense_group", "delete_expense_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4422, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5950, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4142,
    64),
    )
)

internal val Res.string.delete_expense_title: StringResource
  get() = CommonMainString0.delete_expense_title

private fun init_delete_expense_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:delete_expense_title", "delete_expense_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4487, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6039, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4207,
    44),
    )
)

internal val Res.string.detailed: StringResource
  get() = CommonMainString0.detailed

private fun init_detailed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:detailed", "detailed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4583, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6155, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4295,
    28),
    )
)

internal val Res.string.download_mobile_app: StringResource
  get() = CommonMainString0.download_mobile_app

private fun init_download_mobile_app(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:download_mobile_app", "download_mobile_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4616, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6196, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4324,
    55),
    )
)

internal val Res.string.edit_group: StringResource
  get() = CommonMainString0.edit_group

private fun init_edit_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:edit_group", "edit_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4676, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6296, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4380,
    34),
    )
)

internal val Res.string.empty_group_cd: StringResource
  get() = CommonMainString0.empty_group_cd

private fun init_empty_group_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:empty_group_cd", "empty_group_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4719, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6367, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4415,
    46),
    )
)

internal val Res.string.empty_transaction_description: StringResource
  get() = CommonMainString0.empty_transaction_description

private fun init_empty_transaction_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:empty_transaction_description", "empty_transaction_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4774, 177),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6434, 205),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4462,
    125),
    )
)

internal val Res.string.empty_transactions_cd: StringResource
  get() = CommonMainString0.empty_transactions_cd

private fun init_empty_transactions_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:empty_transactions_cd", "empty_transactions_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4952, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6640, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4588,
    57),
    )
)

internal val Res.string.enter_promocode: StringResource
  get() = CommonMainString0.enter_promocode

private fun init_enter_promocode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_promocode", "enter_promocode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5014, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6706, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4646,
    43),
    )
)

internal val Res.string.enter_valid_promocode: StringResource
  get() = CommonMainString0.enter_valid_promocode

private fun init_enter_valid_promocode(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_valid_promocode", "enter_valid_promocode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5062, 81),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6774, 109),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4690,
    57),
    )
)

internal val Res.string.equal: StringResource
  get() = CommonMainString0.equal

private fun init_equal(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:equal", "equal",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5144, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6884, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4748,
    21),
    )
)

internal val Res.string.error: StringResource
  get() = CommonMainString0.error

private fun init_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error", "error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5342, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7174, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4926,
    21),
    )
)

internal val Res.string.error_encountered_problems: StringResource
  get() = CommonMainString0.error_encountered_problems

private fun init_error_encountered_problems(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:error_encountered_problems", "error_encountered_problems",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5166, 106),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6918, 166),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4770,
    98),
    )
)

internal val Res.string.error_fetching_plans: StringResource
  get() = CommonMainString0.error_fetching_plans

private fun init_error_fetching_plans(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:error_fetching_plans", "error_fetching_plans",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5273, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7085, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4869,
    56),
    )
)

internal val Res.string.expanded: StringResource
  get() = CommonMainString0.expanded

private fun init_expanded(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:expanded", "expanded",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5364, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7204, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4948,
    28),
    )
)

internal val Res.string.expense_title: StringResource
  get() = CommonMainString0.expense_title

private fun init_expense_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:expense_title", "expense_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5393, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7249, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 4977,
    41),
    )
)

internal val Res.string.feedback: StringResource
  get() = CommonMainString0.feedback

private fun init_feedback(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:feedback", "feedback",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5439, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7307, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5019,
    28),
    )
)

internal val Res.string.fetch_available_plans: StringResource
  get() = CommonMainString0.fetch_available_plans

private fun init_fetch_available_plans(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fetch_available_plans", "fetch_available_plans",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5468, 73),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7340, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5048,
    65),
    )
)

internal val Res.string.forget_group: StringResource
  get() = CommonMainString0.forget_group

private fun init_forget_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:forget_group", "forget_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5542, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7442, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5114,
    36),
    )
)

internal val Res.string.forgive_and_forget: StringResource
  get() = CommonMainString0.forgive_and_forget

private fun init_forgive_and_forget(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:forgive_and_forget", "forgive_and_forget",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5587, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7499, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5151,
    62),
    )
)

internal val Res.string.free: StringResource
  get() = CommonMainString0.free

private fun init_free(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free", "free",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5662, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7570, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5214,
    24),
    )
)

internal val Res.string.fx_single_currency: StringResource
  get() = CommonMainString0.fx_single_currency

private fun init_fx_single_currency(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fx_single_currency", "fx_single_currency",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5691, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7611, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5239,
    54),
    )
)

internal val Res.string.get_app: StringResource
  get() = CommonMainString0.get_app

private fun init_get_app(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:get_app", "get_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5754, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7702, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5294,
    27),
    )
)

internal val Res.string.get_mobile_app: StringResource
  get() = CommonMainString0.get_mobile_app

private fun init_get_mobile_app(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:get_mobile_app", "get_mobile_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5782, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7738, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5322,
    42),
    )
)

internal val Res.string.get_plus_instant_balances: StringResource
  get() = CommonMainString0.get_plus_instant_balances

private fun init_get_plus_instant_balances(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:get_plus_instant_balances", "get_plus_instant_balances",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5829, 105),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7833, 149),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5365,
    81),
    )
)

internal val Res.string.gets_back: StringResource
  get() = CommonMainString0.gets_back

private fun init_gets_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:gets_back", "gets_back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5935, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7983, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5447,
    29),
    )
)

internal val Res.string.go_to_privacy: StringResource
  get() = CommonMainString0.go_to_privacy

private fun init_go_to_privacy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:go_to_privacy", "go_to_privacy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5973, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8045, 117),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5477,
    49),
    )
)

internal val Res.string.go_to_terms_conditions: StringResource
  get() = CommonMainString0.go_to_terms_conditions

private fun init_go_to_terms_conditions(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:go_to_terms_conditions", "go_to_terms_conditions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6039, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8163, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5527,
    66),
    )
)

internal val Res.string.got_it: StringResource
  get() = CommonMainString0.got_it

private fun init_got_it(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:got_it", "got_it",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6134, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8274, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5594,
    22),
    )
)

internal val Res.string.grant_permission: StringResource
  get() = CommonMainString0.grant_permission

private fun init_grant_permission(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:grant_permission", "grant_permission",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6165, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8305, 84),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5617,
    48),
    )
)

internal val Res.string.group_detailed_empty_description: StringResource
  get() = CommonMainString0.group_detailed_empty_description

private fun init_group_detailed_empty_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_detailed_empty_description", "group_detailed_empty_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6214, 164),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8390, 184),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5666,
    128),
    )
)

internal val Res.string.group_list_empty_description_authorized: StringResource
  get() = CommonMainString0.group_list_empty_description_authorized

private fun init_group_list_empty_description_authorized(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_list_empty_description_authorized", "group_list_empty_description_authorized",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6379, 135),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8575, 135),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5795,
    103),
    )
)

internal val Res.string.group_list_empty_description_unregistered: StringResource
  get() = CommonMainString0.group_list_empty_description_unregistered

private fun init_group_list_empty_description_unregistered(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_list_empty_description_unregistered", "group_list_empty_description_unregistered",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6515, 93),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8711, 117),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5899,
    81),
    )
)

internal val Res.string.group_list_title: StringResource
  get() = CommonMainString0.group_list_title

private fun init_group_list_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_list_title", "group_list_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6609, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8829, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 5981,
    32),
    )
)

internal val Res.string.group_name: StringResource
  get() = CommonMainString0.group_name

private fun init_group_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:group_name", "group_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6646, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8870, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6014,
    34),
    )
)

internal val Res.string.groups: StringResource
  get() = CommonMainString0.groups

private fun init_groups(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:groups", "groups",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6681, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8929, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6049,
    22),
    )
)

internal val Res.string.image_description_ai: StringResource
  get() = CommonMainString0.image_description_ai

private fun init_image_description_ai(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:image_description_ai", "image_description_ai",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6708, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    8960, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6072,
    60),
    )
)

internal val Res.string.info_sign: StringResource
  get() = CommonMainString0.info_sign

private fun init_info_sign(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:info_sign", "info_sign",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6773, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9049, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6133,
    29),
    )
)

internal val Res.string.invite_user: StringResource
  get() = CommonMainString0.invite_user

private fun init_invite_user(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:invite_user", "invite_user",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6807, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9123, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6163,
    35),
    )
)

internal val Res.string.join_agree: StringResource
  get() = CommonMainString0.join_agree

private fun init_join_agree(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_agree", "join_agree",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6851, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9203, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6199,
    58),
    )
)

internal val Res.string.join_group: StringResource
  get() = CommonMainString0.join_group

private fun init_join_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_group", "join_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7063, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9531, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6387,
    34),
    )
)

internal val Res.string.join_group_as_new_participant: StringResource
  get() = CommonMainString0.join_group_as_new_participant

private fun init_join_group_as_new_participant(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_group_as_new_participant", "join_group_as_new_participant",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6918, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9306, 133),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6258,
    77),
    )
)

internal val Res.string.join_group_as_user: StringResource
  get() = CommonMainString0.join_group_as_user

private fun init_join_group_as_user(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_group_as_user", "join_group_as_user",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7004, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9440, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6336,
    50),
    )
)

internal val Res.string.join_new_participant: StringResource
  get() = CommonMainString0.join_new_participant

private fun init_join_new_participant(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_new_participant", "join_new_participant",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7106, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9610, 112),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6422,
    60),
    )
)

internal val Res.string.label_apply: StringResource
  get() = CommonMainString0.label_apply

private fun init_label_apply(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:label_apply", "label_apply",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7175, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9723, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6483,
    27),
    )
)

internal val Res.string.label_code: StringResource
  get() = CommonMainString0.label_code

private fun init_label_code(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:label_code", "label_code",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7207, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9767, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6511,
    26),
    )
)

internal val Res.string.leave_group: StringResource
  get() = CommonMainString0.leave_group

private fun init_leave_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:leave_group", "leave_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7463, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10127, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6739,
    35),
    )
)

internal val Res.string.leave_group_confirmation: StringResource
  get() = CommonMainString0.leave_group_confirmation

private fun init_leave_group_confirmation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:leave_group_confirmation", "leave_group_confirmation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7234, 228),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    9794, 332),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6538,
    200),
    )
)

internal val Res.string.light_theme: StringResource
  get() = CommonMainString0.light_theme

private fun init_light_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:light_theme", "light_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7507, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10187, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6775,
    27),
    )
)

internal val Res.string.loading: StringResource
  get() = CommonMainString0.loading

private fun init_loading(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:loading", "loading",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7535, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10227, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6803,
    27),
    )
)

internal val Res.string.locked_feature: StringResource
  get() = CommonMainString0.locked_feature

private fun init_locked_feature(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:locked_feature", "locked_feature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7559, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10267, 82),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6831,
    42),
    )
)

internal val Res.string.login: StringResource
  get() = CommonMainString0.login

private fun init_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login", "login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8025, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10853, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7241,
    21),
    )
)

internal val Res.string.login_anon: StringResource
  get() = CommonMainString0.login_anon

private fun init_login_anon(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_anon", "login_anon",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7606, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10350, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6874,
    42),
    )
)

internal val Res.string.login_button_cd: StringResource
  get() = CommonMainString0.login_button_cd

private fun init_login_button_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_button_cd", "login_button_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7645, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10405, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6917,
    31),
    )
)

internal val Res.string.login_to_create_descr: StringResource
  get() = CommonMainString0.login_to_create_descr

private fun init_login_to_create_descr(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_to_create_descr", "login_to_create_descr",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7681, 133),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10441, 149),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 6949,
    113),
    )
)

internal val Res.string.login_to_protect_expense: StringResource
  get() = CommonMainString0.login_to_protect_expense

private fun init_login_to_protect_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_to_protect_expense", "login_to_protect_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7815, 100),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10591, 128),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7063,
    72),
    )
)

internal val Res.string.login_with_apple_cd: StringResource
  get() = CommonMainString0.login_with_apple_cd

private fun init_login_with_apple_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_with_apple_cd", "login_with_apple_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7916, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10720, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7136,
    51),
    )
)

internal val Res.string.login_with_google_cd: StringResource
  get() = CommonMainString0.login_with_google_cd

private fun init_login_with_google_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_with_google_cd", "login_with_google_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7968, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10784, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7188,
    52),
    )
)

internal val Res.string.logout: StringResource
  get() = CommonMainString0.logout

private fun init_logout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout", "logout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8051, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10879, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7263,
    22),
    )
)

internal val Res.string.mark_as_non_settled: StringResource
  get() = CommonMainString0.mark_as_non_settled

private fun init_mark_as_non_settled(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:mark_as_non_settled", "mark_as_non_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8078, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10910, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7286,
    55),
    )
)

internal val Res.string.mark_as_settled: StringResource
  get() = CommonMainString0.mark_as_settled

private fun init_mark_as_settled(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:mark_as_settled", "mark_as_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8146, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11002, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7342,
    43),
    )
)

internal val Res.string.month_plus: StringResource
  get() = CommonMainString0.month_plus

private fun init_month_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:month_plus", "month_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8202, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11082, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7386,
    34),
    )
)

internal val Res.string.new: StringResource
  get() = CommonMainString0.new

private fun init_new(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new", "new",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8311, 15),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11231, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7487,
    15),
    )
)

internal val Res.string.new_expense: StringResource
  get() = CommonMainString0.new_expense

private fun init_new_expense(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_expense", "new_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8241, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11129, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7421,
    35),
    )
)

internal val Res.string.new_group: StringResource
  get() = CommonMainString0.new_group

private fun init_new_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_group", "new_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8277, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11181, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7457,
    29),
    )
)

internal val Res.string.no_contact_found: StringResource
  get() = CommonMainString0.no_contact_found

private fun init_no_contact_found(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:no_contact_found", "no_contact_found",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8327, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11259, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7503,
    64),
    )
)

internal val Res.string.no_image_group_cd: StringResource
  get() = CommonMainString0.no_image_group_cd

private fun init_no_image_group_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:no_image_group_cd", "no_image_group_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8396, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11348, 97),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7568,
    57),
    )
)

internal val Res.string.no_image_user: StringResource
  get() = CommonMainString0.no_image_user

private fun init_no_image_user(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:no_image_user", "no_image_user",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8458, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11446, 109),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7626,
    53),
    )
)

internal val Res.string.no_selected_user: StringResource
  get() = CommonMainString0.no_selected_user

private fun init_no_selected_user(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:no_selected_user", "no_selected_user",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8520, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11556, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7680,
    48),
    )
)

internal val Res.string.non_distr_cd: StringResource
  get() = CommonMainString0.non_distr_cd

private fun init_non_distr_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:non_distr_cd", "non_distr_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8577, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11621, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7729,
    52),
    )
)

internal val Res.string.not_allowed_to_update_expense: StringResource
  get() = CommonMainString0.not_allowed_to_update_expense

private fun init_not_allowed_to_update_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:not_allowed_to_update_expense", "not_allowed_to_update_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8630, 97),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11702, 129),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7782,
    93),
    )
)

internal val Res.string.not_found: StringResource
  get() = CommonMainString0.not_found

private fun init_not_found(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:not_found", "not_found",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8728, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11832, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7876,
    41),
    )
)

internal val Res.string.not_participating: StringResource
  get() = CommonMainString0.not_participating

private fun init_not_participating(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:not_participating", "not_participating",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8782, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11886, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7918,
    57),
    )
)

internal val Res.string.not_settled: StringResource
  get() = CommonMainString0.not_settled

private fun init_not_settled(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:not_settled", "not_settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8836, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    11952, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 7976,
    35),
    )
)

internal val Res.string.not_split: StringResource
  get() = CommonMainString0.not_split

private fun init_not_split(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:not_split", "not_split",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8929, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12065, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8057,
    29),
    )
)

internal val Res.string.not_split_amount: StringResource
  get() = CommonMainString0.not_split_amount

private fun init_not_split_amount(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:not_split_amount", "not_split_amount",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8876, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12000, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8012,
    44),
    )
)

internal val Res.string.offer_badge: StringResource
  get() = CommonMainString0.offer_badge

private fun init_offer_badge(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:offer_badge", "offer_badge",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8967, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12119, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8087,
    35),
    )
)

internal val Res.string.offline_balances_shown: StringResource
  get() = CommonMainString0.offline_balances_shown

private fun init_offline_balances_shown(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:offline_balances_shown", "offline_balances_shown",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9007, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12187, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8123,
    66),
    )
)

internal val Res.string.okey: StringResource
  get() = CommonMainString0.okey

private fun init_okey(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:okey", "okey",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9082, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12290, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8190,
    16),
    )
)

internal val Res.string.only_this_transaction: StringResource
  get() = CommonMainString0.only_this_transaction

private fun init_only_this_transaction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:only_this_transaction", "only_this_transaction",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9099, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12311, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8207,
    57),
    )
)

internal val Res.string.only_you_can_update_expense: StringResource
  get() = CommonMainString0.only_you_can_update_expense

private fun init_only_you_can_update_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:only_you_can_update_expense", "only_you_can_update_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9157, 95),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12397, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8265,
    91),
    )
)

internal val Res.string.or: StringResource
  get() = CommonMainString0.or

private fun init_or(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:or", "or",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9253, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12497, 18),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8357,
    14),
    )
)

internal val Res.string.other: StringResource
  get() = CommonMainString0.other

private fun init_other(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:other", "other",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9272, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12516, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8372,
    21),
    )
)

internal val Res.string.paid_by: StringResource
  get() = CommonMainString0.paid_by

private fun init_paid_by(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paid_by", "paid_by",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9424, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12708, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8504,
    27),
    )
)

internal val Res.string.paid_by_me: StringResource
  get() = CommonMainString0.paid_by_me

private fun init_paid_by_me(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paid_by_me", "paid_by_me",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9294, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12546, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8394,
    34),
    )
)

internal val Res.string.paid_by_person: StringResource
  get() = CommonMainString0.paid_by_person

private fun init_paid_by_person(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paid_by_person", "paid_by_person",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9333, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12601, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8429,
    38),
    )
)

internal val Res.string.paid_by_you: StringResource
  get() = CommonMainString0.paid_by_you

private fun init_paid_by_you(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paid_by_you", "paid_by_you",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9380, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12652, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8468,
    35),
    )
)

internal val Res.string.per_month: StringResource
  get() = CommonMainString0.per_month

private fun init_per_month(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:per_month", "per_month",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9456, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12748, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8532,
    33),
    )
)

internal val Res.string.per_week: StringResource
  get() = CommonMainString0.per_week

private fun init_per_week(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:per_week", "per_week",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9490, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12786, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8566,
    28),
    )
)

internal val Res.string.per_year: StringResource
  get() = CommonMainString0.per_year

private fun init_per_year(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:per_year", "per_year",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9523, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12827, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8595,
    28),
    )
)

internal val Res.string.personal_expense: StringResource
  get() = CommonMainString0.personal_expense

private fun init_personal_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:personal_expense", "personal_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9552, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12860, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8624,
    48),
    )
)

internal val Res.string.plus_active: StringResource
  get() = CommonMainString0.plus_active

private fun init_plus_active(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plus_active", "plus_active",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9605, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12917, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8673,
    47),
    )
)

internal val Res.string.plus_badge: StringResource
  get() = CommonMainString0.plus_badge

private fun init_plus_badge(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plus_badge", "plus_badge",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9717, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13065, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8781,
    34),
    )
)

internal val Res.string.plus_badge_benefits: StringResource
  get() = CommonMainString0.plus_badge_benefits

private fun init_plus_badge_benefits(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_badge_benefits", "plus_badge_benefits",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9649, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    12973, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8721,
    59),
    )
)

internal val Res.string.plus_feature_cats_cta: StringResource
  get() = CommonMainString0.plus_feature_cats_cta

private fun init_plus_feature_cats_cta(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_cats_cta", "plus_feature_cats_cta",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9756, 77),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13108, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8816,
    61),
    )
)

internal val Res.string.plus_feature_cats_descr_full: StringResource
  get() = CommonMainString0.plus_feature_cats_descr_full

private fun init_plus_feature_cats_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_cats_descr_full", "plus_feature_cats_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9834, 172),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13210, 260),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 8878,
    256),
    )
)

internal val Res.string.plus_feature_cats_descr_short: StringResource
  get() = CommonMainString0.plus_feature_cats_descr_short

private fun init_plus_feature_cats_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_cats_descr_short", "plus_feature_cats_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10007, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13471, 121),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9135,
    81),
    )
)

internal val Res.string.plus_feature_cats_title: StringResource
  get() = CommonMainString0.plus_feature_cats_title

private fun init_plus_feature_cats_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_cats_title", "plus_feature_cats_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10093, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13593, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9217,
    67),
    )
)

internal val Res.string.plus_feature_currencies_descr_full: StringResource
  get() = CommonMainString0.plus_feature_currencies_descr_full

private fun init_plus_feature_currencies_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_currencies_descr_full", "plus_feature_currencies_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10161, 274),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13685, 246),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9285,
    218),
    )
)

internal val Res.string.plus_feature_currencies_descr_short: StringResource
  get() = CommonMainString0.plus_feature_currencies_descr_short

private fun init_plus_feature_currencies_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_currencies_descr_short", "plus_feature_currencies_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10436, 107),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    13932, 127),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9504,
    83),
    )
)

internal val Res.string.plus_feature_currencies_title: StringResource
  get() = CommonMainString0.plus_feature_currencies_title

private fun init_plus_feature_currencies_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_currencies_title", "plus_feature_currencies_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10544, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14060, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9588,
    49),
    )
)

internal val Res.string.plus_feature_images_descr_full: StringResource
  get() = CommonMainString0.plus_feature_images_descr_full

private fun init_plus_feature_images_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_images_descr_full", "plus_feature_images_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10610, 170),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14126, 282),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9638,
    154),
    )
)

internal val Res.string.plus_feature_images_descr_short: StringResource
  get() = CommonMainString0.plus_feature_images_descr_short

private fun init_plus_feature_images_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_images_descr_short", "plus_feature_images_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10781, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14409, 119),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9793,
    71),
    )
)

internal val Res.string.plus_feature_images_title: StringResource
  get() = CommonMainString0.plus_feature_images_title

private fun init_plus_feature_images_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_images_title", "plus_feature_images_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10861, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14529, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9865,
    49),
    )
)

internal val Res.string.plus_feature_more_descr: StringResource
  get() = CommonMainString0.plus_feature_more_descr

private fun init_plus_feature_more_descr(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_more_descr", "plus_feature_more_descr",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10915, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14607, 119),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9915,
    59),
    )
)

internal val Res.string.plus_feature_more_title: StringResource
  get() = CommonMainString0.plus_feature_more_title

private fun init_plus_feature_more_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_more_title", "plus_feature_more_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10999, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14727, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 9975,
    47),
    )
)

internal val Res.string.plus_feature_noads_descr_full: StringResource
  get() = CommonMainString0.plus_feature_noads_descr_full

private fun init_plus_feature_noads_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_noads_descr_full", "plus_feature_noads_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11047, 277),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    14803, 253),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10023,
    221),
    )
)

internal val Res.string.plus_feature_noads_descr_short: StringResource
  get() = CommonMainString0.plus_feature_noads_descr_short

private fun init_plus_feature_noads_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_noads_descr_short", "plus_feature_noads_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11325, 78),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15057, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10245,
    74),
    )
)

internal val Res.string.plus_feature_noads_title: StringResource
  get() = CommonMainString0.plus_feature_noads_title

private fun init_plus_feature_noads_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_noads_title", "plus_feature_noads_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11404, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15168, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10320,
    40),
    )
)

internal val Res.string.plus_feature_protect_descr_full: StringResource
  get() = CommonMainString0.plus_feature_protect_descr_full

private fun init_plus_feature_protect_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_protect_descr_full", "plus_feature_protect_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11457, 235),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15229, 279),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10361,
    195),
    )
)

internal val Res.string.plus_feature_protect_descr_short: StringResource
  get() = CommonMainString0.plus_feature_protect_descr_short

private fun init_plus_feature_protect_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_protect_descr_short", "plus_feature_protect_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11693, 96),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15509, 104),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10557,
    72),
    )
)

internal val Res.string.plus_feature_protect_title: StringResource
  get() = CommonMainString0.plus_feature_protect_title

private fun init_plus_feature_protect_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_protect_title", "plus_feature_protect_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11790, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15614, 98),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10630,
    58),
    )
)

internal val Res.string.plus_feature_quick_add_descr_full: StringResource
  get() = CommonMainString0.plus_feature_quick_add_descr_full

private fun init_plus_feature_quick_add_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_quick_add_descr_full", "plus_feature_quick_add_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11853, 225),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    15713, 301),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10689,
    193),
    )
)

internal val Res.string.plus_feature_quick_add_descr_short: StringResource
  get() = CommonMainString0.plus_feature_quick_add_descr_short

private fun init_plus_feature_quick_add_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_quick_add_descr_short", "plus_feature_quick_add_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12079, 106),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16015, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10883,
    74),
    )
)

internal val Res.string.plus_feature_quick_add_title: StringResource
  get() = CommonMainString0.plus_feature_quick_add_title

private fun init_plus_feature_quick_add_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_quick_add_title", "plus_feature_quick_add_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12186, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16126, 96),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10958,
    64),
    )
)

internal val Res.string.plus_feature_single_settle_descr_full: StringResource
  get() = CommonMainString0.plus_feature_single_settle_descr_full

private fun init_plus_feature_single_settle_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_single_settle_descr_full", "plus_feature_single_settle_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12255, 193),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16223, 317),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11023,
    189),
    )
)

internal val Res.string.plus_feature_single_settle_descr_short: StringResource
  get() = CommonMainString0.plus_feature_single_settle_descr_short

private fun init_plus_feature_single_settle_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_single_settle_descr_short", "plus_feature_single_settle_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12449, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16541, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11213,
    90),
    )
)

internal val Res.string.plus_feature_single_settle_title: StringResource
  get() = CommonMainString0.plus_feature_single_settle_title

private fun init_plus_feature_single_settle_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_single_settle_title", "plus_feature_single_settle_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12544, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16652, 108),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11304,
    68),
    )
)

internal val Res.string.plus_feature_suggest_descr_full: StringResource
  get() = CommonMainString0.plus_feature_suggest_descr_full

private fun init_plus_feature_suggest_descr_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_suggest_descr_full", "plus_feature_suggest_descr_full",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12609, 303),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    16761, 315),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11373,
    255),
    )
)

internal val Res.string.plus_feature_suggest_descr_short: StringResource
  get() = CommonMainString0.plus_feature_suggest_descr_short

private fun init_plus_feature_suggest_descr_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_suggest_descr_short", "plus_feature_suggest_descr_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12913, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17077, 148),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11629,
    92),
    )
)

internal val Res.string.plus_feature_suggest_title: StringResource
  get() = CommonMainString0.plus_feature_suggest_title

private fun init_plus_feature_suggest_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:plus_feature_suggest_title", "plus_feature_suggest_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13022, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17226, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11722,
    74),
    )
)

internal val Res.string.plus_subs_title: StringResource
  get() = CommonMainString0.plus_subs_title

private fun init_plus_subs_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plus_subs_title", "plus_subs_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13105, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17337, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11797,
    43),
    )
)

internal val Res.string.privacy_policy: StringResource
  get() = CommonMainString0.privacy_policy

private fun init_privacy_policy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:privacy_policy", "privacy_policy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13149, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17401, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11841,
    42),
    )
)

internal val Res.string.problems_encountered: StringResource
  get() = CommonMainString0.problems_encountered

private fun init_problems_encountered(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:problems_encountered", "problems_encountered",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13200, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17496, 160),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11884,
    92),
    )
)

internal val Res.string.profile: StringResource
  get() = CommonMainString0.profile

private fun init_profile(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:profile", "profile",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13392, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17764, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12048,
    27),
    )
)

internal val Res.string.profile_under_construction: StringResource
  get() = CommonMainString0.profile_under_construction

private fun init_profile_under_construction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_under_construction", "profile_under_construction",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13309, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17657, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 11977,
    70),
    )
)

internal val Res.string.protect_expense: StringResource
  get() = CommonMainString0.protect_expense

private fun init_protect_expense(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:protect_expense", "protect_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13508, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17916, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12148,
    43),
    )
)

internal val Res.string.protect_expense_editing: StringResource
  get() = CommonMainString0.protect_expense_editing

private fun init_protect_expense_editing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:protect_expense_editing", "protect_expense_editing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13416, 91),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17800, 115),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12076,
    71),
    )
)

internal val Res.string.protected_expense: StringResource
  get() = CommonMainString0.protected_expense

private fun init_protected_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:protected_expense", "protected_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13556, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    17980, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12192,
    49),
    )
)

internal val Res.string.protected_feature: StringResource
  get() = CommonMainString0.protected_feature

private fun init_protected_feature(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:protected_feature", "protected_feature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13610, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18050, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12242,
    49),
    )
)

internal val Res.string.quick_add: StringResource
  get() = CommonMainString0.quick_add

private fun init_quick_add(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:quick_add", "quick_add",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13788, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18296, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12384,
    45),
    )
)

internal val Res.string.quick_add_empty: StringResource
  get() = CommonMainString0.quick_add_empty

private fun init_quick_add_empty(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:quick_add_empty", "quick_add_empty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13664, 123),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18124, 171),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12292,
    91),
    )
)

internal val Res.string.quick_split: StringResource
  get() = CommonMainString0.quick_split

private fun init_quick_split(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:quick_split", "quick_split",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13974, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18526, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12546,
    35),
    )
)

internal val Res.string.quick_split_total_participants: StringResource
  get() = CommonMainString0.quick_split_total_participants

private fun init_quick_split_total_participants(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:quick_split_total_participants", "quick_split_total_participants",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13846, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18362, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12430,
    70),
    )
)

internal val Res.string.quick_split_turn: StringResource
  get() = CommonMainString0.quick_split_turn

private fun init_quick_split_turn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:quick_split_turn", "quick_split_turn",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13921, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18453, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12501,
    44),
    )
)

internal val Res.string.recalculate: StringResource
  get() = CommonMainString0.recalculate

private fun init_recalculate(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:recalculate", "recalculate",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14014, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18582, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12582,
    35),
    )
)

internal val Res.string.recalculating_backend: StringResource
  get() = CommonMainString0.recalculating_backend

private fun init_recalculating_backend(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:recalculating_backend", "recalculating_backend",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14054, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18634, 89),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12618,
    61),
    )
)

internal val Res.string.remove_share: StringResource
  get() = CommonMainString0.remove_share

private fun init_remove_share(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:remove_share", "remove_share",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14116, 100),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18724, 120),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12680,
    80),
    )
)

internal val Res.string.retry: StringResource
  get() = CommonMainString0.retry

private fun init_retry(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:retry", "retry",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14217, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18845, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12761,
    21),
    )
)

internal val Res.string.save: StringResource
  get() = CommonMainString0.save

private fun init_save(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:save", "save",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14255, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18883, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12783,
    20),
    )
)

internal val Res.string.search_contact: StringResource
  get() = CommonMainString0.search_contact

private fun init_search_contact(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search_contact", "search_contact",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14280, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    18920, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12804,
    46),
    )
)

internal val Res.string.search_currency: StringResource
  get() = CommonMainString0.search_currency

private fun init_search_currency(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search_currency", "search_currency",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14355, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19007, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12851,
    51),
    )
)

internal val Res.string.select_currency: StringResource
  get() = CommonMainString0.select_currency

private fun init_select_currency(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_currency", "select_currency",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14399, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19063, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12903,
    43),
    )
)

internal val Res.string.select_payer_cd: StringResource
  get() = CommonMainString0.select_payer_cd

private fun init_select_payer_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_payer_cd", "select_payer_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14451, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19127, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 12947,
    55),
    )
)

internal val Res.string.settings: StringResource
  get() = CommonMainString0.settings

private fun init_settings(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings", "settings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14511, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19199, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13003,
    28),
    )
)

internal val Res.string.settle_all_balances: StringResource
  get() = CommonMainString0.settle_all_balances

private fun init_settle_all_balances(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settle_all_balances", "settle_all_balances",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14548, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19240, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13032,
    55),
    )
)

internal val Res.string.settle_balances: StringResource
  get() = CommonMainString0.settle_balances

private fun init_settle_balances(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settle_balances", "settle_balances",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14608, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19340, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13088,
    35),
    )
)

internal val Res.string.settled: StringResource
  get() = CommonMainString0.settled

private fun init_settled(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settled", "settled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14765, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19533, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13225,
    27),
    )
)

internal val Res.string.settled_all_expenses: StringResource
  get() = CommonMainString0.settled_all_expenses

private fun init_settled_all_expenses(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settled_all_expenses", "settled_all_expenses",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14644, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19396, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13124,
    56),
    )
)

internal val Res.string.settled_message: StringResource
  get() = CommonMainString0.settled_message

private fun init_settled_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settled_message", "settled_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14709, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19469, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13181,
    43),
    )
)

internal val Res.string.share_group: StringResource
  get() = CommonMainString0.share_group

private fun init_share_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:share_group", "share_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14793, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19573, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13253,
    43),
    )
)

internal val Res.string.share_link_copied: StringResource
  get() = CommonMainString0.share_link_copied

private fun init_share_link_copied(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:share_link_copied", "share_link_copied",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14841, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19653, 97),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13297,
    53),
    )
)

internal val Res.string.share_subject: StringResource
  get() = CommonMainString0.share_subject

private fun init_share_subject(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:share_subject", "share_subject",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14899, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19751, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13351,
    37),
    )
)

internal val Res.string.shares: StringResource
  get() = CommonMainString0.shares

private fun init_shares(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:shares", "shares",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14941, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19801, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13389,
    22),
    )
)

internal val Res.string.should_pay_to: StringResource
  get() = CommonMainString0.should_pay_to

private fun init_should_pay_to(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:should_pay_to", "should_pay_to",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14968, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19832, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13412,
    41),
    )
)

internal val Res.string.start_type_creat_contact: StringResource
  get() = CommonMainString0.start_type_creat_contact

private fun init_start_type_creat_contact(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:start_type_creat_contact", "start_type_creat_contact",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15010, 120),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    19894, 144),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13454,
    88),
    )
)

internal val Res.string.submit_feedback_cd: StringResource
  get() = CommonMainString0.submit_feedback_cd

private fun init_submit_feedback_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:submit_feedback_cd", "submit_feedback_cd",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15131, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20039, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13543,
    46),
    )
)

internal val Res.string.subscribe_for: StringResource
  get() = CommonMainString0.subscribe_for

private fun init_subscribe_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:subscribe_for", "subscribe_for",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15178, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20106, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13590,
    45),
    )
)

internal val Res.string.subscribe_plus: StringResource
  get() = CommonMainString0.subscribe_plus

private fun init_subscribe_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:subscribe_plus", "subscribe_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15228, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20172, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13636,
    46),
    )
)

internal val Res.string.subscribe_via_mobile_app: StringResource
  get() = CommonMainString0.subscribe_via_mobile_app

private fun init_subscribe_via_mobile_app(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:subscribe_via_mobile_app", "subscribe_via_mobile_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15271, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20239, 156),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13683,
    92),
    )
)

internal val Res.string.suggest_split: StringResource
  get() = CommonMainString0.suggest_split

private fun init_suggest_split(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:suggest_split", "suggest_split",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15380, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20396, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13776,
    41),
    )
)

internal val Res.string.sync_in_progress: StringResource
  get() = CommonMainString0.sync_in_progress

private fun init_sync_in_progress(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sync_in_progress", "sync_in_progress",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15430, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20474, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13818,
    48),
    )
)

internal val Res.string.system_theme: StringResource
  get() = CommonMainString0.system_theme

private fun init_system_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:system_theme", "system_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15487, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20563, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13867,
    28),
    )
)

internal val Res.string.terms_conditions: StringResource
  get() = CommonMainString0.terms_conditions

private fun init_terms_conditions(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:terms_conditions", "terms_conditions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15516, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20608, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13896,
    48),
    )
)

internal val Res.string.text_its_me: StringResource
  get() = CommonMainString0.text_its_me

private fun init_text_its_me(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:text_its_me", "text_its_me",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15577, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20689, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13945,
    31),
    )
)

internal val Res.string.text_loading: StringResource
  get() = CommonMainString0.text_loading

private fun init_text_loading(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:text_loading", "text_loading",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15613, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20721, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 13977,
    36),
    )
)

internal val Res.string.title_leave_group: StringResource
  get() = CommonMainString0.title_leave_group

private fun init_title_leave_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_leave_group", "title_leave_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15646, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20770, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14014,
    41),
    )
)

internal val Res.string.title_should_be_filled: StringResource
  get() = CommonMainString0.title_should_be_filled

private fun init_title_should_be_filled(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_should_be_filled", "title_should_be_filled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15692, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20828, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14056,
    62),
    )
)

internal val Res.string.transactions: StringResource
  get() = CommonMainString0.transactions

private fun init_transactions(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:transactions", "transactions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15767, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20935, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14119,
    36),
    )
)

internal val Res.string.try_days: StringResource
  get() = CommonMainString0.try_days

private fun init_try_days(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_days", "try_days",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15808, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    20984, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14156,
    40),
    )
)

internal val Res.string.try_month_plus: StringResource
  get() = CommonMainString0.try_month_plus

private fun init_try_month_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_month_plus", "try_month_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15861, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21073, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14197,
    50),
    )
)

internal val Res.string.try_plus: StringResource
  get() = CommonMainString0.try_plus

private fun init_try_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_plus", "try_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15916, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21140, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14248,
    28),
    )
)

internal val Res.string.try_week_plus: StringResource
  get() = CommonMainString0.try_week_plus

private fun init_try_week_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_week_plus", "try_week_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15961, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21193, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14277,
    49),
    )
)

internal val Res.string.try_year_plus: StringResource
  get() = CommonMainString0.try_year_plus

private fun init_try_year_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:try_year_plus", "try_year_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16019, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21263, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14327,
    49),
    )
)

internal val Res.string.tutorial_info: StringResource
  get() = CommonMainString0.tutorial_info

private fun init_tutorial_info(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tutorial_info", "tutorial_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16073, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21329, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14377,
    49),
    )
)

internal val Res.string.tutorial_step_add_expense_description: StringResource
  get() = CommonMainString0.tutorial_step_add_expense_description

private fun init_tutorial_step_add_expense_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_add_expense_description", "tutorial_step_add_expense_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16123, 165),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21415, 221),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14427,
    129),
    )
)

internal val Res.string.tutorial_step_add_expense_title: StringResource
  get() = CommonMainString0.tutorial_step_add_expense_title

private fun init_tutorial_step_add_expense_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_add_expense_title", "tutorial_step_add_expense_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16289, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21637, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14557,
    67),
    )
)

internal val Res.string.tutorial_step_add_participant_description: StringResource
  get() = CommonMainString0.tutorial_step_add_participant_description

private fun init_tutorial_step_add_participant_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_add_participant_description", "tutorial_step_add_participant_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16369, 233),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    21737, 277),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14625,
    197),
    )
)

internal val Res.string.tutorial_step_add_participant_title: StringResource
  get() = CommonMainString0.tutorial_step_add_participant_title

private fun init_tutorial_step_add_participant_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_add_participant_title", "tutorial_step_add_participant_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16603, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22015, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14823,
    63),
    )
)

internal val Res.string.tutorial_step_balances_tab_description: StringResource
  get() = CommonMainString0.tutorial_step_balances_tab_description

private fun init_tutorial_step_balances_tab_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_balances_tab_description", "tutorial_step_balances_tab_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16679, 150),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22107, 182),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14887,
    110),
    )
)

internal val Res.string.tutorial_step_balances_tab_title: StringResource
  get() = CommonMainString0.tutorial_step_balances_tab_title

private fun init_tutorial_step_balances_tab_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_balances_tab_title", "tutorial_step_balances_tab_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16830, 76),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22290, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 14998,
    56),
    )
)

internal val Res.string.tutorial_step_check_balances_description: StringResource
  get() = CommonMainString0.tutorial_step_check_balances_description

private fun init_tutorial_step_check_balances_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_check_balances_description", "tutorial_step_check_balances_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16907, 232),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22371, 344),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15055,
    188),
    )
)

internal val Res.string.tutorial_step_check_balances_title: StringResource
  get() = CommonMainString0.tutorial_step_check_balances_title

private fun init_tutorial_step_check_balances_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_check_balances_title", "tutorial_step_check_balances_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17140, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22716, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15244,
    62),
    )
)

internal val Res.string.tutorial_step_choose_payer: StringResource
  get() = CommonMainString0.tutorial_step_choose_payer

private fun init_tutorial_step_choose_payer(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_choose_payer", "tutorial_step_choose_payer",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17374, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23066, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15458,
    58),
    )
)

internal val Res.string.tutorial_step_choose_payer_description: StringResource
  get() = CommonMainString0.tutorial_step_choose_payer_description

private fun init_tutorial_step_choose_payer_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_choose_payer_description", "tutorial_step_choose_payer_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17203, 170),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    22803, 262),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15307,
    150),
    )
)

internal val Res.string.tutorial_step_confirm_selection_description: StringResource
  get() = CommonMainString0.tutorial_step_confirm_selection_description

private fun init_tutorial_step_confirm_selection_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_confirm_selection_description",
    "tutorial_step_confirm_selection_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17441, 167),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23153, 279),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15517,
    159),
    )
)

internal val Res.string.tutorial_step_confirm_selection_title: StringResource
  get() = CommonMainString0.tutorial_step_confirm_selection_title

private fun init_tutorial_step_confirm_selection_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_confirm_selection_title", "tutorial_step_confirm_selection_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17609, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23433, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15677,
    77),
    )
)

internal val Res.string.tutorial_step_create_group_description: StringResource
  get() = CommonMainString0.tutorial_step_create_group_description

private fun init_tutorial_step_create_group_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_create_group_description", "tutorial_step_create_group_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17695, 186),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23535, 254),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15755,
    154),
    )
)

internal val Res.string.tutorial_step_create_group_title: StringResource
  get() = CommonMainString0.tutorial_step_create_group_title

private fun init_tutorial_step_create_group_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_create_group_title", "tutorial_step_create_group_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17882, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23790, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15910,
    64),
    )
)

internal val Res.string.tutorial_step_date_currency_description: StringResource
  get() = CommonMainString0.tutorial_step_date_currency_description

private fun init_tutorial_step_date_currency_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_date_currency_description", "tutorial_step_date_currency_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17951, 255),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    23883, 335),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 15975,
    223),
    )
)

internal val Res.string.tutorial_step_date_currency_setup: StringResource
  get() = CommonMainString0.tutorial_step_date_currency_setup

private fun init_tutorial_step_date_currency_setup(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_date_currency_setup", "tutorial_step_date_currency_setup",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18207, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24219, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16199,
    73),
    )
)

internal val Res.string.tutorial_step_expense_title: StringResource
  get() = CommonMainString0.tutorial_step_expense_title

private fun init_tutorial_step_expense_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_expense_title", "tutorial_step_expense_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18453, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24525, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16417,
    63),
    )
)

internal val Res.string.tutorial_step_expense_title_description: StringResource
  get() = CommonMainString0.tutorial_step_expense_title_description

private fun init_tutorial_step_expense_title_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_expense_title_description", "tutorial_step_expense_title_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18293, 159),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24321, 203),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16273,
    143),
    )
)

internal val Res.string.tutorial_step_input_name_description: StringResource
  get() = CommonMainString0.tutorial_step_input_name_description

private fun init_tutorial_step_input_name_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_input_name_description", "tutorial_step_input_name_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18533, 184),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24617, 240),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16481,
    152),
    )
)

internal val Res.string.tutorial_step_input_name_title: StringResource
  get() = CommonMainString0.tutorial_step_input_name_title

private fun init_tutorial_step_input_name_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_input_name_title", "tutorial_step_input_name_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18718, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24858, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16634,
    66),
    )
)

internal val Res.string.tutorial_step_invite_friends_description: StringResource
  get() = CommonMainString0.tutorial_step_invite_friends_description

private fun init_tutorial_step_invite_friends_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_invite_friends_description", "tutorial_step_invite_friends_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18809, 208),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    24965, 292),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16701,
    164),
    )
)

internal val Res.string.tutorial_step_invite_friends_title: StringResource
  get() = CommonMainString0.tutorial_step_invite_friends_title

private fun init_tutorial_step_invite_friends_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_invite_friends_title", "tutorial_step_invite_friends_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19018, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25258, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16866,
    78),
    )
)

internal val Res.string.tutorial_step_put_amount: StringResource
  get() = CommonMainString0.tutorial_step_put_amount

private fun init_tutorial_step_put_amount(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_put_amount", "tutorial_step_put_amount",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19258, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25526, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17062,
    52),
    )
)

internal val Res.string.tutorial_step_put_amount_description: StringResource
  get() = CommonMainString0.tutorial_step_put_amount_description

private fun init_tutorial_step_put_amount_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_put_amount_description", "tutorial_step_put_amount_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19109, 148),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25369, 156),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 16945,
    116),
    )
)

internal val Res.string.tutorial_step_save_expense: StringResource
  get() = CommonMainString0.tutorial_step_save_expense

private fun init_tutorial_step_save_expense(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_save_expense", "tutorial_step_save_expense",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19526, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25930, 74),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17282,
    58),
    )
)

internal val Res.string.tutorial_step_save_expense_description: StringResource
  get() = CommonMainString0.tutorial_step_save_expense_description

private fun init_tutorial_step_save_expense_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_save_expense_description", "tutorial_step_save_expense_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19323, 202),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    25595, 334),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17115,
    166),
    )
)

internal val Res.string.tutorial_step_select_user_description: StringResource
  get() = CommonMainString0.tutorial_step_select_user_description

private fun init_tutorial_step_select_user_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_select_user_description", "tutorial_step_select_user_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19597, 233),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26005, 353),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17341,
    185),
    )
)

internal val Res.string.tutorial_step_select_user_title: StringResource
  get() = CommonMainString0.tutorial_step_select_user_title

private fun init_tutorial_step_select_user_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_select_user_title", "tutorial_step_select_user_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19831, 99),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26359, 123),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17527,
    71),
    )
)

internal val Res.string.tutorial_step_settle_up_description: StringResource
  get() = CommonMainString0.tutorial_step_settle_up_description

private fun init_tutorial_step_settle_up_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_settle_up_description", "tutorial_step_settle_up_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19931, 123),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26483, 175),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17599,
    111),
    )
)

internal val Res.string.tutorial_step_settle_up_title: StringResource
  get() = CommonMainString0.tutorial_step_settle_up_title

private fun init_tutorial_step_settle_up_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tutorial_step_settle_up_title", "tutorial_step_settle_up_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20055, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26659, 121),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17711,
    73),
    )
)

internal val Res.string.undistributed: StringResource
  get() = CommonMainString0.undistributed

private fun init_undistributed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:undistributed", "undistributed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20141, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26781, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17785,
    41),
    )
)

internal val Res.string.unlock_all_features: StringResource
  get() = CommonMainString0.unlock_all_features

private fun init_unlock_all_features(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unlock_all_features", "unlock_all_features",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20183, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26843, 95),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17827,
    55),
    )
)

internal val Res.string.user_already_in_group: StringResource
  get() = CommonMainString0.user_already_in_group

private fun init_user_already_in_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:user_already_in_group", "user_already_in_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20251, 101),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    26939, 137),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17883,
    81),
    )
)

internal val Res.string.users_count: StringResource
  get() = CommonMainString0.users_count

private fun init_users_count(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:users_count", "users_count",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20353, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27077, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 17965,
    35),
    )
)

internal val Res.string.valid_subs: StringResource
  get() = CommonMainString0.valid_subs

private fun init_valid_subs(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:valid_subs", "valid_subs",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20520, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27308, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18116,
    42),
    )
)

internal val Res.string.valid_subs_days_more: StringResource
  get() = CommonMainString0.valid_subs_days_more

private fun init_valid_subs_days_more(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:valid_subs_days_more", "valid_subs_days_more",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20393, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27137, 84),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18001,
    56),
    )
)

internal val Res.string.valid_subs_hours_more: StringResource
  get() = CommonMainString0.valid_subs_hours_more

private fun init_valid_subs_hours_more(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:valid_subs_hours_more", "valid_subs_hours_more",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20454, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27222, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18058,
    57),
    )
)

internal val Res.string.we_split_in: StringResource
  get() = CommonMainString0.we_split_in

private fun init_we_split_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:we_split_in", "we_split_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20567, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27379, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18159,
    35),
    )
)

internal val Res.string.web_billing_in_progress: StringResource
  get() = CommonMainString0.web_billing_in_progress

private fun init_web_billing_in_progress(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:web_billing_in_progress", "web_billing_in_progress",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20607, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27431, 115),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18195,
    75),
    )
)

internal val Res.string.week_plus: StringResource
  get() = CommonMainString0.week_plus

private fun init_week_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:week_plus", "week_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20683, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27547, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18271,
    29),
    )
)

internal val Res.string.wesplit_logo: StringResource
  get() = CommonMainString0.wesplit_logo

private fun init_wesplit_logo(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:wesplit_logo", "wesplit_logo",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20729, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27597, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18301,
    36),
    )
)

internal val Res.string.what_you_get_with: StringResource
  get() = CommonMainString0.what_you_get_with

private fun init_what_you_get_with(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:what_you_get_with", "what_you_get_with",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20766, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27650, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18338,
    49),
    )
)

internal val Res.string.with_me: StringResource
  get() = CommonMainString0.with_me

private fun init_with_me(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:with_me", "with_me",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20808, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27720, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18388,
    27),
    )
)

internal val Res.string.year_plus: StringResource
  get() = CommonMainString0.year_plus

private fun init_year_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:year_plus", "year_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20836, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27756, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18416,
    29),
    )
)

internal val Res.string.you: StringResource
  get() = CommonMainString0.you

private fun init_you(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:you", "you",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21061, 15),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27997, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18601,
    15),
    )
)

internal val Res.string.you_borrowed: StringResource
  get() = CommonMainString0.you_borrowed

private fun init_you_borrowed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:you_borrowed", "you_borrowed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20878, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27802, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18446,
    44),
    )
)

internal val Res.string.you_have_plus: StringResource
  get() = CommonMainString0.you_have_plus

private fun init_you_have_plus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:you_have_plus", "you_have_plus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20935, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27855, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18491,
    41),
    )
)

internal val Res.string.you_lent: StringResource
  get() = CommonMainString0.you_lent

private fun init_you_lent(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:you_lent", "you_lent",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20977, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27909, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18533,
    36),
    )
)

internal val Res.string.your_share: StringResource
  get() = CommonMainString0.your_share

private fun init_your_share(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:your_share", "your_share",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ), "composeResources/split.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21030, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/split.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    27962, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 18570,
    30),
    )
)
